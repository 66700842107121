import React, { useEffect } from "react";

import ErrorIcon from "@mui/icons-material/Error";

import countries from "../utils/countries";

export default function AddIdCountryInput({ val, setVal, error, name }) {
  const scrollTo = (className) => {
    const element = document.querySelector(
      `.${className.toLowerCase().split(" ").join("")}`
    );
    if (element != undefined) {
      let topPos = element.offsetTop;
      let scrollDiv = document.querySelector(".countrydropdown");
      if (scrollDiv != undefined) {
        scrollDiv.scrollTop = topPos;
      }
    }
  };

  return (
    <div className="flex flex-col relative">
      <div className="flex flex-row items-center mb-1">
        <p className="text-neutral-800 mr-3 text-lg">{name}</p>
        {error ? (
          <div className="flex-1 flex flex-row items-center justify-end text-red-500">
            <ErrorIcon style={{ width: 16, height: 16 }} />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        className={`flex border ${
          error
            ? "border-red-500"
            : "border-neutral-300 focus-within:border-neutral-500"
        } rounded-lg overflow-auto duration-100 bg-white`}
      >
        <input
          value={val}
          onChange={(e) => {
            let value = e.target.value;
            setVal(value);
            if (value != "") {
              let searchTerm = countries.search(value.toLowerCase());
              if (searchTerm != undefined) {
                scrollTo(searchTerm.toLowerCase());
              }
            }
          }}
          className={`peer flex-1 bg-transparent outline-none w-[100%] py-2 px-3 text-base placeholder:text-neutral-500 text-neutral-900`}
          autoCorrect="false"
          autoComplete="false"
        />
        <div className="hidden shadow-2xl countrydropdown absolute top-full bg-white w-64 h-32 mt-1 hover:flex peer-focus:flex flex-col overflow-scroll overflow-x-hidden custom-scrollbar">
          {countries.get().map((country) => {
            return (
              <p
                key={country}
                className={`${country
                  .toLowerCase()
                  .split(" ")
                  .join(
                    ""
                  )} px-1 py-2 hover:bg-[#DB287B] hover:text-white cursor-pointer`}
                onClick={() => {
                  setVal(country);
                }}
              >
                {country}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
}

// return (
//   <>
//     <div
//       className="addIdInput-container w-full"
//       style={(() => {
//         if (invalid) {
//           return { border: "1px dashed red" };
//         } else {
//           return {};
//         }
//       })()}
//     >
//       <p style={{ color: "#555" }}>Country</p>
//       <div style={{ position: "relative", flex: 1 }}>
//         <input
//           className="addIdCountryInput"
//           style={{
//             position: "absolute",
//             width: "100%",
//             height: "100%",
//           }}
//           value={val}
//           onChange={(e) => {
//             let value = e.target.value;
//             set(value);
//             if (value != "") {
//               let searchTerm = countries.search(value.toLowerCase());
//               if (searchTerm != undefined) {
//                 scrollTo(searchTerm.toLowerCase());
//               }
//             }
//           }}
//         />
//         <div className="addIdCountryInput-dropdown-container">
//           <div className="addIdCountry-dropdown-box">
//             {countries.get().map((country) => {
//               return (
//                 <p
//                   key={country}
//                   className={`${country.toLowerCase()} addIdCountry-dropdown-country`}
//                   onClick={() => {
//                     set(country);
//                   }}
//                 >
//                   {country}
//                 </p>
//               );
//             })}
//           </div>
//         </div>
//       </div>
//     </div>
//   </>
// );
