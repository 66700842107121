import React, { useState } from "react";

import OtherHeader from "../components/OtherHeader";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const questions = [
  {
    question: "Why should I use Pass Protect?",
    answer:
      "Pass Protect helps you to manage your passwords securely, which means you don't have to remember all of them or write them down on a piece of paper that could get lost or stolen. By using Pass Protect, you can generate strong, unique passwords for each of your accounts, which makes it much harder for someone to hack into your accounts if they do manage to get hold of your passwords",
  },
  {
    question: "Is it safe to store my passwords in a password manager?",
    answer:
      "Yes, Pass Protect uses strong encryption to protect your passwords, which means that they are stored securely and can only be accessed with your master password. It's important to choose a strong and unique master password, as this is the key to accessing your passwords in Pass Protect.",
  },
  {
    question: "Can I access my passwords from multiple devices?",
    answer:
      "Yes, Pass Protect allows you to access your passwords from multiple devices, such as a computer or a phone.",
  },
  {
    question: "What happens if I forget my master password?",
    answer: `If you forget your master password, unfortunately, there is no way to recover it. Your passwords are strongly encrypted for security reasons, and even Pass Protect's developers can't access them without your master password. So, it's crucial to choose a strong and memorable master password and keep a backup copy of it somewhere safe. If you forget it, you can still recover your account by using the "Forgot password" option while signing in, but changing your password will mean losing all passwords in your account.`,
  },
  {
    question: "Is it possible to export my passwords from Pass Protect?",
    answer: `Yes, Pass Protect allows you to export your passwords in a format that can be imported into other password managers or stored as a backup. This can be useful if you want to switch to a different password manager, or if you want to keep a backup copy of your passwords in case something happens to your Pass Protect account.`,
  },
  {
    question: "How can I import my passwords from other password managers?",
    answer: `To import passwords from other password managers into Pass Protect, you can use a standard format like CSV. Your old password manager should allow you to export your passwords in this format. Simply go to your Pass Protect settings to import passwords from other managers.`,
  },
  {
    question:
      "Can I use my password manager to store other sensitive information?",
    answer: `Yes, Pass Protect can be used to store other sensitive information, such as credit card details, secure notes, or other types of personal information that you want to keep secure.`,
  },
];

export default function Faq({ loggedIn, email }) {
  return (
    <div className="flex-1 flex flex-col">
      {loggedIn ? (
        <>
          <OtherHeader email={email} />
        </>
      ) : (
        <></>
      )}
      <div className="mt-6 flex-1 flex-col flex items-center mx-4">
        <div className="w-full md:w-4/5 lg:w-[1000px]">
          <h1 className="mt-4 text-neutral-800 text-3xl text-center font-bold">
            Frequently asked questions
          </h1>
          <div className="mt-10 flex flex-col">
            {questions.map((q) => {
              return (
                <QuestionCard
                  question={q.question}
                  answer={q.answer}
                  key={q.question}
                />
              );
            })}
          </div>
          <div className="mt-10 flex flex-col">
            <h3 className="text-neutral-800 text-center font-bold text-xl">
              Still have questions?
            </h3>
            <p className="mt-4 text-neutral-500 text-center text-base">
              Can't find the answer you're looking for? Get in touch with us.
            </p>
            <div className="flex items-center justify-center mt-4 mb-10">
              <Link to="/contact">
                <button className="bg-[#FFFCBA] text-[#85823D] px-3 py-1 items-center justify-center rounded-lg hover:brightness-90 duration-100">
                  Contact us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const QuestionCard = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <section className="border-b border-b-neutral-300 flex flex-col px-6 py-4">
      <div
        className="flex flex-row items-center justify-between cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <h4 className="text-lg font-neutral-800 font-bold">{question}</h4>
        <button className="bg-white flex items-center justify-center border border-[#DB287B] rounded-full text-[#DB287B]">
          {open ? (
            <RemoveIcon style={{ width: 20, height: 20 }} />
          ) : (
            <AddIcon style={{ width: 20, height: 20 }} />
          )}
        </button>
      </div>
      <div className={`relative overflow-hidden ${open ? "h-auto" : "h-0"}`}>
        <div className="text-neutral-500 text-base mt-2">{answer}</div>
      </div>
    </section>
  );
};
