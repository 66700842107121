import React, { useState } from "react";

import SubscriptionBox from "../components/SubscriptionBox";

import SidebarContainer from "../components/SidebarContainer";
import SidebarHomeContent from "../components/SidebarHomeContent";

const map = {
  password: "Passwords",
  card: "Cards",
  identity: "Identities",
  note: "Notes",
};

export default function CheckoutRequest({ email, freeTrialDone }) {
  const [selected, setSelected] = useState("password");

  return (
    <SidebarContainer
      email={email}
      content={
        <SidebarHomeContent selected={selected} setSelected={setSelected} />
      }
    >
      <div className="flex flex-1 justify-center items-center flex-col">
        <p className="text-xl md:text-3xl text-center">
          You need to buy a subscription before you can start using Pass Protect
        </p>
        <p className="text-neutral-500 mb-8 mt-2">(If you have a subscription key, you can redeem it in your settings)</p>
        <SubscriptionBox freeTrialDone={freeTrialDone} />
        <p className="text-center mt-4">
          You cannot access the "{map[selected]}" page without a subscription
        </p>
      </div>
    </SidebarContainer>
  );
}
