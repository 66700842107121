import React from "react";

export default function BtnOne({
  name,
  onClick,
  deactivated,
  loading,
  hoverEffect = true,
  flex1 = false,
}) {
  return (
    <button
      onClick={onClick && !deactivated && !loading ? onClick : () => {}}
      className={
        !deactivated && !loading
          ? `bg-[#DB287B] py-2 px-6 rounded-md text-[#fff] text-base font-semibold duration-200 border border-[#DB287B] relative ${
              hoverEffect ? "hover:bg-[#fff] hover:text-[#DB287B]" : ""
            } ${flex1 ? "flex-1" : ""}`
          : `bg-white py-2 px-6 rounded-md font-semibold text-[#aaa] border border-[#aaa] cursor-default relative ${
              flex1 ? "flex-1" : ""
            }`
      }
    >
      <p className={loading ? "opacity-0 select-none" : ""}>{name}</p>
      <div
        className={`absolute w-[100%] h-[100%] left-0 top-0 flex justify-center items-center ${
          !loading ? "hidden" : ""
        }`}
      >
        <div className="w-6 h-6 rounded-[50%] border border-[#aaa] border-r-[#fff] animate-spin"></div>
      </div>
    </button>
  );
}

// export default function BtnOne({ name, style, onClick }) {
//   if (style == undefined) {
//     if (onClick == undefined) {
//       return <button className="btnOne">{name}</button>;
//     } else {
//       return (
//         <button className="btnOne" onClick={onClick}>
//           {name}
//         </button>
//       );
//     }
//   } else {
//     if (onClick == undefined) {
//       return (
//         <button className="btnOne" style={style}>
//           {name}
//         </button>
//       );
//     } else {
//       return (
//         <button className="btnOne" style={style} onClick={onClick}>
//           {name}
//         </button>
//       );
//     }
//   }
// }
