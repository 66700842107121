import React, { useState } from "react";

import axios from "axios";

import UnverifiedHeader from "../components/UnverifiedHeader";

import MailOutlinedIcon from "@mui/icons-material/MailOutlined";

export default function VerifyEmailPage({ email }) {
  const [isSubbed, setIsSubbed] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [succMsg, setSuccMsg] = useState("");

  const resendEmail = async () => {
    if (isSubbed == false) {
      setIsSubbed(true);

      let data = {};

      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      data["_csrf"] = csrfToken;

      let res = await axios.post("/api/resendVerificationEmail", data);

      let resData = res.data;

      if (resData.status == "success") {
        setSuccMsg("Email sent!");
        setErrMsg("");
      } else {
        setErrMsg(resData.message);
        setSuccMsg("");
      }

      setIsSubbed(false);
    }
  };

  return (
    <div className="flex flex-1 flex-col">
      <UnverifiedHeader email={email} />
      <div className="flex flex-1 justify-center items-center">
        <div className="p-6 w-[100%] mx-4 sm:w-[80%] md:w-[724px] flex flex-col shadow-2xl rounded-xl">
          <div className="flex justify-center items-center">
            <div className="bg-[#FFFCBA] p-3 rounded-[50%] text-[#85823D]">
              <MailOutlinedIcon />
            </div>
          </div>
          <p className="mt-6 text-center text-gray-900 font-bold text-xl">
            Please confirm your email
          </p>
          <p className="text-gray-500 text-center mt-6 text-lg">
            We've sent a link to <span className="font-bold">{email}</span>
          </p>
          <p className="mt-4 text-center text-gray-500">
            Didn't receive the email? Check your spam folder or{" "}
            <span className="underline cursor-pointer" onClick={resendEmail}>
              Click to resend
            </span>
            .
          </p>
          <div className="h-16 flex flex-col justify-end">
            {(() => {
              if (errMsg != "") {
                return <p className="text-red-500">{errMsg}</p>
              } else if (succMsg != "") {
                return <p className="text-green-700">{succMsg}</p>
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}