import React, { useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import ErrorIcon from "@mui/icons-material/Error";

export default function AuthInput({
  name,
  icon,
  placeholder,
  value,
  setValue,
  error = false,
  password = false,
}) {
  const [visible, setVisible] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center">
        <p className="ml-1 mb-1 text-[#aaa]">{name}</p>
        {error ? (
          <>
            <div className="flex-1 flex flex-row items-center justify-end">
              <ErrorIcon
                style={{ width: 16, height: 16 }}
                className="text-red-500"
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        className={`bg-white flex flex-row px-1 items-center border ${
          error ? "border-red-500" : "border-black"
        } rounded-lg`}
      >
        {(() => {
          let A = icon;
          return <A className="mx-1" />;
        })()}
        <input
          type={!visible && password ? "password" : "text"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="bg-transparent outline-none flex-1 py-2 ml-1 min-w-0"
          placeholder={placeholder}
        />
        {password ? (
          <div
            className="cursor-pointer mx-1"
            onClick={() => setVisible(!visible)}
          >
            {visible ? <Visibility /> : <VisibilityOff />}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
