import React from "react";
import Logo from "../assets/Logo";

import { Link } from "react-router-dom";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

import Logout from "../utils/Logout";

export default function UnverifiedHeader({ email }) {
  return (
    <div className="bg-white flex flex-col px-8 py-4">
      <div className="flex-1 flex flex-col md:flex-row justify-center">
        <div className="flex-1"></div>
        <div className="px-4 flex justify-center">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="flex-1 flex items-center justify-center md:justify-end mt-4 md:mt-0">
          <div className="flex flex-row items-center select-none cursor-pointer group relative">
            <AccountCircleIcon
              style={{ color: "#682A47", width: 32, height: 32 }}
            />
            <p className="ml-2 text-[#682A47]">{email}</p>
            <div className="absolute hidden group-hover:flex hover:flex top-[100%] right-0 w-[256px] cursor-default flex-row pt-2">
              <div onClick={Logout} className="bg-[#682A47] flex-1 flex flex-row justify-center items-center py-2 rounded-lg text-white cursor-pointer hover:brightness-150 duration-100 group/dropdown">
                <p className="mr-2">Logout</p>
                <span className="group-hover/dropdown:translate-x-1 duration-100">
                  <LogoutIcon />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
