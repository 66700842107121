import React from "react";

import AddIcon from "@mui/icons-material/Add";

export default function AddBtn({ onClick }) {
  return (
    <>
      {(() => {
        if (onClick == undefined) {
          return (
            <>
              <div className="homepage-addbtn">
                <div className="homepage-addbtn-circle">
                  <AddIcon className="homepage-addbtn-icon" />
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="homepage-addbtn" onClick={onClick}>
                <div className="homepage-addbtn-circle">
                  <AddIcon className="homepage-addbtn-icon" />
                </div>
              </div>
            </>
          );
        }
      })()}
    </>
  );
}
