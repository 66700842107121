import React from "react";

import SettingsCheckbox from "./SettingsCheckbox";
import PasswordGenSlider from "../PasswordGenSlider";

export default function SettingsPasswordGenerator({
  nums,
  setNums,
  az,
  setaz,
  AZ,
  setAZ,
  other,
  setOther,
  otherChars,
  setOtherChars,
  minLength,
  setMinLength,
  maxLength,
  setMaxLength,
  succMsg,
  errMsg,
}) {
  const setLen = (val) => {
    if (!isNumber(val)) {
      return false;
    }

    setMaxLength(val);
    setMinLength(val);
  };

  const setChars = (val) => {
    let forbiden = "abcdefghijklmnopqrstuvwxyz";
    forbiden += forbiden.toUpperCase();
    forbiden += "0123456789";
    for (let i = 0; i < forbiden.length; i++) {
      let char = forbiden[i];
      if (val.includes(char)) {
        return false;
      }
    }
    let charCount = {};
    for (let i = 0; i < val.length; i++) {
      let char = val[i];
      if (charCount[char] == undefined) {
        charCount[char] = 1;
      } else {
        return false;
      }
    }
    setOtherChars(val);
  };

  const isNumber = (val) => {
    let num = Number(val);
    if (val.includes("-")) {
      return false;
    }
    if (isNaN(num)) {
      return false;
    } else {
      if (num < 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <div className="flex-1 flex flex-col">
      <p className="text-2xl text-neutral-800 font-bold">Characters</p>
      <div className="flex flex-row items-center">
        <SettingsCheckbox value={nums} setValue={setNums} />
        <p className="ml-2 text-base text-neutral-500">0-9</p>
      </div>
      <div className="flex flex-row items-center mt-4">
        <SettingsCheckbox value={az} setValue={setaz} />
        <p className="ml-2 text-base text-neutral-500">a-z</p>
      </div>
      <div className="flex flex-row items-center mt-4">
        <SettingsCheckbox value={AZ} setValue={setAZ} />
        <p className="ml-2 text-base text-neutral-500">A-Z</p>
      </div>
      <div className="flex flex-row items-center mt-4">
        <SettingsCheckbox value={other} setValue={setOther} />
        <p className="ml-2 text-base text-neutral-500">Other</p>
        <input
          className="bg-white border w-32 ml-2 border-neutral-300 border-neutral-500 outline-none px-2 py-1 rounded-lg"
          value={otherChars}
          onChange={(v) => setChars(v.target.value)}
        />
      </div>
      <p className="text-2xl text-neutral-800 font-bold mb-4 mt-6">
        Password length
      </p>
      <div>
        <PasswordGenSlider val={maxLength} setVal={setLen} />
      </div>
    </div>
  );

  return (
    <>
      <div
        style={{
          marginLeft: 8,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ fontWeight: "bold", fontSize: 24 }}>Characters</p>
        <p style={{ color: "#949494", marginBottom: 8 }}>
          Characters to include by default in generated passwords
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        <SettingsCheckbox value={nums} setValue={setNums} />
        <p style={{ marginLeft: 8 }}>0-9</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 12,
        }}
      >
        <SettingsCheckbox value={az} setValue={setaz} />
        <p style={{ marginLeft: 8 }}>a-z</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 12,
        }}
      >
        <SettingsCheckbox value={AZ} setValue={setAZ} />
        <p style={{ marginLeft: 8 }}>A-Z</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 12,
        }}
      >
        <SettingsCheckbox value={other} setValue={setOther} />
        <input
          className="settings-pwgen-othercharsinput"
          value={otherChars}
          onChange={(e) => setChars(e.target.value)}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: 8,
          marginTop: 12,
        }}
      >
        <p style={{ fontWeight: "bold", fontSize: 24, marginBottom: 8 }}>
          Password length
        </p>
        <div style={{ display: "flex" }}>
          <PasswordGenSlider val={maxLength} setVal={setLen} />
        </div>
        {(() => {
          if (Number(minLength) > Number(maxLength)) {
            return (
              <p style={{ color: "red" }}>
                Minimum password length cannot be greater than maximum length
              </p>
            );
          } else if (Number(maxLength) < Number(minLength)) {
            return (
              <>
                <p style={{ color: "red" }}>
                  Maximum password length cannot be less than minimum length
                </p>
              </>
            );
          } else {
            return <></>;
          }
        })()}
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div style={{ flex: 1 }}></div>
        {(() => {
          if (succMsg != "") {
            return <p style={{ color: "green", marginLeft: 16 }}>{succMsg}</p>;
          } else if (errMsg != "") {
            return <p style={{ color: "red", marginLeft: 16 }}>{errMsg}</p>;
          }
        })()}
      </div>
    </>
  );
}
