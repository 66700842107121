import { useState } from "react";

import React from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import axios from "axios";
import { SHA256 } from "crypto-js";

export default function SettingsDeleteAccount({ uid }) {
  const [isVisible, setIsVisible] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [pw, setPw] = useState("");
  const [isSub, setIsSub] = useState(false);

  const delAccount = async () => {
    try {
      setIsSub(true);
      setErrMsg("");

      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      let response = await axios.post("/api/deleteAccount", {
        _csrf: csrfToken,
        password: SHA256(pw).toString(),
      });

      let res = response.data;

      if (res.status == "error") {
        setErrMsg(res.message);
      } else {
        localStorage.clear();
        document.location.pathname = "/";
      }

      setIsSub(false);
    } catch {
      setIsSub(false);
    }
  };

  return (
    <div className="flex flex-col">
      <p className="text-neutral-800">
        Enter your password to permanently delete your account
      </p>
      <p className="text-red-500">WARNING! This action cannot be undone</p>

      <div className="flex flex-row border border-neutral-500 px-1 items-center focus-within:border-neutral-800 rounded-lg">
        <input
          className="flex-1 outline-none py-2 pe-1 text-sm"
          type={isVisible ? "text" : "password"}
          value={pw}
          onChange={(e) => setPw(e.target.value)}
        />
        <div
          className="flex items-center justify-center p-1 cursor-pointer"
          onClick={() => setIsVisible(!isVisible)}
        >
          {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </div>
      </div>
      <button
        disabled={pw.length < 7}
        className={`mt-2 py-2 flex items-center justify-center rounded duration-100 ${
          pw.length < 7
            ? "border border-neutral-500 text-neutral-500 bg-neutral-200 cursor-default"
            : "text-red-500 border border-red-500 bg-white hover:text-white hover:bg-red-500"
        }`}
        onClick={delAccount}
      >
        {isSub ? (
          <>
            <div className="w-6 h-6 bg-none border-2 border-x-red-500 border-t-red-500 rounded-full animate-spin"></div>
          </>
        ) : (
          <>
            <p className="h-6">Delete account</p>
          </>
        )}
      </button>
      <p className="text-red-500 mt-2">{errMsg}</p>
    </div>
  );
}
