import React, { useEffect, useState } from "react";

import ViewCardField from "../ViewCardField";

import Logout from "../../utils/Logout";
import encryption from "../../utils/encryption";

import BtnTwo from "../BtnTwo";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import OverlayContentWrapper from "../OverlayContentWrapper";
import AddAccountInput from "../AddAccountInput";

export default function ViewCardOverlay({
  card,
  close,
  sync,
  promptDeleteCard,
  encKey,
  setOverlay,
}) {
  const [cardNum, setCardNum] = useState("");
  const [expDate, setExpDate] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [cvc, setCvc] = useState("");
  const [cardName, setCardName] = useState();
  const [errorList, setErrorList] = useState([]);
  const [defaultState, setDefaultState] = useState("");
  const [isSubbed, setIsSubbed] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    try {
      let cardData = JSON.parse(atob(card.data));
      let defaultVal = "";
      if (encKey == "" || encKey == undefined) {
        Logout();
        return;
      } else {
        let data = {};
        for (let key in cardData) {
          let value = cardData[key];
          let decrypted = "";
          if (value != "") {
            decrypted = encryption.decrypt(value, encKey);
          }
          data[key] = decrypted;
        }
        for (let key in data) {
          let value = data[key];
          if (key == "cardName") {
            setCardName(value);
            defaultVal += value;
          } else if (key == "cardNum") {
            setVal.cardNum(value);
            defaultVal += value;
          } else if (key == "cvc") {
            setCvc(value);
            defaultVal += value;
          }
        }
        let eDate = ``;
        let eMonth = data["expMonth"];
        let eYear = data["expYear"];
        if (eMonth.length == 1) {
          eMonth = "0" + eMonth;
        }

        if (eYear.length == 1) {
          eYear = "0" + eYear;
        }

        setExpMonth(eMonth);
        setExpYear(eYear);

        defaultVal = defaultVal + eMonth + eYear;
        setDefaultState(defaultVal);
        setIsLoaded(true);
      }
    } catch {
      close();
    }
  }, []);

  const hasChanged = () => {
    let currentState = `${cardNum}${cvc}${cardName}${expMonth}${expYear}`;
    if (currentState.split(" ").join("") == defaultState.split(" ").join("")) {
      return false;
    } else {
      return true;
    }
  };

  const submit = async () => {
    try {
      setIsSubbed(true);
      let errs = verifySubmit.all();
      if (errs.length == 0) {
        if (encKey == undefined || encKey == "") {
          Logout();
        }
        let data = {
          cardNum: cardNum.split(" ").join(""),
          expMonth: expMonth,
          expYear: expYear,
          cvc,
          cardName,
        };
        let encrypted = {};
        for (let key in data) {
          let value = data[key];
          if (value == "") {
            encrypted[key] = "";
          } else {
            let enced = encryption.encrypt(value, encKey);
            encrypted[key] = enced;
          }
        }
        let last4digits = "";
        let cn = cardNum.split(" ").join("");
        for (let i = cn.length - 1; i > cn.length - 5; i--) {
          let n = cn[i];
          last4digits += n;
        }
        let reversed4Digits = last4digits.split("").reverse().join("");
        let name = `${reversed4Digits}`;
        encrypted["name"] = name;
        encrypted["id"] = card.id;

        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        encrypted["_csrf"] = csrfToken;
        encrypted["hashedPw"] = localStorage.getItem("hashedPw");

        let res = await axios.post("/api/updateCard", encrypted);

        if (res.data.status == "success") {
          sync();
          close();
        } else {
          if (res.data.message == "pwHashError") {
            localStorage.removeItem("hashedPw");
            setOverlay({ type: "view-card", data: card });
            setIsSubbed(false);
            return;
          } else {
            sync();
            close();
          }
        }
      }
      setIsSubbed(false);
    } catch {
      setIsSubbed(false);
      close();
    }
  };

  const isNumber = (val) => {
    let valid = true;
    let numVal = Number(val);
    if (isNaN(numVal)) {
      valid = false;
    } else {
      if (numVal < 0) {
        valid = false;
      }
    }

    if (val.includes("-")) {
      valid = false;
    }
    return valid;
  };

  const verifySubmit = {
    cardNum: () => {
      let value = cardNum;
      let valid = true;
      value = value.split(" ").join("");
      if (!isNumber(value)) {
        valid = false;
      } else if (value.length >= 20) {
        valid = false;
      } else if (value.length <= 10) {
        valid = false;
      }
      return valid;
    },
    expMonth: () => {
      if (
        expMonth != "" &&
        isNumber(expMonth) &&
        expMonth.length <= 2 &&
        Number(expMonth) > 0 &&
        Number(expMonth) <= 12
      ) {
        return true;
      } else {
        return false;
      }
    },
    expYear: () => {
      if (expYear != "" && isNumber(expYear) && expYear.length <= 2) {
        return true;
      } else {
        return false;
      }
    },
    cvc: () => {
      let value = cvc;
      let valid = true;
      if (!isNumber(value)) {
        valid = false;
      } else if (value.length > 4) {
        valid = false;
      } else if (value.length < 3) {
        valid = false;
      } else if (value == "") {
        valid = false;
      }
      return valid;
    },
    cardName: () => {
      let value = cardName;
      if (value.length < 40) {
        return true;
      } else {
        return false;
      }
    },
    all: () => {
      let errors = [];
      for (let key in verifySubmit) {
        if (key != "all") {
          let valid = verifySubmit[key]();
          if (!valid) {
            errors.push(key);
          }
        }
      }
      setErrorList(errors);
      return errors;
    },
  };

  const verifyVal = {
    cardNum: (val) => {
      let valid = true;
      if (!isNumber(val)) {
        valid = false;
      } else if (val.length >= 20) {
        valid = false;
      }
      return valid;
    },
    expDate: (val) => {
      if (!isNumber(val)) {
        return false;
      }

      if (val.length > 2) {
        return false;
      }

      return true;
    },
    cvc: (val) => {
      let valid = true;
      if (!isNumber(val)) {
        valid = false;
      } else if (val.length > 4) {
        valid = false;
      }
      if (val.includes(" ")) {
        valid = false;
      }

      return valid;
    },
    cardName: (val) => {
      return true;
    },
  };

  const setVal = {
    cardNum: (val) => {
      val = val.split(" ").join("");
      if (verifyVal.cardNum(val)) {
        let newValue = "";
        for (let i = 0; i < val.length; i++) {
          newValue += val[i];
          if ((i + 1) % 4 == 0 && i != val.length - 1) {
            newValue += " ";
          }
        }
        setCardNum(newValue);
      }
    },
    expMonth: (val) => {
      if (verifyVal.expDate(val)) {
        setExpMonth(val);
      }
    },
    expYear: (val) => {
      if (verifyVal.expDate(val)) {
        setExpYear(val);
      }
    },
    cvc: (val) => {
      if (verifyVal.cvc(val)) {
        setCvc(val);
      }
    },
    cardName: (val) => {
      if (verifyVal.cardName(val)) {
        setCardName(val);
      }
    },
  };

  return (
    <OverlayContentWrapper
      close={close}
      errMsg={
        errorList.length == 0
          ? ""
          : "Some of the information you entered was invalid"
      }
      loading={isSubbed}
      submit={submit}
      deactivated={!hasChanged()}
    >
      {isLoaded ? (
        <>
          <AddAccountInput
            name="Card number"
            placeholder="1234 1234 1234 1234"
            val={cardNum}
            setVal={setVal.cardNum}
            error={errorList.includes("cardNum")}
            helpTxt="The number for a credit or debit card, usually 16 digits"
          />
          <div className="flex flex-row mt-4">
            <div className="flex flex-row flex-1 mr-1">
              <AddAccountInput
                className={`rounded-r-none ${
                  errorList.includes("expMonth") ||
                  errorList.includes("expYear")
                    ? "border-r-0"
                    : ""
                }`}
                name="Expiry"
                placeholder="MM"
                val={expMonth}
                setVal={setVal.expMonth}
                error={
                  errorList.includes("expMonth") ||
                  errorList.includes("expYear")
                }
                helpTxt="The expiry date for the card"
                noErrIcon={true}
              />
              <AddAccountInput
                className="rounded-l-none"
                placeholder="YY"
                val={expYear}
                setVal={setVal.expYear}
                error={
                  errorList.includes("expMonth") ||
                  errorList.includes("expYear")
                }
              />
            </div>
            <div className="flex flex-row flex-1 ml-1">
              <AddAccountInput
                name="Security code"
                placeholder=""
                val={cvc}
                setVal={setVal.cvc}
                error={errorList.includes("cvc")}
                helpLeft={true}
                helpTxt="The security code for the card. This is usually found on the back of a card"
              />
            </div>
          </div>
          <div className="mt-4">
            <AddAccountInput
              name="Name on card"
              placeholder="John doe"
              val={cardName}
              setVal={setVal.cardName}
              error={errorList.includes("cardName")}
              helpTxt="The name of the person this card belongs to"
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </OverlayContentWrapper>
  );
}

// return (
//   <>
//     <div style={{ display: "flex", flexDirection: "row" }}>
//       <ViewCardField
//         name="Card number"
//         value={cardNum}
//         update={setVal.cardNum}
//         containerFlex
//         error={errorList.includes("cardNum")}
//       />
//     </div>
//     <div style={{ display: "flex", flexDirection: "row" }}>
//       <ViewCardField
//         name="Expiry date"
//         value={expDate}
//         update={setVal.expDate}
//         containerFlex
//         error={errorList.includes("expDate")}
//       />
//       <div className="addIdOverlay-seperator"></div>

//       <ViewCardField
//         name="Security code"
//         value={cvc}
//         update={setVal.cvc}
//         containerFlex
//         error={errorList.includes("cvc")}
//       />
//     </div>
//     <div style={{ display: "flex", flexDirection: "row" }}>
//       <ViewCardField
//         name="Name on card"
//         value={cardName}
//         update={setVal.cardName}
//         containerFlex
//         error={errorList.includes("cardName")}
//       />
//     </div>
//     <div style={{ flex: 1 }}></div>
//     <div style={{ display: "flex" }}>
//       <div style={{ display: "flex", flexDirection: "column" }}>
//         <div style={{ flex: 1 }}></div>
//         <div
//           className="viewAccount-deletebtn"
//           onClick={() => promptDeleteCard(card)}
//         >
//           <DeleteIcon />
//           <p>Delete</p>
//         </div>
//       </div>
//       <div
//         style={{ flex: 1, display: "flex", alignItems: "center", padding: 8 }}
//       >
//         <div style={{ flex: 1, alignItems: "center", display: "flex" }}>
//           {(() => {
//             if (errorList.length != 0) {
//               return (
//                 <p style={{ color: "red" }}>
//                   Some of the information you entered was invalid
//                 </p>
//               );
//             } else {
//               return <></>;
//             }
//           })()}
//         </div>
//       </div>
//       <BtnTwo
//         name="Save"
//         style={{ width: 200 }}
//         onClick={submit}
//         deactivated={!hasChanged()}
//         loading={isSubbed}
//       />
//     </div>
//   </>
// );
