import React, { useState } from "react";

import CardInput from "../CardInput";
import CardInputTop from "../CardInputTop";
import BtnTwo from "../BtnTwo";
import Logout from "../../utils/Logout";
import encryption from "../../utils/encryption";
import axios from "axios";
import OverlayContentWrapper from "../OverlayContentWrapper";
import AddAccountInput from "../AddAccountInput";

export default function AddCardOverlay({ close, sync, encKey, setOverlay }) {
  const [cardNum, setCardNum] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [cvc, setCvc] = useState("");
  const [cardName, setCardName] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [isSubbed, setIsSubbed] = useState(false);

  const isNumber = (val) => {
    let valid = true;
    let numVal = Number(val);
    if (isNaN(numVal)) {
      valid = false;
    } else {
      if (numVal < 0) {
        valid = false;
      }
    }

    if (val.includes("-")) {
      valid = false;
    }
    return valid;
  };

  const submit = async () => {
    try {
      setIsSubbed(true);
      let errs = verifySubmit.all();
      if (errs.length == 0) {
        if (encKey == undefined || encKey == "") {
          Logout();
        }
        let data = {
          cardNum: cardNum.split(" ").join(""),
          expMonth,
          expYear,
          cvc,
          cardName,
        };
        let encrypted = {};
        for (let key in data) {
          let value = data[key];
          if (value == "") {
            encrypted[key] = "";
          } else {
            let enced = encryption.encrypt(value, encKey);
            encrypted[key] = enced;
          }
        }
        let last4digits = "";
        let cn = cardNum.split(" ").join("");
        for (let i = cn.length - 1; i > cn.length - 5; i--) {
          let n = cn[i];
          last4digits += n;
        }
        let reversed4Digits = last4digits.split("").reverse().join("");
        let name = `${reversed4Digits}`;
        encrypted["name"] = name;

        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        encrypted["_csrf"] = csrfToken;
        encrypted["hashedPw"] = localStorage.getItem("hashedPw");

        let res = await axios.post("/api/createCard", encrypted);

        if (res.data.status == "success") {
          sync();
          close();
        } else {
          if (res.data.message == "pwHashError") {
            localStorage.removeItem("hashedPw");
            setIsSubbed(false);
            setOverlay({ type: "add-card" });
            return;
          } else {
            sync();
            close();
          }
        }
      }
      setIsSubbed(false);
    } catch {
      setIsSubbed(false);
    }
  };

  const verifySubmit = {
    cardNum: () => {
      let value = cardNum;
      let valid = true;
      value = value.split(" ").join("");
      if (!isNumber(value)) {
        valid = false;
      } else if (value.length >= 20) {
        valid = false;
      } else if (value.length <= 10) {
        valid = false;
      }
      return valid;
    },
    expMonth: () => {
      let value = expMonth;
      let valid = true;
      if (!isNumber(value)) {
        valid = false;
      } else if (value.length > 2) {
        valid = false;
      } else if (value == "") {
        valid = false;
      }
      return valid;
    },
    expYear: () => {
      let value = expYear;
      let valid = true;
      if (!isNumber(value)) {
        valid = false;
      } else if (value.length > 2) {
        valid = false;
      } else if (value == "") {
        valid = false;
      }
      return valid;
    },
    cvc: () => {
      let value = cvc;
      let valid = true;
      if (!isNumber(value)) {
        valid = false;
      } else if (value.length > 4) {
        valid = false;
      } else if (value.length < 3) {
        valid = false;
      } else if (value == "") {
        valid = false;
      }
      return valid;
    },
    cardName: () => {
      let value = cardName;
      if (value.length < 40) {
        return true;
      } else {
        return false;
      }
    },
    all: () => {
      let errors = [];
      for (let key in verifySubmit) {
        if (key != "all") {
          let valid = verifySubmit[key]();
          if (!valid) {
            errors.push(key);
          }
        }
      }
      setErrorList(errors);
      return errors;
    },
  };

  const verifyVal = {
    cardNum: (val) => {
      let valid = true;
      if (!isNumber(val)) {
        valid = false;
      } else if (val.length >= 20) {
        valid = false;
      }
      return valid;
    },
    expMonth: (val) => {
      let valid = true;
      if (!isNumber(val)) {
        valid = false;
      } else if (val.length > 2) {
        valid = false;
      } else if (Number(val) > 12) {
        valid = false;
      }
      if (val.includes(" ")) {
        valid = false;
      }

      return valid;
    },
    expYear: (val) => {
      let valid = true;
      if (!isNumber(val)) {
        valid = false;
      } else if (val.length > 2) {
        valid = false;
      }
      if (val.includes(" ")) {
        valid = false;
      }

      return valid;
    },
    cvc: (val) => {
      let valid = true;
      if (!isNumber(val)) {
        valid = false;
      } else if (val.length > 4) {
        valid = false;
      }
      if (val.includes(" ")) {
        valid = false;
      }

      return valid;
    },
    cardName: (val) => {
      return true;
    },
  };

  const setVal = {
    cardNum: (val) => {
      val = val.split(" ").join("");
      if (verifyVal.cardNum(val)) {
        let newValue = "";
        for (let i = 0; i < val.length; i++) {
          newValue += val[i];
          if ((i + 1) % 4 == 0 && i != val.length - 1) {
            newValue += " ";
          }
        }
        setCardNum(newValue);
      }
    },
    expMonth: (val) => {
      if (verifyVal.expMonth(val)) {
        setExpMonth(val);
      }
    },
    expYear: (val) => {
      if (verifyVal.expYear(val)) {
        setExpYear(val);
      }
    },
    cvc: (val) => {
      if (verifyVal.cvc(val)) {
        setCvc(val);
      }
    },
    cardName: (val) => {
      if (verifyVal.cardName(val)) {
        setCardName(val);
      }
    },
  };

  return (
    <OverlayContentWrapper
      close={close}
      errMsg={
        errorList.length == 0
          ? ""
          : "Some of the information you entered was invalid"
      }
      loading={isSubbed}
      submit={submit}
    >
      <AddAccountInput
        name="Card number"
        placeholder="1234 1234 1234 1234"
        val={cardNum}
        setVal={setVal.cardNum}
        error={errorList.includes("cardNum")}
        helpTxt="The number for a credit or debit card, usually 16 digits"
      />
      <div className="flex flex-row mt-4">
        <div className="flex flex-row flex-1 mr-1">
          <AddAccountInput
            className={`rounded-r-none ${
              errorList.includes("expMonth") || errorList.includes("expYear")
                ? "border-r-0"
                : ""
            }`}
            name="Expiry"
            placeholder="MM"
            val={expMonth}
            setVal={setVal.expMonth}
            error={
              errorList.includes("expMonth") || errorList.includes("expYear")
            }
            helpTxt="The expiry date for the card"
            noErrIcon={true}
          />
          <AddAccountInput
            className="rounded-l-none"
            placeholder="YY"
            val={expYear}
            setVal={setVal.expYear}
            error={
              errorList.includes("expMonth") || errorList.includes("expYear")
            }
          />
        </div>
        <div className="flex flex-row flex-1 ml-1">
          <AddAccountInput
            name="Security code"
            placeholder=""
            val={cvc}
            setVal={setVal.cvc}
            error={errorList.includes("cvc")}
            helpLeft={true}
            helpTxt="The security code for the card. This is usually found on the back of a card"
          />
        </div>
      </div>
      <div className="mt-4">
        <AddAccountInput
          name="Name on card"
          placeholder="John doe"
          val={cardName}
          setVal={setVal.cardName}
          error={errorList.includes("cardName")}
          helpTxt="The name of the person this card belongs to"
        />
      </div>
    </OverlayContentWrapper>
  );
}

// return (
//   <>
//     <CardInput
//       name="Card number"
//       placeholder="1234 1234 1234 1234"
//       value={cardNum}
//       setVal={setVal.cardNum}
//       error={(() => {
//         if (errorList.includes("cardNum")) {
//           return true;
//         } else {
//           return false;
//         }
//       })()}
//     />
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         marginTop: 6,
//       }}
//     >
//       <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
//         <p style={{ color: "#555" }}>Expiry date</p>
//         <div style={{ display: "flex", flexDirection: "row" }}>
//           <CardInputTop
//             placeholder="MM"
//             style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
//             value={expMonth}
//             setVal={setVal.expMonth}
//             error={(() => {
//               if (
//                 errorList.includes("expMonth") ||
//                 errorList.includes("expYear")
//               ) {
//                 return true;
//               } else {
//                 return false;
//               }
//             })()}
//           />
//           <CardInputTop
//             placeholder="YY"
//             style={{
//               borderTopLeftRadius: 0,
//               borderBottomLeftRadius: 0,
//               borderLeft: "none",
//             }}
//             value={expYear}
//             setVal={setVal.expYear}
//             error={(() => {
//               if (
//                 errorList.includes("expMonth") ||
//                 errorList.includes("expYear")
//               ) {
//                 return true;
//               } else {
//                 return false;
//               }
//             })()}
//           />
//         </div>
//       </div>
//       <div className="addIdOverlay-seperator"></div>
//       <div style={{ flex: 1 }}>
//         <CardInputTop
//           name="Security code"
//           placeholder="000"
//           value={cvc}
//           setVal={setVal.cvc}
//           error={(() => {
//             if (errorList.includes("cvc")) {
//               return true;
//             } else {
//               return false;
//             }
//           })()}
//         />
//       </div>
//     </div>
//     <div style={{ marginTop: 12 }}>
//       <CardInput
//         name="Name on card"
//         placeholder="John doe"
//         value={cardName}
//         setVal={setVal.cardName}
//         error={(() => {
//           if (errorList.includes("cardName")) {
//             return true;
//           } else {
//             return false;
//           }
//         })()}
//       />
//     </div>
//     <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
//       <div style={{ flex: 1 }}></div>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//         }}
//       >
//         <div style={{ flex: 1, alignItems: "center", display: "flex" }}>
//           {(() => {
//             if (errorList.length != 0) {
//               return (
//                 <p style={{ color: "red" }}>
//                   Some of the information you entered was invalid
//                 </p>
//               );
//             } else {
//               return <></>;
//             }
//           })()}
//         </div>
//         <BtnTwo
//           style={{ width: 200 }}
//           name="Save"
//           onClick={submit}
//           loading={isSubbed}
//         />
//       </div>
//     </div>
//   </>
// );
