import React, { useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import ErrorIcon from "@mui/icons-material/Error";

export default function SettingsAccountInput({
  icon = null,
  name = "",
  value,
  setValue,
  isPassword = false,
  error,
}) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row h-[28px]">
        {name != "" ? (
          <p className="text-neutral-800 text-lg">{name}</p>
        ) : (
          <></>
        )}
        <div className="flex-1 flex flex-row items-center justify-end">
          {error ? (
            <ErrorIcon
              style={{ width: 16, height: 16 }}
              className="text-red-500"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className={`flex flex-row px-2 items-center border duration-100 ${
          error
            ? "border-red-500"
            : "focus-within:border-neutral-500 border-neutral-300"
        } rounded-lg`}
      >
        {(() => {
          let A = icon;
          if (A != null) {
            return <A className="mr-2" />;
          }
        })()}
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type={isPassword && !isVisible ? "password" : "text"}
          className="flex-1 w-full py-2 outline-none"
        />
        {isPassword ? (
          <>
            <div
              className="ml-2 cursor-pointer"
              onClick={() => setIsVisible(!isVisible)}
            >
              {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
