import React, { useState } from "react";

import GetFavUrl from "../utils/GetFavUrl";
import PasswordIcon from "@mui/icons-material/Password";

export default function AccountListButton({ account, onClick }) {
  const [imgErr, setImgErr] = useState(false);

  const getLogo = (domain) => {
    let url = GetFavUrl(domain, 32);
    return url;
  };

  const upperFirstLetter = (word) => {
    let name = "";
    for (let i = 0; i < account.name.length; i++) {
      let letter = account.name[i];
      if (i == 0) {
        letter = letter.toUpperCase();
      }
      name += letter;
    }
    return name;
  };

  return (
    <div
      onClick={onClick}
      className="grid grid-cols-3 p-4 min-[1260px]:grid-cols-4 border-b border-b-neutral-500 cursor-pointer hover:brightness-[0.85] hover:bg-[#efefef] overflow-hidden text-el"
    >
      <div className="flex flex-row items-center">
        {(() => {
          if (
            account.domain != "" &&
            account.domain != undefined &&
            imgErr == false &&
            account.showIcon
          ) {
            return (
              <>
                <img
                  className="select-none"
                  src={getLogo(account.domain)}
                  style={{ width: 32, height: 32, padding: 4 }}
                  onError={() => setImgErr(true)}
                  onLoad={(i) => {
                    let img = new Image();
                    img.onload = () => {
                      if (img.width == 16 && img.height == 16) {
                        setImgErr(true);
                      }
                    };

                    img.src = i.target.src;
                  }}
                />
              </>
            );
          } else {
            return <PasswordIcon style={{ width: 32, height: 32 }} />;
          }
        })()}
        <p className="ml-4 text-neutral-800 select-none overflow-hidden text-ellipsis">
          {account.name}
        </p>
      </div>
      <p className="flex items-center flex-row text-neutral-800 select-none text-ellipsis">
        {account.domain || ""}
      </p>
    </div>
  );
}

// return (
//   <div
//     className="encrypted-list-button"
//     onClick={onClick}
//     style={{ marginTop: 8 }}
//   >
//     <div className="encrypted-list-button-overlay"></div>
//     <div
//       style={{
//         flex: 1,
//         display: "flex",
//         flexDirection: "row",
//         alignItems: "center",
//       }}
//     >
//       <div
//         style={{
//           flex: 1,
//           padding: 4,
//           // borderRight: "1px solid #ccc",
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//         }}
//       >
//         <div
//           style={{
//             width: 32,
//             height: 32,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             marginRight: 8,
//           }}
//         >
//           {(() => {
//             if (
//               account.domain != "" &&
//               account.domain != undefined &&
//               imgErr == false &&
//               account.showIcon
//             ) {
//               return (
//                 <>
//                   <img
//                     src={getLogo(account.domain)}
//                     style={{ width: 32, height: 32, padding: 4 }}
//                     onError={() => setImgErr(true)}
//                     onLoad={(i) => {
//                       let img = new Image();
//                       img.onload = () => {
//                         if (img.width == 16 && img.height == 16) {
//                           setImgErr(true);
//                         }
//                       };

//                       img.src = i.target.src;
//                     }}
//                   />
//                 </>
//               );
//             } else {
//               return <PasswordIcon />;
//             }
//           })()}
//         </div>
//         <p style={{ fontSize: 14 }}>{upperFirstLetter(account.name)}</p>
//       </div>
//       <div
//         style={{
//           flex: 1,
//           padding: 4,
//         }}
//       >
//         <p
//           style={{ fontSize: 14, color: "#55f", textDecoration: "underline" }}
//         >
//           {account.domain}
//         </p>
//       </div>
//     </div>
//   </div>
// );
