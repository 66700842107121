import React from "react";

import BtnThree from "./BtnThree";

import BtnTwo from "./BtnTwo";

export default function OverlayContentWrapper({
  children,
  close,
  errMsg,
  loading,
  submit,
  deactivated,
}) {
  return (
    <div className="p-2 pt-4 flex flex-col flex-1">
      <div className="flex-1 flex flex-col bg-white relative">
        <div className="absolute left-0 top-0 w-full h-full flex flex-col overflow-y-auto overflow-x-hidden custom-scrollbar">
          {children}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="h-6">
          <p className={`text-red-500 ${errMsg == "" ? "hidden" : "flex"}`}>
            {errMsg}
          </p>
        </div>
        <div className="flex flex-row justify-between">
          <BtnTwo name="Cancel" onClick={close} />
          <BtnThree
            name="Save"
            loading={loading}
            onClick={submit}
            deactivated={deactivated}
          />
        </div>
      </div>
    </div>
  );
}
