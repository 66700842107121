import React, { useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import GenKey from "../../utils/GenKey";
import sha256 from "crypto-js/sha256";

import axios from "axios";
import KeyUtils from "../../utils/KeyUtils";

export default function PasswordInput({
  requirePassword,
  component,
  uid,
  encKey,
  setEncKey,
  wrappedKey,
}) {
  const [pwVisible, setPwVisible] = useState(false);
  const [pw, setPw] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [subbed, setSubbed] = useState(false);

  const verifyPwFunc = async () => {
    if (!subbed) {
      setSubbed(true);

      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      let hashedPw = sha256(pw).toString();

      let res = await axios.post("/api/verifyPw", {
        password: hashedPw,
        _csrf: csrfToken,
      });
      let data = res.data;
      if (data.status == "success") {
        // let k = GenKey(pw, uid);
        let k = KeyUtils.unWrapKey(pw, wrappedKey);

        if (requirePassword != true) {
          // do shi
          let keys = JSON.parse(localStorage.getItem("keys"));
          let out = [];

          keys.forEach((keyObj) => {
            let unwrapped = KeyUtils.unWrapKey(pw, keyObj.wrappedKey);

            keyObj["unWrapped"] = unwrapped;

            out.push(keyObj);
          });

          localStorage.setItem("keys", JSON.stringify(out));
          let privKey = KeyUtils.passwordToPriv(pw, true);
          localStorage.setItem("privKey", privKey);
        }

        localStorage.setItem(
          "hashedPw",
          sha256(sha256(hashedPw).toString()).toString()
        );
        setEncKey(k);
      } else {
        setErrMsg("Incorrect password");
      }
      setSubbed(false);
    }
  };

  return (
    <>
      {(() => {
        if (encKey == "" || localStorage.getItem("hashedPw") == null) {
          return (
            <>
              <div className="confirm-delete-account-overlay-container">
                <p>You need to enter your password to perform this action</p>
                <p style={{ color: "#777" }}>
                  To decide if you want to enter your password every time, go to
                  your settings
                </p>
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 4,
                    border: "1px solid black",
                    alignItems: "center",
                    paddingRight: 10,
                    borderRadius: 10,
                  }}
                >
                  <input
                    style={{
                      outline: "none",
                      border: "none",
                      flex: 1,
                      padding: 6,
                    }}
                    type={(() => {
                      if (pwVisible) {
                        return "text";
                      } else {
                        return "password";
                      }
                    })()}
                    value={pw}
                    onChange={(e) => {
                      if (!subbed) {
                        setPw(e.target.value);
                      }
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setPwVisible(!pwVisible)}
                  >
                    {(() => {
                      if (pwVisible) {
                        return <Visibility />;
                      } else {
                        return <VisibilityOff />;
                      }
                    })()}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 16,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {(() => {
                      if (errMsg == "") {
                        return <></>;
                      } else {
                        return <p style={{ color: "red" }}>{errMsg}</p>;
                      }
                    })()}
                  </div>
                  {(() => {
                    if (pw == "" || subbed) {
                      return (
                        <>
                          <button
                            style={{
                              fontSize: 16,
                              padding: 8,
                              borderRadius: 8,
                              border: "none",
                              outline: "none",
                              color: "#8f8f8f",
                              backgroundColor: "#e6e6e6",
                            }}
                          >
                            Continue
                          </button>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <button
                            className="overlay-password-continue-btn"
                            onClick={() => verifyPwFunc()}
                          >
                            Continue
                          </button>
                        </>
                      );
                    }
                  })()}
                </div>
              </div>
            </>
          );
        } else if (encKey != "" && encKey != undefined) {
          return (
            <div className="flex flex-col w-[100vw] h-[100vh] bg-white md:w-[576px] lg:w-[640px] md:h-[75vh] relative md:rounded-xl md:shadow-2xl">
              {component}
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
}
