const sha256 = require("crypto-js/sha256");

const GenDataHash = (username, password) => {
  let usernameHash = sha256(username).toString();
  let passwordHash = sha256(password).toString();
  let dataHash = sha256(usernameHash + passwordHash).toString();
  return dataHash
};

export default GenDataHash;
