import React, { useState } from "react";

import { Link } from "react-router-dom";
import axios from "axios";

import BtnTwo from "../components/BtnTwo";

import CheckIcon from "@mui/icons-material/Check";

import "../styles/subscriptionBox.css";

export default function SubscriptionBox({ register, freeTrialDone = false }) {
  const [annual, setAnnual] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkout = async () => {
    if (loading == false) {
      setLoading(true);
      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      let response = await axios.post("/api/checkout", {
        _csrf: csrfToken,
        year: annual,
      });
      let res = response.data;
      if (res.status == "success") {
        let url = res.data.url;
        document.location = url;
      }
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-center">
        <div className="bg-[#DB287B] p-3 rounded-xl">
          <button
            onClick={() => setAnnual(false)}
            className={
              !annual
                ? "bg-white text-[#DB287B] px-6 sm:px-16 py-4 text-xl font-bold rounded-xl mr-2"
                : "bg-[#DB287B] text-[#fff] px-6 sm:px-16 py-4 text-xl font-bold rounded-xl mr-2"
            }
          >
            Monthly
          </button>
          <button
            onClick={() => setAnnual(true)}
            className={
              annual
                ? "bg-white text-[#DB287B] px-6 sm:px-16 py-4 text-xl font-bold rounded-xl ml-2"
                : "bg-[#DB287B] text-[#fff] px-6 sm:px-16 py-4 text-xl font-bold rounded-xl ml-2"
            }
          >
            Annual
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center mt-12">
        <div className="border border-[#DB287B] rounded-xl p-6 w-[100%] sm:w-[512px] bg-white">
          <p className="text-[#55424D] font-bold text-xl">
            {!annual ? "Monthly subscription" : "Annual susbcription"}
          </p>
          <p className="flex items-center mt-8 mb-8">
            <span className="text-5xl text-[#55424D] font-bold">
              {!annual ? "$3.00" : "$2.50"}
            </span>
            <span className="text-[#8B647B] text-lg ml-4">
              / {!annual ? "Month" : "Month (Billed yearly)"}
            </span>
          </p>
          <PricingFeature text="Save unlimited passwords" />
          <PricingFeature text="Access on any device" />
          <PricingFeature text="Secure password generation" />
          <PricingFeature text="7 day free trial" />
          <PricingFeature text="Password AutoFill" />
          {register ? (
            <Link to="/signup">
              <button
                className={`w-[100%] py-4 rounded-md border border-[#DB287B] text-[#DB287B] font-bold ${
                  !loading
                    ? "hover:bg-[#DB287B] hover:text-[#fff] duration-150"
                    : "cursor-default"
                } relative`}
              >
                <p
                  className={`text-base select-none ${
                    loading ? "opacity-0" : ""
                  }`}
                >
                  {freeTrialDone ? "Subscribe" : "Start 7 day free trial!"}
                </p>
                {loading ? (
                  <div className="absolute left-0 top-0 w-[100%] h-[100%] flex justify-center items-center">
                    <div className="w-6 h-6 border border-[#DB287B] border-r-[#fff] rounded-[50%] animate-spin"></div>
                  </div>
                ) : (
                  <></>
                )}
              </button>
            </Link>
          ) : (
            <button
              onClick={!loading ? checkout : () => {}}
              className={`w-[100%] py-4 rounded-md border border-[#DB287B] text-[#DB287B] font-bold ${
                !loading
                  ? "hover:bg-[#DB287B] hover:text-[#fff] duration-150"
                  : "cursor-default"
              } relative`}
            >
              <p
                className={`text-base select-none ${
                  loading ? "opacity-0" : ""
                }`}
              >
                {freeTrialDone ? "Subscribe" : "Start 7 day free trial!"}
              </p>
              {loading ? (
                <div className="absolute left-0 top-0 w-[100%] h-[100%] flex justify-center items-center">
                  <div className="w-6 h-6 border border-[#DB287B] border-r-[#fff] rounded-[50%] animate-spin"></div>
                </div>
              ) : (
                <></>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const PricingFeature = ({ text }) => {
  return (
    <div className="flex flex-row items-center mb-8">
      <div className="bg-[#FFE7F2] rounded-[50%] p-2 justify-center items-center">
        <CheckIcon className="text-[#DB287B]" />
      </div>
      <p className="ml-4 text-md text-[#55424D]">{text}</p>
    </div>
  );
};
