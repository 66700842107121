import axios from "axios";

export default async () => {
  let csrfToken = await axios.get("/api/csrf");
  csrfToken = csrfToken.data.token;

  let res = await axios.post("/api/logout", { _csrf: csrfToken });
  if (res.data.status == "success") {
    localStorage.clear();
    document.location.pathname = "/";
  }
};
