import React, { useState } from "react";
import { validate } from "email-validator";
import { Link } from "react-router-dom";
import axios from "axios";
import { SHA256 } from "crypto-js";
import KeyUtils from "../utils/KeyUtils";

import AuthInput from "../components/AuthInput";

import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import PasswordStrengthBar from "react-password-strength-bar";
import BtnThree from "../components/BtnThree";


export default function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [conf, setConf] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isSubbed, setIsSubbed] = useState(false);
  const [tos, setTos] = useState(false);
  const [neverSubbed, setNeverSubbed] = useState(true);

  const validateData = () => {
    let errors = [];
    let validEmail = validate(email);
    if (!validEmail && (email != "" || !neverSubbed)) {
      errors.push("email");
    }
    if (password.length < 7 && (password != "" || !neverSubbed)) {
      errors.push("password");
    }
    if (password != conf && (conf != "" || !neverSubbed)) {
      errors.push("conf");
    }
    //? console.log(errors);
    return errors;
  };

  const verifyData = () => {
    let errors = [];
    let validEmail = validate(email);
    if (!validEmail) {
      errors.push("email");
    }
    if (password.length < 7) {
      errors.push("password");
    }
    if (password != conf) {
      errors.push("conf");
    }
    //? console.log(errors);
    return errors;
  };

  const register = async () => {
    setErrMsg("");
    if (neverSubbed) {
      setNeverSubbed(false);
    }

    if (!isSubbed) {
      setIsSubbed(true);
      if (verifyData().length == 0) {
        if (tos == false) {
          setErrMsg("You must accept the terms of service");
          setIsSubbed(false);
          return;
        }

        let pw = String(password);

        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        //
        let randAesKey = KeyUtils.genAesKey();
        let privKey = KeyUtils.passwordToPriv(pw);
        let pubKey = privKey.getPublic();

        let pubStr = pubKey.encode("hex");

        let wrappedAesKey = KeyUtils.wrapKey(pubStr, randAesKey);

        //? console.log(pubStr, wrappedAesKey);

        //

        let res = await axios.post(
          "/api/register",
          {
            email,
            password: SHA256(password).toString(),
            conf: SHA256(conf).toString(),
            pubStr,
            wrappedKey: wrappedAesKey,
            _csrf: csrfToken,
          },
          { withCredentials: true }
        );
        if (res.data.status == "error") {
          setErrMsg(res.data.message);
        } else if (res.data.status == "success") {
          let uid = res.data.data.uid;
          if (uid != undefined || pw != undefined) {
            let keys = [
              {
                id: uid,
                type: "account",
                wrappedKey: wrappedAesKey,
                unWrapped: randAesKey,
              },
            ];

            let prKey = KeyUtils.passwordToPriv(pw, true);

            localStorage.setItem("privKey", prKey);
            localStorage.setItem("keys", JSON.stringify(keys));
            localStorage.setItem(
              "hashedPw",
              SHA256(SHA256(SHA256(password).toString()).toString()).toString()
            );
            document.location.pathname = "/";
          }
        }
      } else {
        setErrMsg("Some of the data you entered is invalid");
      }
      setIsSubbed(false);
    }
  };

  return (
    <div className="mt-16 flex flex-col items-center mb-8">
      <div className="bg-white w-[100%] md:w-[75%] lg:w-[900px] flex flex-col">
        <h1 className="text-center text-3xl sm:text-5xl font-bold">
          Create account
        </h1>
        <div className="flex justify-center mt-4">
          <p className="text-gray-600 text-center w-[95%] sm:w-[65%]">
            Pass Protect provides the security and encryption necessary to
            safeguard your passwords and personal information
          </p>
        </div>
        <div className="bg-white rounded-3xl p-8 mt-8 md:shadow-2xl">
          <div>
            <AuthInput
              error={validateData().includes("email")}
              name="Email"
              placeholder="Email address"
              icon={MailIcon}
              value={email}
              setValue={setEmail}
            />
          </div>
          <div className="mt-8 flex flex-col">
            <AuthInput
              error={validateData().includes("password")}
              name="Password"
              placeholder="Password"
              icon={LockIcon}
              value={password}
              setValue={setPassword}
              password
            />
            <PasswordStrengthBar className="mt-4" password={password} />
          </div>
          <div className="mt-2">
            <AuthInput
              error={validateData().includes("conf")}
              name="Confirm password"
              placeholder="Confirm password"
              icon={LockIcon}
              value={conf}
              setValue={setConf}
              password
            />
          </div>
          <div className="mt-8 flex flex-col h-12">
            <BtnThree
              onClick={register}
              loading={isSubbed}
              flex1
              name="Sign up"
            />
          </div>
          <div className="mt-2 flex flex-row items-center">
            <input
              checked={tos}
              onChange={(e) => setTos(!tos)}
              type="checkbox"
              className="accent-[#DB287B] w-[16px] h-[16px] mr-2"
            />
            <p>
              I agree with the{" "}
              <span className="text-[#DB287B]">
                <Link to="/tos">Terms & Conditions</Link>
              </span>
            </p>
          </div>
          <div className="h-[24px]">
            {(() => {
              if (errMsg != "") {
                return <p className="text-red-500">{errMsg}</p>;
              }
            })()}
          </div>
          <div className="h-16 flex flex-row items-end justify-center">
            <p className="text-gray-500">
              Already have an account?{" "}
              <span className="font-bold text-[#DB287B]">
                <Link to="/login">Sign in</Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}