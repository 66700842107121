import React, { useState, useRef } from "react";
import BtnThree from "../BtnThree";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import LockIcon from "@mui/icons-material/Lock";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import ErrorIcon from "@mui/icons-material/Error";

import RestoreIcon from "@mui/icons-material/Restore";
import PasswordCsvDecoder from "../../utils/PasswordCsvDecoder";

import KeyUtils from "../../utils/KeyUtils";
import { SHA256 } from "crypto-js";
import encryption from "../../utils/encryption";

import GenDataHash from "../../utils/GenDataHash";
import axios from "axios";

import { Buffer } from "buffer";

import Paparse from "papaparse";

const genCsvUri = (data) => {
  let baseData = "data:text/csv;base64,";

  let b64Data = Buffer.from(data).toString("base64");

  return baseData + b64Data;
};

export default function SettingsImportExport({ uid, sync }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [succMsg, setSuccMsg] = useState("");
  const [isPwError, setIsPwError] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const downloadRef = useRef(null);

  const setStatusMessage = {
    error: (message) => {
      setErrMsg(message);
      setSuccMsg("");
    },
    success: (message) => {
      setSuccMsg(message);
      setErrMsg("");
    },
    clear: () => {
      setIsPwError(false);
      setErrMsg("");
      setSuccMsg("");
    },
  };

  const fileChangeHandler = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const exportSubmit = async () => {
    try {
      setExportLoading(true);
      setStatusMessage.clear();

      if (password == "") {
        setIsPwError(true);
        throw new Error("Invalid password");
      }

      let localHashedPw = localStorage.getItem("hashedPw");
      let myHashedPw = SHA256(
        SHA256(SHA256(password).toString()).toString()
      ).toString();

      if (localHashedPw != myHashedPw) {
        setIsPwError(true);
        throw new Error("Invalid password");
      }

      let wrappedKeys = localStorage.getItem("keys");

      if (wrappedKeys == undefined) {
        throw new Error("An error has occured");
      }

      wrappedKeys = JSON.parse(wrappedKeys);

      let myWk = wrappedKeys.find((wk) => wk.id == uid);

      if (myWk == undefined) {
        throw new Error("An error has occured");
      }

      myWk = myWk.wrappedKey;

      let myKey = KeyUtils.unWrapKey(password, myWk);

      let encedData = localStorage.getItem("encryptedData");
      if (encedData == "" || encedData == undefined) {
        throw new Error("");
      }

      encedData = JSON.parse(encedData);

      encedData = encedData.password;

      let csvLines = [["name", "domain", "username", "password"]];

      for (let i = 0; i < encedData.length; i++) {
        let ed = encedData[i];
        let name = ed.name || "";
        let domain = ed.domain || "";

        try {
          let encedData = ed.data;
          encedData = Buffer.from(encedData, "base64").toString("utf-8");
          encedData = JSON.parse(encedData);

          let encedPw = encedData.password || "";
          let encedUid = encedData.uid || "";

          let decedPw = "";
          let decedUid = "";

          if (encedPw != "") {
            decedPw = encryption.decrypt(encedPw, myKey);
          }

          if (encedUid != "") {
            decedUid = encryption.decrypt(encedUid, myKey);
          }

          let line = [name, domain, decedUid, decedPw];
          csvLines.push(line);
        } catch {
          continue;
        }
      }

      let parsedCsv = Paparse.unparse(csvLines);

      let csvUri = genCsvUri(parsedCsv);

      downloadRef.current.setAttribute("download", "PassProtect_export.csv");
      downloadRef.current.href = csvUri;
      downloadRef.current.click();

      setExportLoading(false);
    } catch (e) {
      let eMsg = e.message || "An error has occured";

      setStatusMessage.error(eMsg);
      setExportLoading(false);
    }
  };

  const importSubmit = async () => {
    try {
      setImportLoading(true);
      setStatusMessage.clear();

      if (selectedFile == null) {
        throw new Error("No file selected");
      }

      if (password == "") {
        setIsPwError(true);
        throw new Error("Invalid password");
      }

      if (selectedFile.type != "text/csv") {
        throw new Error("Passwords must be in a csv file");
      }

      let decodedFile = await PasswordCsvDecoder(selectedFile);

      let localHashPw = localStorage.getItem("hashedPw");
      if (localHashPw == undefined) {
        throw new Error("An error has occured");
      }

      let myHashedPw = SHA256(
        SHA256(SHA256(password).toString()).toString()
      ).toString();

      if (myHashedPw != localHashPw) {
        setIsPwError(true);
        throw new Error("Invalid password");
      }

      // Correct password;

      let wrappedKeys = JSON.parse(localStorage.getItem("keys"));
      let foundKey = wrappedKeys.find((wk) => wk.id == uid);
      if (foundKey == undefined) {
        throw new Error("");
      }

      let wrappedKey = foundKey.wrappedKey;
      let unwrappedKey = KeyUtils.unWrapKey(password, wrappedKey);

      let postPasswords = [];

      decodedFile.forEach((f) => {
        let o = {
          name: f.name,
          domain: f.domain || "",
        };

        let uName = f.uid || "";
        let pw = f.password || "";

        if (uName != "") {
          uName = encryption.encrypt(uName, unwrappedKey);
        }

        if (pw != "") {
          pw = encryption.encrypt(pw, unwrappedKey);
        }

        o["uid"] = uName;
        o["password"] = pw;

        let dh = GenDataHash(f.uid || "", f.password || "");

        o["dataHash"] = dh;

        postPasswords.push(o);
      });

      let out = {};

      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      out["_csrf"] = csrfToken;
      out["password"] = SHA256(password).toString();
      out["passwordList"] = postPasswords;

      let res = await axios.post("/api/importPasswords", out);

      let resData = res.data;
      if (resData.status == "success") {
        setStatusMessage.success("Success!");
        setSelectedFile(null);
        sync();
        setImportLoading(false);
      } else {
        setImportLoading(false);
      }
    } catch (e) {
      let emsg = e.message;
      if (emsg == undefined || emsg == "") {
        emsg = "An error has occured";
      }

      setStatusMessage.error(emsg);
      setImportLoading(false);
    }
  };

  return (
    <div className="flex flex-1 flex-col">
      <p className="text-2xl text-neutral-800 font-bold">Import passwords</p>
      <div className="flex flex-row mt-2">
        <input
          id="file-upload"
          onChange={fileChangeHandler}
          className="hidden"
          type="file"
        />
        <label
          htmlFor="file-upload"
          className="flex flex-row flex-1 bg-white outline-none border-y border-l border-y-neutral-800 border-l-neutral-800 text-neutral-500 text-sm p-2 rounded-l-lg cursor-pointer font-bold overflow-hidden
        text-ellipsis
        "
        >
          {selectedFile == null ? "Choose file" : selectedFile.name}
        </label>
        <div
          className="flex items-center justify-center border-y border-r border-y-neutral-800 border-r-neutral-800 cursor-pointer group"
          onClick={() => setSelectedFile(null)}
        >
          <span className="flex-1 px-1 duration-100 group-hover:rotate-[22.5deg]">
            <RestoreIcon />
          </span>
        </div>
        <button
          onClick={importLoading ? () => {} : importSubmit}
          className={`rounded-r-lg px-2 flex border-y border-r ${
            importLoading
              ? "bg-white cursor-default"
              : "bg-[#DB287B] duration-100 hover:brightness-90"
          } border-y-neutral-800 border-r-neutral-800 flex-row items-center relative`}
        >
          <div
            className={`${
              importLoading ? "flex" : "hidden"
            } left-0 top-0 w-full h-full absolute items-center justify-center`}
          >
            <span className="w-6 h-6 animate-spin border-y-2 border-y-[#aaa] border-l-2 border-l-[#aaa] rounded-full border-r-2 border-r-white"></span>
          </div>
          <p
            className={`text-sm ${
              importLoading ? "opacity-0" : "text-white"
            } select-none`}
          >
            Import
          </p>
          <FileUploadIcon
            className={`${importLoading ? "opacity-0" : "text-white"} ml-1`}
          />
        </button>
      </div>
      <p className="text-2xl text-neutral-800 font-bold mt-6">
        Export passwords
      </p>
      {exportLoading ? (
        <button
          className={`bg-white border border-[#aaa] cursor-default justify-center rounded-lg py-2 mt-1 flex flex-row items-center duration-100 relative`}
        >
          <p className={`text-sm select-none text-[#aaa] opacity-0`}>Export</p>
          <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center">
            <div className="w-6 h-6 rounded-full border-y border-y-[#aaa] border-l border-l-[#aaa] border-r border-r-white animate-spin"></div>
          </div>
        </button>
      ) : (
        <button
          onClick={selectedFile == null ? exportSubmit : () => {}}
          className={`${
            selectedFile == null
              ? "bg-[#DB287B] hover:brightness-90 border border-[#DB287B]"
              : "bg-white border border-[#aaa] cursor-default"
          } justify-center rounded-lg py-2 mt-1 flex flex-row items-center duration-100`}
        >
          <p
            className={`text-sm select-none ${
              selectedFile == null ? "text-white" : "text-[#aaa]"
            }`}
          >
            Export
          </p>
          <FileDownloadIcon
            className={`${
              selectedFile == null ? "text-white" : "text-[#aaa]"
            } ml-1`}
          />
        </button>
      )}
      {selectedFile == null ? (
        <></>
      ) : (
        <p className="text-sm text-[#aaa]">
          You can't import and export at the same time
        </p>
      )}
      <a
        ref={downloadRef}
        onClick={() => {
          //?console.log("ASD");
        }}
        className="hidden"
      >
        Hello world
      </a>
      <div className="flex-1"></div>
      {(() => {
        if (errMsg != "") {
          return <p className="ml-2 text-red-500 text-sm">{errMsg}</p>;
        } else if (succMsg != "") {
          return <p className="ml-2 text-green-700 text-sm">{succMsg}</p>;
        }
      })()}
      <div className="flex flex-row items-center">
        <p className="text-[#aaa] ml-2">Enter your password to confirm</p>
        <div className="flex-1"></div>
        {isPwError ? (
          <ErrorIcon
            style={{ width: 16, height: 16 }}
            className="text-red-500"
          />
        ) : (
          <></>
        )}
      </div>
      <div
        className={`flex flex-row border items-center rounded-lg ${
          isPwError
            ? "border-red-500"
            : "duration-100 border-neutral-300 focus-within:border-neutral-500"
        } px-1`}
      >
        <LockIcon />
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="flex-1 outline-none p-1 w-full"
          type={visible ? "text" : "password"}
        />
        <div className="cursor-pointer" onClick={() => setVisible(!visible)}>
          {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </div>
      </div>
    </div>
  );
}
