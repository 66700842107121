const elliptic = require("elliptic");
const secp256k1 = new elliptic.ec("secp256k1");
const { AES, SHA256, SHA512 } = require("crypto-js");
const { Buffer } = require("buffer");

let keys = {
  privFromPw: (password) => {
    for (let i = 0; i < 256; i++) {
      password = SHA512(password).toString();
    }
    return SHA256(password).toString();
  },
  privToPub: (privKey) => {
    let pub = secp256k1.keyFromPrivate(privKey).getPublic();
    let arr = pub.encode("array");
    return Buffer.from(arr).toString("hex");
  },
  pubToKey: (pub) => {
    let buf = Buffer.from(pub, "hex");
    return secp256k1.keyFromPublic(buf).getPublic();
  },
  privtoKey: (priv) => {
    let buf = Buffer.from(priv, "hex");
    return secp256k1.keyFromPrivate(buf);
  },
};

const encrypt = (pubKey, plaintext) => {
  const ephemeral = secp256k1.genKeyPair();

  let pub = keys.pubToKey(pubKey);

  const sharedSecret = ephemeral.derive(pub);

  const cipherText = AES.encrypt(plaintext, sharedSecret.toString(16));

  let obj = {
    cipherText: cipherText.toString(),
    ephemeral: ephemeral.getPublic().encode("hex"),
  };

  let jsonStr = JSON.stringify(obj);

  return Buffer.from(jsonStr).toString("base64");
};

const decrypt = (privateKey, enced) => {
  try {
    let priv = keys.privtoKey(privateKey);

    let jsonStr = Buffer.from(enced, "base64").toString();
    let json = JSON.parse(jsonStr);
    let ephPub = keys.pubToKey(json.ephemeral);

    let sharedSecret = priv.derive(ephPub);

    let bytes = AES.decrypt(json.cipherText, sharedSecret.toString(16));

    let bufBytes = Buffer.from(bytes.toString(), "hex");
    let str = bufBytes.toString();

    if (str.length == 0) {
      throw new Error("Incorrect key");
    }

    return str;
  } catch {
    throw new Error("Incorrect key");
  }
};

module.exports = {keys: keys, encrypt, decrypt}
