import React, { useEffect, useState } from "react";

import encryption from "../../utils/encryption";
import Logout from "../../utils/Logout";

import ViewAccountField from "../ViewAccountField";
import GenDataHash from "../../utils/GenDataHash";

import DeleteIcon from "@mui/icons-material/Delete";
import BtnTwo from "../BtnTwo";
import axios from "axios";
import validDomain from "is-valid-domain";
import OverlayContentWrapper from "../OverlayContentWrapper";
import AddAccountInput from "../AddAccountInput";

export default function ViewAccountOverlay({
  account,
  sync,
  close,
  promptDeleteAccount,
  encKey,
  setOverlay,
}) {
  const [accInfo, setAccInfo] = useState(undefined);
  const [ogAccInfo, setOgAccInfo] = useState(undefined);
  const [invalid, setInvalid] = useState("");
  const [submited, setSubmited] = useState(false);
  const [showDomainIcon, setShowDomainIcon] = useState(
    account.showIcon || false
  );

  const parseDomain = (url) => {
    url = url.split("://");
    if (url.length > 1) {
      url = url[1];
    } else {
      url = url[0];
    }
    url = url.split("/");
    url = url[0];

    return url;
  };

  const updateAcc = (name, val) => {
    let updated = JSON.parse(JSON.stringify(accInfo));
    updated[name] = val;
    setAccInfo(updated);
  };

  const isValid = () => {
    let inv = [];
    if (accInfo.domain != "" && !validDomain(parseDomain(accInfo.domain))) {
      inv.push("domain");
    }

    if (accInfo.password == "") {
      inv.push("password");
    }

    if (accInfo.name == "") {
      inv.push("name");
    }

    return inv;
  };

  const updateReq = async () => {
    try {
      setSubmited(true);
      let inv = isValid();
      setInvalid(inv);

      if (inv.length == 0) {
        let key = encKey;
        let out = {
          id: account.id,
          name: accInfo.name,
          domain: parseDomain(accInfo.domain),
          uid: encryption.encrypt(accInfo.uid, key),
          password: encryption.encrypt(accInfo.password, key),
          showIcon: showDomainIcon,
          dataHash: GenDataHash(accInfo.uid, accInfo.password),
        };

        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        out["_csrf"] = csrfToken;
        out["hashedPw"] = localStorage.getItem("hashedPw");

        let res = await axios.post("/api/updatePassword", out);
        let status = res.data.status;
        if (status == "success") {
          close();
          await sync();
        } else {
          if (res.data.message == "pwHashError") {
            localStorage.removeItem("hashedPw");
            setOverlay({ type: "view-account", data: account });
            setSubmited(false);
            return;
          }
          close();
        }
      }
      setSubmited(false);
    } catch {
      setSubmited(false);
    }
  };

  const isChanged = () => {
    let changed = true;
    if (JSON.stringify(accInfo) == JSON.stringify(ogAccInfo)) {
      changed = false;
    }

    let ogShowIcon = account.showIcon || false;
    if (ogShowIcon != showDomainIcon) {
      changed = true;
    }
    return changed;
  };

  const getAccountInfo = (account) => {
    try {
      let output = {
        name: account.name,
      };
      if (account.domain != undefined) {
        output["domain"] = account.domain;
      }

      let key = encKey;

      if (key == null) {
        Logout();
        return;
      }

      let jsonData = JSON.parse(window.atob(account.data));
      let uid;
      let password;

      if (account.fields.includes("uid") && jsonData["uid"] != undefined) {
        uid = encryption.decrypt(jsonData["uid"], key);
      }

      if (
        account.fields.includes("password") &&
        jsonData["password"] != undefined
      ) {
        password = encryption.decrypt(jsonData["password"], key);
      }

      if (uid != undefined) {
        output["uid"] = uid;
      }

      if (password != undefined) {
        output["password"] = password;
      }

      return output;
    } catch (e) {
      //? console.log(e);
      close();
      return undefined;
    }
  };

  useEffect(() => {
    let info = getAccountInfo(account);
    setAccInfo(info);
    setOgAccInfo(info);
  }, []);

  return (
    <OverlayContentWrapper
      deactivated={!isChanged()}
      close={close}
      errMsg={
        invalid.length == 0
          ? ""
          : "Some of the information you entered was invalid"
      }
      loading={submited}
      submit={updateReq}
    >
      {(() => {
        if (accInfo != undefined) {
          return (
            <>
              <div className="mb-4">
                <AddAccountInput
                  copy
                  name="Name"
                  helpTxt="The name used to identify this account"
                  val={accInfo.name}
                  setVal={(v) => updateAcc("name", v)}
                  error={invalid.includes("name")}
                  placeholder="Name for the account"
                />
              </div>
              <div className="mb-4">
                <AddAccountInput
                  copy
                  name="User ID"
                  helpTxt="This is what whatever name you use to log into an account. E.g: An email or a username"
                  val={accInfo.uid}
                  setVal={(v) => updateAcc("uid", v)}
                  placeholder="Username or email"
                />
              </div>
              <div className="mb-4">
                <AddAccountInput
                  copy
                  name="Password"
                  helpTxt="The password you use to log into this account"
                  val={accInfo.password}
                  setVal={(v) => updateAcc("password", v)}
                  error={invalid.includes("password")}
                  placeholder="Password"
                />
              </div>
              <AddAccountInput
                copy
                name="Domain"
                helpTxt="The url of the website this account belongs to. E.g: pass-protect.com"
                val={accInfo.domain}
                setVal={(v) => updateAcc("domain", v)}
                error={invalid.includes("domain")}
                placeholder="E.g: www.google.com"
              />
            </>
          );
        }
      })()}
    </OverlayContentWrapper>
  );
}

// return (
//   <>
//     {(() => {
//       if (accInfo != undefined) {
//         return (
//           <>
//             <ViewAccountField
//               name="Name"
//               value={accInfo.name}
//               update={updateAcc}
//               error={invalid.includes("name")}
//             />
//             {(() => {
//               if (true) {
//                 return (
//                   <ViewAccountField
//                     name="User ID"
//                     value={accInfo.uid}
//                     update={updateAcc}
//                   />
//                 );
//               } else {
//                 return <></>;
//               }
//             })()}
//             {(() => {
//               if (true) {
//                 return (
//                   <ViewAccountField
//                     name="Password"
//                     value={accInfo.password}
//                     update={updateAcc}
//                     error={invalid.includes("password")}
//                   />
//                 );
//               } else {
//                 return <></>;
//               }
//             })()}
//             {(() => {
//               if (true) {
//                 return (
//                   <ViewAccountField
//                     name="Domain"
//                     value={accInfo.domain}
//                     update={updateAcc}
//                     error={invalid.includes("domain")}
//                   />
//                 );
//               } else {
//                 return <></>;
//               }
//             })()}
//           </>
//         );
//       } else {
//         return <></>;
//       }
//     })()}
//     <div
//       style={{
//         marginTop: 16,
//         display: "flex",
//         flexDirection: "row",
//         alignItems: "center",
//       }}
//     >
//       <input
//         type="checkbox"
//         style={{ width: 16, height: 16, marginRight: 8, outline: "none" }}
//         checked={showDomainIcon}
//         onChange={() => setShowDomainIcon(!showDomainIcon)}
//       />
//       <p>Show domain icon</p>
//     </div>
//     <div className="viewAccountFooterBox">
//       <div style={{ flex: 1 }}></div>
//       <div
//         className="viewAccountFooterContainer"
//         style={{ display: "flex", flexDirection: "row" }}
//       >
//         <div style={{ display: "flex", flexDirection: "column" }}>
//           <div style={{ flex: 1 }}></div>
//           <div
//             className="viewAccount-deletebtn"
//             onClick={() => {
//               promptDeleteAccount({ id: account.id, name: account.name });
//             }}
//           >
//             <DeleteIcon />
//             <p>Delete</p>
//           </div>
//         </div>
//         <div
//           style={{
//             flex: 1,
//             padding: 8,
//             display: "flex",
//             alignItems: "center",
//           }}
//         >
//           {(() => {
//             if (invalid.length > 0) {
//               return (
//                 <p style={{ color: "red" }}>
//                   Some of the information you entered was invalid
//                 </p>
//               );
//             } else {
//               return <></>;
//             }
//           })()}
//         </div>
//         <BtnTwo
//           name="Save"
//           style={{ minWidth: 200 }}
//           onClick={() => {
//             updateReq();
//           }}
//           loading={submited}
//           deactivated={!isChanged() || submited}
//         />
//       </div>
//     </div>
//   </>
// );
