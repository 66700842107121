import React from "react";

import ErrorIcon from "@mui/icons-material/Error";

export default function AddNoteTextArea({ value, setValue, error }) {
  return (
    <div className="flex flex-col flex-1">
      <div
        className={`flex-1 bg-white flex-col flex p-2 border border-black rounded-lg duration-100 relative ${
          error
            ? "border-red-500"
            : "border-neutral-300 focus-within:border-neutral-500"
        }`}
      >
        {error ? (
          <div className="absolute flex flex-row items-center justify-end right-2">
            <ErrorIcon
              style={{ width: 16, height: 16 }}
              className="text-red-500"
            />
          </div>
        ) : (
          <></>
        )}
        <textarea
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Enter note here"
          className="bg-white flex-1 resize-none outline-none custom-scrollbar"
          autoComplete="false"
        ></textarea>
        <p className="flex flex-row items-center justify-end">
          {value.length}/750 characters used
        </p>
      </div>
    </div>
  );

  return (
    <div
      className="note-overlay-textarea-container"
      style={(() => {
        let s = { flex: "1", display: "flex", flexDirection: "column" };
        if (error) {
          s["border"] = "1px dashed red";
        }
        return s;
      })()}
    >
      <textarea
        className="note-overlay-textarea custom-scrollbar"
        placeholder="Enter note here"
        value={value}
        onChange={(e) => {
          let val = e.target.value;
          if (val.length > 750) {
            return;
          } else {
            setValue(val);
          }
        }}
      ></textarea>
      <div>
        <p style={{ fontWeight: "bold" }}>{value.length}/750 characters used</p>
      </div>
    </div>
  );
}
