import React from "react";

import axios from "axios";
import BtnThree from "../BtnThree";
import BtnTwo from "../BtnTwo";

export default function ConfirmDeleteCard({ data, close, sync }) {
  const deleteCard = async () => {
    let id = data.id;

    let csrfToken = await axios.get("/api/csrf");
    csrfToken = csrfToken.data.token;

    await axios.post("/api/deleteEncrypted", { id, _csrf: csrfToken });

    close();
    sync();
  };

  return (
    <div className="p-3 flex flex-col">
      <p className="text-lg text-center text-neutral-800">
        Are you sure you want to delete card ending with "{data.name}"? This
        action cannot be undone.
      </p>
      <div className="flex flex-col mt-2">
        <div className="mb-2 flex flex-col">
          <BtnTwo name="Cancel" onClick={close} />
        </div>
        <BtnThree name="Delete" onClick={deleteCard} />
      </div>
    </div>
  );
}