import React from "react";

export default function LgLandingSvg() {
  return (
    <>
      <svg
        width="500"
        height="348"
        viewBox="0 0 500 348"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_265_85)">
          <path
            d="M99.6217 348C154.641 348 199.243 344.611 199.243 340.431C199.243 336.251 154.641 332.862 99.6217 332.862C44.6021 332.862 0 336.251 0 340.431C0 344.611 44.6021 348 99.6217 348Z"
            fill="#E6E6E6"
          />
          <path
            d="M76.1489 77.2151C93.4861 69.8384 115.08 74.5122 136.168 77.756C136.365 73.8277 138.743 69.0468 136.252 66.3063C133.224 62.9767 133.503 59.4783 135.325 55.9597C139.983 46.9622 133.309 37.3883 126.57 29.4278C125.052 27.6375 123.132 26.2309 120.968 25.3222C118.804 24.4135 116.455 24.0282 114.114 24.1978L101.632 25.0897C98.5942 25.3068 95.6954 26.4466 93.3231 28.3568C90.9509 30.2669 89.2184 32.8563 88.3575 35.7784C85.3769 39.8335 83.7816 43.8774 84.786 47.9003C80.3215 50.9523 79.5714 54.6365 81.5776 58.7897C83.2787 61.4047 83.2606 63.9502 81.5002 66.4252C79.1882 69.5806 77.4175 73.0991 76.2605 76.8363L76.1489 77.2151Z"
            fill="#2F2E41"
          />
          <path
            d="M488.152 300.84H134.799C131.657 300.84 128.643 299.592 126.421 297.369C124.199 295.146 122.951 292.131 122.951 288.988C251.041 274.096 376.724 274.096 500 288.988C500 292.131 498.752 295.146 496.53 297.369C494.308 299.592 491.294 300.84 488.152 300.84Z"
            fill="#2F2E41"
          />
          <path
            d="M500 289.685L122.951 288.988L166.65 215.434L166.859 215.085V52.98C166.859 51.0123 167.246 49.064 167.998 47.2461C168.751 45.4282 169.854 43.7765 171.245 42.3852C172.636 40.9939 174.287 39.8902 176.104 39.1374C177.922 38.3845 179.869 37.997 181.836 37.9972H439.024C440.991 37.997 442.939 38.3845 444.756 39.1374C446.573 39.8902 448.224 40.9939 449.615 42.3852C451.006 43.7765 452.109 45.4282 452.862 47.2461C453.614 49.064 454.002 51.0123 454.002 52.98V216.201L500 289.685Z"
            fill="#3F3D56"
          />
          <path
            d="M181.495 48.4552C180.201 48.4567 178.961 48.9713 178.046 49.8862C177.132 50.8012 176.617 52.0417 176.616 53.3356V201.141C176.617 202.435 177.132 203.676 178.047 204.591C178.961 205.505 180.201 206.02 181.495 206.022H441.456C442.75 206.02 443.99 205.505 444.904 204.591C445.819 203.676 446.334 202.435 446.335 201.141V53.3356C446.334 52.0417 445.819 50.8012 444.904 49.8863C443.99 48.9714 442.75 48.4567 441.456 48.4552H181.495Z"
            fill="white"
          />
          <path
            d="M182.584 225.543C182.184 225.544 181.793 225.659 181.456 225.874C181.12 226.09 180.852 226.398 180.684 226.761L167.219 256.043C167.073 256.361 167.009 256.712 167.033 257.062C167.057 257.412 167.169 257.75 167.358 258.045C167.547 258.341 167.808 258.584 168.115 258.752C168.423 258.92 168.768 259.009 169.119 259.009H452.279C452.635 259.009 452.986 258.917 453.297 258.744C453.608 258.57 453.87 258.32 454.057 258.016C454.245 257.713 454.351 257.367 454.367 257.011C454.383 256.655 454.308 256.3 454.148 255.981L439.512 226.699C439.34 226.351 439.073 226.058 438.743 225.854C438.412 225.65 438.031 225.542 437.643 225.543H182.584Z"
            fill="#2F2E41"
          />
          <path
            d="M310.43 46.015C312.162 46.015 313.566 44.6103 313.566 42.8776C313.566 41.1449 312.162 39.7402 310.43 39.7402C308.698 39.7402 307.294 41.1449 307.294 42.8776C307.294 44.6103 308.698 46.015 310.43 46.015Z"
            fill="white"
          />
          <path
            d="M282.598 264.586C282.138 264.587 281.691 264.739 281.327 265.018C280.962 265.298 280.699 265.69 280.579 266.134L277.201 278.683C277.118 278.993 277.107 279.318 277.169 279.633C277.231 279.948 277.365 280.245 277.561 280.5C277.756 280.754 278.007 280.961 278.295 281.103C278.583 281.245 278.899 281.319 279.22 281.319H343.453C343.787 281.319 344.115 281.239 344.411 281.086C344.708 280.934 344.963 280.712 345.156 280.44C345.35 280.169 345.475 279.855 345.523 279.525C345.57 279.195 345.538 278.858 345.429 278.543L341.086 265.993C340.944 265.583 340.677 265.226 340.323 264.974C339.969 264.722 339.545 264.586 339.11 264.586H282.598Z"
            fill="#2F2E41"
          />
          <path
            d="M454.001 212.645V215.434H166.649L166.866 215.085V212.645H454.001Z"
            fill="#2F2E41"
          />
          <path
            d="M445.987 97.6075C472.931 97.6075 494.773 75.7573 494.773 48.8038C494.773 21.8502 472.931 0 445.987 0C419.043 0 397.2 21.8502 397.2 48.8038C397.2 75.7573 419.043 97.6075 445.987 97.6075Z"
            fill="#9D5083"
          />
          <path
            d="M466.198 70.0683H425.775C425.036 70.0676 424.327 69.7736 423.805 69.2507C423.282 68.7279 422.988 68.019 422.987 67.2796V42.8776C422.988 42.1383 423.282 41.4294 423.805 40.9066C424.327 40.3837 425.036 40.0897 425.775 40.0889H466.198C466.937 40.0897 467.646 40.3837 468.169 40.9066C468.691 41.4294 468.985 42.1383 468.986 42.8776V67.2795C468.985 68.0189 468.691 68.7279 468.169 69.2507C467.646 69.7736 466.937 70.0676 466.198 70.0683ZM425.775 42.8777V67.2796H466.2L466.198 42.8776L425.775 42.8777Z"
            fill="white"
          />
          <path
            d="M458.532 42.8777H433.441V31.7225C433.441 23.6428 438.718 17.7786 445.986 17.7786C453.255 17.7786 458.532 23.6428 458.532 31.7225L458.532 42.8777ZM436.229 40.0889H455.744V31.7225C455.744 25.1546 451.732 20.5674 445.987 20.5674C440.241 20.5674 436.229 25.1546 436.229 31.7225L436.229 40.0889Z"
            fill="white"
          />
          <path
            d="M445.987 57.1701C447.526 57.1701 448.774 55.9215 448.774 54.3813C448.774 52.8411 447.526 51.5925 445.987 51.5925C444.447 51.5925 443.199 52.8411 443.199 54.3813C443.199 55.9215 444.447 57.1701 445.987 57.1701Z"
            fill="white"
          />
          <path
            d="M411.929 155.887H211.022C210.035 155.886 209.089 155.494 208.392 154.796C207.695 154.098 207.302 153.152 207.301 152.166V102.311C207.302 101.324 207.695 100.378 208.392 99.6807C209.089 98.983 210.035 98.5905 211.022 98.5894H411.929C412.916 98.5905 413.861 98.983 414.559 99.6807C415.256 100.378 415.649 101.324 415.65 102.311V152.166C415.649 153.152 415.256 154.098 414.559 154.796C413.861 155.494 412.916 155.886 411.929 155.887ZM211.022 100.078C210.43 100.079 209.862 100.314 209.444 100.733C209.025 101.152 208.79 101.719 208.789 102.311V152.166C208.79 152.758 209.025 153.325 209.444 153.744C209.862 154.163 210.43 154.398 211.022 154.399H411.929C412.521 154.398 413.089 154.163 413.507 153.744C413.926 153.325 414.161 152.758 414.162 152.166V102.311C414.161 101.719 413.926 101.151 413.507 100.733C413.089 100.314 412.521 100.079 411.929 100.078L211.022 100.078Z"
            fill="#E6E6E6"
          />
          <path
            d="M242.238 140.486C250.609 140.486 257.394 133.699 257.394 125.325C257.394 116.952 250.609 110.165 242.238 110.165C233.868 110.165 227.083 116.952 227.083 125.325C227.083 133.699 233.868 140.486 242.238 140.486Z"
            fill="#E6E6E6"
          />
          <path
            d="M277.24 115.218C276.908 115.218 276.579 115.283 276.272 115.409C275.966 115.536 275.687 115.722 275.452 115.957C275.217 116.192 275.03 116.47 274.903 116.777C274.776 117.084 274.71 117.413 274.71 117.745C274.71 118.077 274.776 118.406 274.903 118.713C275.03 119.02 275.217 119.299 275.452 119.533C275.687 119.768 275.966 119.954 276.272 120.081C276.579 120.207 276.908 120.272 277.24 120.272H396.318C396.988 120.272 397.631 120.006 398.104 119.532C398.578 119.058 398.844 118.415 398.844 117.745C398.844 117.075 398.578 116.432 398.104 115.958C397.631 115.484 396.988 115.218 396.318 115.218H277.24Z"
            fill="#E6E6E6"
          />
          <path
            d="M277.24 130.379C276.57 130.379 275.928 130.645 275.454 131.119C274.98 131.593 274.714 132.236 274.714 132.906C274.714 133.576 274.98 134.219 275.454 134.693C275.928 135.167 276.57 135.433 277.24 135.433H328.48C329.15 135.433 329.792 135.167 330.266 134.693C330.74 134.219 331.006 133.576 331.006 132.906C331.006 132.236 330.74 131.593 330.266 131.119C329.792 130.645 329.15 130.379 328.48 130.379H277.24Z"
            fill="#E6E6E6"
          />
          <path
            d="M166.155 131.401L129.016 151.153L128.546 134.693C140.668 132.669 152.201 129.144 162.864 123.406L166.749 113.806C167.172 112.76 167.86 111.842 168.745 111.143C169.63 110.443 170.682 109.985 171.797 109.815C172.912 109.645 174.052 109.767 175.106 110.171C176.159 110.575 177.09 111.245 177.806 112.117C178.853 113.393 179.377 115.02 179.271 116.667C179.164 118.315 178.436 119.861 177.233 120.991L166.155 131.401Z"
            fill="#FFB8B8"
          />
          <path
            d="M75.4086 245.442C75.1377 244.448 75.0802 243.408 75.2398 242.39C75.3995 241.372 75.7728 240.4 76.335 239.537L84.4935 227.011C86.4232 224.049 89.3841 221.909 92.8018 221.007C96.2195 220.105 99.8503 220.505 102.99 222.13C99.561 227.968 100.039 233.09 104.174 237.541C97.7549 240.676 91.8222 244.722 86.5597 249.554C85.599 250.209 84.4957 250.625 83.3417 250.767C82.1877 250.909 81.0164 250.774 79.9254 250.372C78.8344 249.969 77.8553 249.312 77.0697 248.455C76.284 247.597 75.7145 246.564 75.4086 245.442Z"
            fill="#2F2E41"
          />
          <path
            d="M167.847 229.065C166.612 231.124 164.869 232.83 162.786 234.021C160.702 235.211 158.348 235.846 155.948 235.864L101.749 236.275L99.3982 222.637L123.374 215.582L103.159 200.063L125.255 174.197L165.387 211.616C167.733 213.803 169.257 216.729 169.704 219.905C170.152 223.081 169.496 226.315 167.847 229.065Z"
            fill="#2F2E41"
          />
          <path
            d="M93.2868 322.337H80.5936C69.1969 244.19 57.5962 165.824 91.8764 136.574L132.307 143.158L127.135 177.489L104.57 203.355L93.2868 322.337Z"
            fill="#2F2E41"
          />
          <path
            d="M104.445 340.04C103.451 340.311 102.411 340.369 101.394 340.209C100.376 340.05 99.4039 339.676 98.5409 339.114L84.7588 337.261C81.7972 335.33 79.658 332.369 78.7561 328.95C77.8542 325.531 78.254 321.899 79.8779 318.758C85.7145 322.188 90.8345 321.709 95.2837 317.573C98.4274 323.923 103.607 323.535 108.554 328.885C109.209 329.846 109.625 330.95 109.767 332.104C109.909 333.258 109.774 334.43 109.372 335.522C108.97 336.613 108.313 337.592 107.456 338.378C106.599 339.164 105.566 339.734 104.445 340.04Z"
            fill="#2F2E41"
          />
          <path
            d="M123.374 84.3726L99.8684 78.7292C103.772 70.7352 104.094 61.8403 102.219 52.3931L118.203 51.9229C118.704 63.6707 120.219 74.6461 123.374 84.3726Z"
            fill="#FFB8B8"
          />
          <path
            d="M129.834 157.318C112.723 168.983 100.633 157.717 91.4061 140.337C92.6882 129.701 90.6123 116.974 86.7676 103.144C85.0577 97.0444 85.6961 90.525 88.5567 84.8731C91.4172 79.2211 96.2921 74.8474 102.219 72.6155L122.434 81.0807C139.599 95.077 142.977 110.238 136.538 126.228L129.834 157.318Z"
            fill="#8D1C1C"
          />
          <path
            d="M80.5935 96.1297L61.7887 106.006L95.1671 125.758L99.8115 137.218C100.122 137.983 100.273 138.803 100.255 139.628C100.238 140.453 100.053 141.266 99.7117 142.018C99.3701 142.769 98.8792 143.443 98.2688 143.998C97.6584 144.554 96.9413 144.979 96.1613 145.248C95.2746 145.554 94.3292 145.65 93.3991 145.53C92.469 145.409 91.5794 145.074 90.8001 144.552C90.0207 144.03 89.3728 143.335 88.9069 142.521C88.4411 141.706 88.17 140.795 88.1149 139.859L87.6453 131.871L45.2217 117.867C43.7923 117.395 42.489 116.604 41.4106 115.553C40.3321 114.503 39.5066 113.221 38.9968 111.804C38.2536 109.739 38.2158 107.486 38.8892 105.397C39.5627 103.308 40.9093 101.502 42.7185 100.26L76.8325 76.848L80.5935 96.1297Z"
            fill="#FFB8B8"
          />
          <path
            d="M99.3981 98.0109C91.5554 94.5727 84.4237 98.3076 75.4221 101.303L74.0117 76.3777C82.95 71.612 91.4721 70.3271 99.3981 74.0263L99.3981 98.0109Z"
            fill="#8D1C1C"
          />
          <path
            d="M108.78 64.2691C117.068 64.2691 123.787 57.5476 123.787 49.2561C123.787 40.9647 117.068 34.2432 108.78 34.2432C100.491 34.2432 93.7722 40.9647 93.7722 49.2561C93.7722 57.5476 100.491 64.2691 108.78 64.2691Z"
            fill="#FFB8B8"
          />
          <path
            d="M126.734 47.362C111.875 48.8178 100.553 46.3868 93.3164 39.4651V33.8907H125.473L126.734 47.362Z"
            fill="#2F2E41"
          />
        </g>
        <defs>
          <clipPath id="clip0_265_85">
            <rect width="500" height="348" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
