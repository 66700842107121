import React from "react";

import Logo from "../assets/Logo";

export default function LoadingScreen() {
  return (
    <div className="flex-1 flex items-center justify-center flex-col">
      <div className="mb-12">
        <Logo />
      </div>
      <p className="text-2xl text-neutral-700">Loading</p>
      <div className="mt-2">
        <div className="w-6 h-6 border-x-2 border-y-2 border-x-neutral-300 border-t-neutral-300 border-b-[#DB287B] rounded-full animate-spin"></div>
      </div>
    </div>
  );
}
