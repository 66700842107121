import React from "react";

import PersonIcon from "@mui/icons-material/Person";

export default function IdentityListButton({ identity, onClick }) {
  return (
    <div
      onClick={onClick}
      className="grid grid-cols-1 p-4 border-b border-b-neutral-500 cursor-pointer hover:brightness-[0.85] hover:bg-[#efefef] overflow-hidden text-ellipsis"
    >
      <div className="flex flex-row items-center">
        <PersonIcon style={{ width: 32, height: 32 }} />
        <p className="ml-4 text-neutral-800 select-none text-ellipsis">
          {identity.name}
        </p>
      </div>
    </div>
  );
}

// return (
//   <div
//     className="encrypted-list-button"
//     onClick={onClick}
//     style={{ marginTop: 8 }}
//   >
//     <div className="encrypted-list-button-overlay"></div>
//     <div
//       style={{
//         flex: 1,
//         display: "flex",
//         flexDirection: "row",
//         alignItems: "center",
//       }}
//     >
//       <div
//         style={{
//           flex: 1,
//           padding: 4,
//           // borderRight: "1px solid #ccc",
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//         }}
//       >
//         <div
//           style={{
//             width: 32,
//             height: 32,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             marginRight: 8,
//           }}
//         >
//           <PersonIcon style={{ width: 32, height: 32, padding: 4 }} />
//         </div>
//         <p style={{ fontSize: 14 }}>{identity.name}</p>
//       </div>
//     </div>
//   </div>
// );
