import React from "react";

import AddBtn from "../AddBtn";
import IdentityButton from "../IdentityButton";
import IdentityListButton from "../IdentityListButton";

import AddIcon from "@mui/icons-material/Add";
import ListAddBtn from "../ListAddBtn";

export default function HomePageIdentities({
  setOverlay,
  identities,
  searchTerm,
  viewType,
}) {
  const search = (term, array, count) => {
    if (count == undefined) {
      count = 0;
    }
    if (array == undefined) {
      array = JSON.parse(JSON.stringify(identities));
    }
    term = term.toLowerCase();
    let realTerm = "";
    for (let i = 0; i < count; i++) {
      realTerm += term[i];
    }
    let output = array.sort((a, b) => {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();

      if (a.includes(realTerm)) {
        return -1;
      } else {
        return 0;
      }
    });
    if (count < term.length) {
      return search(term, output, count + 1);
    } else {
      let realOut = output.sort((a, b) => {
        a = a.name.toLowerCase();
        a = `~${a}`;
        let newTerm = `~${term}`;
        if (a.includes(newTerm)) {
          return -1;
        } else {
          return 0;
        }
      });

      return output;
    }
  };

  return (
    <div className="flex-1 flex flex-col mt-3">
      <div
        className={`${
          viewType == "grid"
            ? `grid xl:grid-cols-4 min-[1260px]:grid-cols-3 min-[870px]:grid-cols-2 gap-y-10`
            : `flex flex-1 flex-col`
        }`}
      >
        {(() => {
          if (viewType == "grid") {
            return (
              <>
                <div className="flex justify-center items-center">
                  <AddBtn onClick={() => setOverlay({ type: "add-id" })} />
                </div>
                {search(searchTerm).map((identity) => {
                  return (
                    <div
                      className="flex justify-center items-center"
                      key={identity.id}
                    >
                      <IdentityButton
                        identity={identity}
                        onClick={() =>
                          setOverlay({ type: "view-identity", data: identity })
                        }
                      />
                    </div>
                  );
                })}
              </>
            );
          } else {
            return (
              <>
                <div className="flex min-[550px]:hidden flex-col">
                  <ListAddBtn
                    name="Add identity"
                    centered
                    onClick={() => setOverlay({ type: "add-id" })}
                  />
                </div>
                <div className="grid grid-cols-3 p-4 pt-0 min-[1260px]:grid-cols-4 border-b border-b-neutral-500">
                  <p className="flex items-center text-neutral-500">Name</p>
                  <span></span>
                  <span className="hidden min-[1260px]:flex"></span>
                  <div className="hidden min-[550px]:flex flex-row items-center justify-end">
                    <ListAddBtn
                      name="Add identity"
                      onClick={() => setOverlay({ type: "add-id" })}
                    />
                  </div>
                </div>
                {search(searchTerm).map((identity) => {
                  return (
                    <IdentityListButton
                      key={identity.id}
                      identity={identity}
                      onClick={() =>
                        setOverlay({ type: "view-identity", data: identity })
                      }
                    />
                  );
                })}
                {identities.length == 0 ? (
                  <div className="flex flex-1 justify-center items-center">
                    <p className="text-gray-600 text-lg">
                      You don't have any identities
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          }
        })()}
      </div>
    </div>
  );
}

// return (
//   <>
//     {(() => {
//       if (viewType == "grid") {
//         return (
//           <>
//             <AddBtn
//               onClick={() => {
//                 setOverlay({ type: "add-id" });
//               }}
//             />
//             {search(searchTerm).map((id) => {
//               return (
//                 <IdentityButton
//                   key={id.id}
//                   identity={id}
//                   onClick={() =>
//                     setOverlay({ type: "view-identity", data: id })
//                   }
//                 />
//               );
//             })}
//           </>
//         );
//       } else {
//         return (
//           <>
//             <div
//               style={{
//                 marginTop: 12,
//                 display: "flex",
//                 flexDirection: "column",
//               }}
//             >
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   backgroundColor: "#fff",
//                   borderRadius: 8,
//                   position: "relative",
//                   border: "1px solid #ddd",
//                 }}
//               >
//                 <div
//                   style={{
//                     flex: 1,
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     padding: 4,
//                   }}
//                 >
//                   <p
//                     style={{
//                       fontSize: 18,
//                       fontWeight: "bold",
//                       color: "#db287b",
//                     }}
//                   >
//                     Name
//                   </p>
//                 </div>
//                 <div
//                   style={{
//                     flex: 1,
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     padding: 4,
//                   }}
//                 >
//                   <div style={{ flex: 1 }}></div>
//                   <div
//                     className="add-list-btn"
//                     onClick={() => setOverlay({ type: "add-id" })}
//                   >
//                     <AddIcon />
//                     <p>Add identity</p>
//                   </div>
//                 </div>
//               </div>
//               {search(searchTerm).map((id) => {
//                 return (
//                   <IdentityListButton
//                     key={id.id}
//                     identity={id}
//                     onClick={() =>
//                       setOverlay({ type: "view-identity", data: id })
//                     }
//                   />
//                 );
//               })}
//             </div>
//           </>
//         );
//       }
//     })()}
//   </>
// );
