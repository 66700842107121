import React from "react";

import StickyNote2Icon from "@mui/icons-material/StickyNote2";

export default function NoteButton({ note, onClick }) {
  const upperFirstLetter = (word) => {
    let name = "";
    for (let i = 0; i < note.name.length; i++) {
      let letter = note.name[i];
      if (i == 0) {
        letter = letter.toUpperCase();
      }
      name += letter;
    }
    return name;
  };

  return (
    <div className="homepage-accbtn" onClick={onClick || (() => {})}>
      <div className="homepage-accbtn-overlay"></div>
      <div className="homepage-accbtn-logocntr">
        <div className="homepage-accbtn-iconcontainer">
          <StickyNote2Icon style={{ transform: "scale(3)" }} />
        </div>
      </div>
      <div className="homepage-accbtn-namectnr">
        <p>{upperFirstLetter(note.name)}</p>
      </div>
    </div>
  );
}
