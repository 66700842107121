const ecies = require("./Ecies");
const { lib, SHA256 } = require("crypto-js");

const KeyUtils = {
  unWrapKey: (password, wrapped, isKey) => {
    try {
      let privKeyString;
      if (!isKey) {
        privKeyString = ecies.keys.privFromPw(password);
      } else {
        privKeyString = password;
      }
      let unwrapped = ecies.decrypt(privKeyString, wrapped);
      return unwrapped;
    } catch {
      throw new Error("Incorrect password");
    }
  },
  wrapKey: (pub, keyToWrap) => {
    return ecies.encrypt(pub, keyToWrap);
  },
  genAesKey: () => {
    let bytes = lib.WordArray.random(256);
    let aesKey = SHA256(bytes.toString()).toString();
    return aesKey;
  },
  passwordToPriv: (password, hex) => {
    let priv = ecies.keys.privFromPw(password);
    if (hex) {
      return priv;
    }
    return ecies.keys.privtoKey(priv);
  },
};

export default KeyUtils;
