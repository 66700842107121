import React, { useRef } from "react";

const isNum = (n) => {
  let nums = "0123456789";
  if (nums.includes(n)) {
    return true;
  } else {
    return false;
  }
};

export default function PinInput({ value, setValue }) {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();

  const setVal = (index, val) => {
    const bigNumisNum = true;
    for (let i=0; i<String(val).length; i++) {
      if (!isNum(String(val[i]))) {
        bigNumisNum = false;
      }
    }
    
    if (index == 0 && String(val).length == 6 && bigNumisNum) {
      let vals = val.split("");
      let newVals = [];
      vals.forEach((v) => {
        newVals.push(Number(v));
      });
      setValue(newVals);
      ref6.current.focus();
    } else {
      let newVal = value;
      let replaceVal = -1;

      if (val != "") {
        if (val.length != 1) {
          let nv = val[val.length - 1];
          if (isNum(nv)) {
            replaceVal = Number(nv);
          }
        } else {
          let nv = val;
          if (isNum(nv)) {
            replaceVal = Number(nv);
          }
        }
      }

      newVal[index] = replaceVal;
      setValue(JSON.parse(JSON.stringify(newVal)));

      if (replaceVal >= 0) {
        if (index == 0) {
          ref2.current.focus();
        } else if (index == 1) {
          ref3.current.focus();
        } else if (index == 2) {
          ref4.current.focus();
        } else if (index == 3) {
          ref5.current.focus();
        } else if (index == 4) {
          ref6.current.focus();
        }
      }
    }
  };

  const keyPress = (key, index) => {
    if (key == "Backspace" && value[index] < 0) {
      if (index == 5) {
        ref5.current.focus();
      } else if (index == 4) {
        ref4.current.focus();
      } else if (index == 3) {
        ref3.current.focus();
      } else if (index == 2) {
        ref2.current.focus();
      } else if (index == 1) {
        ref1.current.focus();
      }
    }
  };

  const getValue = (index) => {
    let out = "";
    if (Number(value[index]) < 0) {
      return out;
    } else {
      return String(value[index]);
    }
  };

  return (
    <div className="grid grid-cols-6 mt-4">
      <div className="flex justify-center items-center">
        <input
          type="number"
          value={getValue(0)}
          onChange={(e) => setVal(0, String(e.target.value))}
          ref={ref1}
          className="bg-white w-[32px] h-[32px] sm:w-[48px] sm:h-[48px] border-2 border-gray-500 rounded-lg outline-none text-center focus:border-gray-900 text-[#DB287B]"
        />
      </div>
      <div className="flex justify-center items-center">
        <input
          onKeyDown={(e) => keyPress(e.key, 1)}
          type="number"
          value={getValue(1)}
          onChange={(e) => setVal(1, String(e.target.value))}
          ref={ref2}
          className="bg-white w-[32px] h-[32px] sm:w-[48px] sm:h-[48px] border-2 border-gray-500 rounded-lg outline-none text-center focus:border-gray-900 text-[#DB287B]"
        />
      </div>
      <div className="flex justify-center items-center">
        <input
          onKeyDown={(e) => keyPress(e.key, 2)}
          type="number"
          value={getValue(2)}
          onChange={(e) => setVal(2, String(e.target.value))}
          ref={ref3}
          className="bg-white w-[32px] h-[32px] sm:w-[48px] sm:h-[48px] border-2 border-gray-500 rounded-lg outline-none text-center focus:border-gray-900 text-[#DB287B]"
        />
      </div>
      <div className="flex justify-center items-center">
        <input
          onKeyDown={(e) => keyPress(e.key, 3)}
          type="number"
          value={getValue(3)}
          onChange={(e) => setVal(3, String(e.target.value))}
          ref={ref4}
          className="bg-white w-[32px] h-[32px] sm:w-[48px] sm:h-[48px] border-2 border-gray-500 rounded-lg outline-none text-center focus:border-gray-900 text-[#DB287B]"
        />
      </div>
      <div className="flex justify-center items-center">
        <input
          onKeyDown={(e) => keyPress(e.key, 4)}
          type="number"
          value={getValue(4)}
          onChange={(e) => setVal(4, String(e.target.value))}
          ref={ref5}
          className="bg-white w-[32px] h-[32px] sm:w-[48px] sm:h-[48px] border-2 border-gray-500 rounded-lg outline-none text-center focus:border-gray-900 text-[#DB287B]"
        />
      </div>
      <div className="flex justify-center items-center">
        <input
          onKeyDown={(e) => keyPress(e.key, 5)}
          type="number"
          value={getValue(5)}
          onChange={(e) => setVal(5, String(e.target.value))}
          ref={ref6}
          className="bg-white w-[32px] h-[32px] sm:w-[48px] sm:h-[48px] border-2 border-gray-500 rounded-lg outline-none text-center focus:border-gray-900 text-[#DB287B]"
        />
      </div>
    </div>
  );
}
