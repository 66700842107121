import React from "react";

export default function SettingsRadio({ value, setValue }) {
  return (
    <input
      type="radio"
      className="w-[16px] h-[16px] accent-[#DB287B]"
      checked={value}
      onChange={() => setValue()}
    />
  );
}
