import React from "react";

import VerifyEmailPage from "../pages/VerifyEmailPage";

export default function authStatus({
  email,
  subbed,
  loggedIn,
  loggedOut,
  unsubscribed,
  any,
  subscribed,
  emailVerified,
}) {
  let status;
  if (email == "none") {
    status = "logged-out";
  } else if (email != "none" && subbed) {
    status = "subscribed";
  } else if (email != "none" && subbed == false) {
    status = "unsubscribed";
  }

  return (
    <>
      {(() => {
        if (any != undefined) {
          return any;
        } else if (emailVerified === false && email != "none") {
          return <VerifyEmailPage email={email} />;
        } else if (loggedIn != undefined && email != "none") {
          return loggedIn;
        } else if (loggedOut != undefined && status == "logged-out") {
          return loggedOut;
        } else if (subscribed != undefined && status == "subscribed") {
          return subscribed;
        } else if (unsubscribed != undefined && status == "unsubscribed") {
          return unsubscribed;
        } else {
          return <></>;
        }
      })()}
    </>
  );
}

// loggedIn
// loggedOut
// any
// subscribed
// unsubscribed
