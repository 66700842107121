import React, { useState } from "react";

import encryption from "../../utils/encryption";

import validDomain from "is-valid-domain";

import AddAccountInput from "../AddAccountInput";
import BtnTwo from "../BtnTwo";
import GenDataHash from "../../utils/GenDataHash";

import axios from "axios";
import OverlayContentWrapper from "../OverlayContentWrapper";

export default function AddAccountOverlay({ close, sync, encKey, setOverlay }) {
  const [name, setName] = useState("");
  const [uid, setUid] = useState("");
  const [password, setPassword] = useState("");
  const [domain, setDomain] = useState("");
  const [submited, setSubmited] = useState(false);
  const [invalid, setInvalid] = useState([]);
  const [showDomainIcon, setShowDomainIcon] = useState(true);

  const parseDomain = (url) => {
    url = url.split("://");
    if (url.length > 1) {
      url = url[1];
    } else {
      url = url[0];
    }
    url = url.split("/");
    url = url[0];

    return url;
  };

  const isValid = () => {
    let inv = [];
    if (domain != "" && !validDomain(parseDomain(domain))) {
      inv.push("domain");
    }

    if (password == "") {
      inv.push("password");
    }

    if (name == "") {
      inv.push("name");
    }

    return inv;
  };

  const setVal = (name, val) => {
    if (name == "name") {
      setName(val);
    } else if (name == "uid") {
      setUid(val);
    } else if (name == "password") {
      setPassword(val);
    } else if (name == "domain") {
      setDomain(val);
    }
  };

  const submit = async () => {
    let inv = isValid();
    setInvalid(inv);

    if (inv.length == 0) {
      if (submited == false) {
        setSubmited(true);

        let key = encKey;
        let userId = undefined;
        if (uid != undefined && uid != "") {
          userId = encryption.encrypt(uid, key);
        }
        let out = {
          name,
          domain: parseDomain(domain),
          uid: userId,
          password: encryption.encrypt(password, key),
          showIcon: showDomainIcon,
          dataHash: GenDataHash(uid, password),
          hashedPw: localStorage.getItem("hashedPw"),
        };

        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        out["_csrf"] = csrfToken;

        let reply = await axios.post("/api/createPassword", out);

        if (reply.data.status == "success") {
          sync();
          close();
        } else {
          if (reply.data.message == "pwHashError") {
            localStorage.removeItem("hashedPw");
            setOverlay({ type: "add-account" });
            return;
          } else {
            sync();
            close();
          }
        }
      }
    }
  };

  return (
    <OverlayContentWrapper
      close={close}
      errMsg={
        invalid.length == 0
          ? ""
          : "Some of the information you entered was invalid"
      }
      loading={submited}
      submit={submit}
    >
      <div className="mb-4">
        <AddAccountInput
          name="Name"
          helpTxt="The name used to identify this account"
          val={name}
          setVal={(v) => setVal("name", v)}
          error={invalid.includes("name")}
          placeholder="Name for the account"
        />
      </div>
      <div className="mb-4">
        <AddAccountInput
          name="User ID"
          helpTxt="This is what whatever name you use to log into an account. E.g: An email or a username"
          val={uid}
          setVal={(v) => setVal("uid", v)}
          placeholder="Username or email"
        />
      </div>
      <div className="mb-4">
        <AddAccountInput
          name="Password"
          helpTxt="The password you use to log into this account"
          val={password}
          setVal={(v) => setVal("password", v)}
          error={invalid.includes("password")}
          placeholder="Password"
        />
      </div>
      <AddAccountInput
        name="Domain"
        helpTxt="The url of the website this account belongs to. E.g: pass-protect.com"
        val={domain}
        setVal={(v) => setVal("domain", v)}
        error={invalid.includes("domain")}
        placeholder="E.g: www.google.com"
      />
    </OverlayContentWrapper>
  );
}

// return (
//   <div className="addAcc-container">
//     <AddAccountInput
//       name="Name"
//       helpTxt="The name used to identify this account"
//       val={name}
//       setVal={(v) => setVal("name", v)}
//       error={invalid.includes("name")}
//     />
//     <AddAccountInput
//       name="User ID"
//       helpTxt="This is what whatever name you use to log into an account. Ex: An email or a username"
//       val={uid}
//       setVal={(v) => setVal("uid", v)}
//     />
//     <AddAccountInput
//       name="Password"
//       helpTxt="The password you use to log into this account"
//       val={password}
//       setVal={(v) => setVal("password", v)}
//       error={invalid.includes("password")}
//     />
//     <AddAccountInput
//       name="Domain"
//       helpTxt="The url of the website this account belongs to. Ex: pass-protect.com"
//       val={domain}
//       setVal={(v) => setVal("domain", v)}
//       error={invalid.includes("domain")}
//     />
//     <div
//       style={{
//         marginTop: 16,
//         display: "flex",
//         flexDirection: "row",
//         alignItems: "center",
//       }}
//     >
//       <input
//         type="checkbox"
//         style={{ width: 16, height: 16, marginRight: 8, outline: "none" }}
//         checked={showDomainIcon}
//         onChange={() => setShowDomainIcon(!showDomainIcon)}
//       />
//       <p>Show domain icon</p>
//     </div>
//     <div className="addAcc-footer-container">
//       <div style={{ flex: 1 }}></div>
//       <div style={{ display: "flex" }}>
//         <div
//           style={{
//             flex: 1,
//             display: "flex",
//             alignItems: "center",
//           }}
//         >
//           {(() => {
//             if (invalid.length != 0) {
//               return (
//                 <>
//                   <p style={{ color: "red" }}>
//                     Some of the information you entered was invalid
//                   </p>
//                 </>
//               );
//             } else {
//               return <></>;
//             }
//           })()}
//         </div>
//         <BtnTwo
//           deactivated={submited}
//           loading={submited}
//           name="Save"
//           style={{ minWidth: 200 }}
//           onClick={() => {
//             submit();
//           }}
//         />
//       </div>
//     </div>
//   </div>
// );
