import React from "react";

export default function SettingsCheckbox({ value, setValue }) {
  return (
    <input
      className="accent-[#DB287B] w-[16px] h-[16px]"
      type="checkbox"
      checked={value}
      onChange={() => setValue(!value)}
    />
  );
}
