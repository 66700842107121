import React, { useEffect, useState } from "react";

import axios from "axios";

import SettingsKeyIncrementer from "../SettingsKeyIncrementer";

import BtnThree from "../BtnThree";

const parsePpu = (ppu) => {
  ppu = String(ppu);

  ppu = ppu.split(".");

  if (ppu.length == 2) {
    let decimal = ppu[1];
    if (decimal.length == 1) {
      decimal += 0;
    }

    ppu[1] = decimal;
  }

  ppu = ppu.join(".");

  return ppu;
};

const parseExpDate = (date) => {
  date = date * 1000;
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let renewDate = new Date(date);

  let renew = [months[renewDate.getMonth()]];
  renew.push(renewDate.getDate());
  renew.push(renewDate.getFullYear());
  for (let i = 0; i < renew.length; i++) {
    renew[i] = String(renew[i]);
  }
  renew = renew.join(" ");

  return renew;
};

export default function SettingsSubKeys({ uid }) {
  const [ppu, setPpu] = useState(null);
  const [keyCount, setKeyCount] = useState(null);
  const [startKeyCount, setStartKeyCount] = useState(null);
  const [expires, setExpires] = useState(0);

  const [incIs10, setIncIs10] = useState(true);

  const [isSub, setIsSub] = useState(false);

  useEffect(() => {
    (async () => {
      if (ppu == null) {
        let res = await axios.get("/api/getCustomPrice");

        if (res.data.status == "success") {
          setExpires(res.data.data.expires);
          setKeyCount(res.data.data.keyCount);
          setStartKeyCount(res.data.data.keyCount);
          setPpu(res.data.data.ppu);
        }
      }
    })();
  }, []);

  const checkout = async () => {
    try {
      if (!isSub) {
        setIsSub(true);
        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        let res = await axios.post("/api/bulkCheckout", {
          _csrf: csrfToken,
          quantity: keyCount - startKeyCount,
        });

        let url = res.data.data.url;
        document.location = url;
        setIsSub(false);
      }
    } catch {
      setIsSub(false);
    }
  };

  const remKeys = async () => {
    try {
      if (!isSub) {
        setIsSub(true);
        let quantity = (keyCount - startKeyCount) * -1;
        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        let res = await axios.post("/api/removeKeys", {
          _csrf: csrfToken,
          quantity,
        });

        let newKeyCount = res.data.data.keyCount;

        setKeyCount(newKeyCount);
        setStartKeyCount(newKeyCount);

        setIsSub(false);
      }
    } catch {
      setIsSub(false);
    }
  };

  if (ppu != null) {
    return (
      <div className="flex flex-1 flex-col">
        <div>
          <p className="text-base">
            <span className="font-bold text-neutral-800">Price per user: </span>
            <span className="text-neutral-500">{parsePpu(ppu)} USD/Month</span>
          </p>
          <p className="text-base mt-1">
            <span className="font-bold text-neutral-800">
              Currently paying:{" "}
            </span>
            <span className="text-neutral-500">
              {parsePpu(Number(startKeyCount) * ppu)} USD/Month
            </span>
          </p>
          {expires > 0 && startKeyCount != 0 ? (
            <p className="text-base mt-1">
              <span className="font-bold text-neutral-800">Next payment: </span>
              <span className="text-neutral-500">{parseExpDate(expires)}</span>
            </p>
          ) : (
            <></>
          )}
          <div className="flex flex-row mt-1 items-center">
            <p className="text-base font-bold text-neutral-800">Keys: </p>
            <SettingsKeyIncrementer
              increment={incIs10 ? 10 : 100}
              value={keyCount}
              setValue={setKeyCount}
            />
          </div>
        </div>
        <div className="flex flex-row items-center mt-1">
          <p className="text-neutral-500 mr-1">Key increment: </p>
          <div className="flex flex-row" onClick={() => setIncIs10(true)}>
            <input
              type="radio"
              className="mx-1 accent-[#DB287B] cursor-pointer"
              checked={incIs10 == true}
              onChange={() => {}}
            />
            <label className="cursor-pointer select-none">10</label>
          </div>
          <div
            className="flex flex-row ml-4 cursor-pointer"
            onClick={() => setIncIs10(false)}
          >
            <input
              type="radio"
              className="mx-1 accent-[#DB287B] cursor-pointer"
              checked={incIs10 == false}
              onChange={() => {}}
            />
            <label className="cursor-pointer select-none">100</label>
          </div>
        </div>
        <div className="flex flex-col mt-1">
          <BtnThree
            onClick={startKeyCount <= keyCount ? checkout : remKeys}
            name={
              startKeyCount <= keyCount
                ? "Buy keys"
                : `Remove ${startKeyCount - keyCount} keys`
            }
            deactivated={startKeyCount == keyCount}
          />
        </div>
        <p className="text-sm text-neutral-500">
          Keep in mind that you will not be refunded for keys you delete.
        </p>
        {startKeyCount > 0 ? <div className="flex-1 flex flex-col justify-end">
          <p className="text-neutral-500">This spreadsheet contains the subscription keys for you to distribute</p>
          <a href="/api/download/subscription-keys.csv" download className="text-[#DB287B] underline hover:brightness-75 cursor-pointer">Download subscription keys</a>
        </div> : <></>}
      </div>
    );
  }
}
