import React, { useState, useEffect } from "react";

import HomePageMenu from "../components/HomePageMenu";
import HomePageSearch from "../components/HomePageSearch";

import HomePageAccounts from "../components/HomePageSections/HomePageAccounts";
import HomePageNotes from "../components/HomePageSections/HomePageNotes";
import HomePageIdentities from "../components/HomePageSections/HomePageIdentities";

import HomePageContentContainer from "../components/HomePageSections/HomePageContentContainer";

import MediaQuery from "react-responsive";

import "../styles/homePage.css";
import SidebarContainer from "../components/SidebarContainer";
import SidebarHomeContent from "../components/SidebarHomeContent";

export default function HomePage({ setOverlay, encrypted, overlay, email }) {
  const [menuSelection, setMenuSelection] = useState("password");
  const [searchTerm, setSearchTerm] = useState("");
  const changeMenu = (newMenu) => {
    setSearchTerm("");
    setMenuSelection(newMenu);
  };

  useEffect(() => {
    try {
      let search = window.location.search;
      let didAffiliate = localStorage.getItem("didAffiliate");
      let didChromePopup = localStorage.getItem("chromePopup");
      if (search.includes("checkoutSuccess") && !didAffiliate) {
        window.rewardful("convert", { email: email });
        localStorage.setItem("didAffiliate", "true");
      }

      if (didChromePopup != "done") {
        setOverlay({ type: "chromePopup" });
        localStorage.setItem("chromePopup", "done");
      }
    } catch {
      // Just so don't throw err
    }
  });

  return (
    <>
      <SidebarContainer
        email={email}
        content={
          <SidebarHomeContent
            selected={menuSelection}
            setSelected={changeMenu}
          />
        }
      >
        <div className="bg-white flex-1 flex flex-col md:mt-6">
          <div className="mx-3 md:mx-6">
            <HomePageSearch
              menuSelection={menuSelection}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </div>
          <HomePageContentContainer
            searchTerm={searchTerm}
            setOverlay={setOverlay}
            encrypted={encrypted}
            menuSelection={menuSelection}
          />
        </div>
      </SidebarContainer>
      {overlay}
    </>
  );
}

// return (
//   <>
//     <div className="homepage-container">
//       <HomePageMenu menuSelection={menuSelection} changeMenu={changeMenu} />
//       <HomePageSearch
//         menuSelection={menuSelection}
//         searchTerm={searchTerm}
//         setSearchTerm={setSearchTerm}
//       />

//       {/* 4 rows */}
//       <MediaQuery minWidth={1351}>
//         {(() => {
//           let count = 0;

//           if (menuSelection == "accounts") {
//             count = encrypted.password.length || 0;
//           } else if (menuSelection == "notes") {
//             count = encrypted.note.length || 0;
//           } else if (menuSelection == "identities") {
//             count = encrypted.identity.length || 0;
//           } else if (menuSelection == "cards") {
//             count = encrypted.card.length || 0;
//           } else {
//             count = 0;
//           }
//           count += 1;
//           let rows = 4;
//           let columns = Math.floor(count / rows);

//           if (count % rows != 0) {
//             columns += 1;
//           }

//           return (
//             <>
//               <HomePageContentContainer
//                 searchTerm={searchTerm}
//                 columns={columns}
//                 setOverlay={setOverlay}
//                 encrypted={encrypted}
//                 menuSelection={menuSelection}
//               />
//             </>
//           );
//         })()}
//       </MediaQuery>
//       {/* 3 rows */}
//       <MediaQuery maxWidth={1350} minWidth={1051}>
//         {(() => {
//           let count = 0;
//           if (menuSelection == "accounts") {
//             count = encrypted.password.length || 0;
//           } else if (menuSelection == "notes") {
//             count = encrypted.note.length || 0;
//           } else if (menuSelection == "identities") {
//             count = encrypted.identity.length || 0;
//           } else if (menuSelection == "cards") {
//             count = encrypted.card.length || 0;
//           } else {
//             count = 0;
//           }
//           count += 1;
//           let rows = 3;
//           let columns = Math.floor(count / rows);

//           if (count % rows != 0) {
//             columns += 1;
//           }

//           return (
//             <>
//               <HomePageContentContainer
//                 searchTerm={searchTerm}
//                 columns={columns}
//                 setOverlay={setOverlay}
//                 encrypted={encrypted}
//                 menuSelection={menuSelection}
//               />
//             </>
//           );
//         })()}
//       </MediaQuery>
//       {/* 2 rows */}
//       <MediaQuery maxWidth={1050} minWidth={751}>
//         {(() => {
//           let count = 0;
//           if (menuSelection == "accounts") {
//             count = encrypted.password.length || 0;
//           } else if (menuSelection == "notes") {
//             count = encrypted.note.length || 0;
//           } else if (menuSelection == "identities") {
//             count = encrypted.identity.length || 0;
//           } else if (menuSelection == "cards") {
//             count = encrypted.card.length || 0;
//           } else {
//             count = 0;
//           }
//           count += 1;
//           let rows = 2;
//           let columns = Math.floor(count / rows);

//           if (count % rows != 0) {
//             columns += 1;
//           }

//           return (
//             <>
//               <HomePageContentContainer
//                 searchTerm={searchTerm}
//                 columns={columns}
//                 setOverlay={setOverlay}
//                 encrypted={encrypted}
//                 menuSelection={menuSelection}
//               />
//             </>
//           );
//         })()}
//       </MediaQuery>
//       {/* 1 row */}
//       <MediaQuery maxWidth={750}>
//         {(() => {
//           let count = 0;
//           if (menuSelection == "accounts") {
//             count = encrypted.password.length || 0;
//           } else if (menuSelection == "notes") {
//             count = encrypted.note.length || 0;
//           } else if (menuSelection == "identities") {
//             count = encrypted.identity.length || 0;
//           } else if (menuSelection == "cards") {
//             count = encrypted.card.length || 0;
//           } else {
//             count = 0;
//           }
//           count += 1;
//           let rows = 1;
//           let columns = Math.floor(count / rows);

//           if (count % rows != 0) {
//             columns += 1;
//           }

//           return (
//             <>
//               <HomePageContentContainer
//                 searchTerm={searchTerm}
//                 columns={columns}
//                 setOverlay={setOverlay}
//                 encrypted={encrypted}
//                 menuSelection={menuSelection}
//               />
//             </>
//           );
//         })()}
//       </MediaQuery>
//     </div>
//     {overlay}
//   </>
// );
