import React, { useState, useEffect } from "react";

import axios from "axios";

import SettingsSidebar from "../components/SettingsSidebar";
import BtnTwo from "../components/BtnTwo";
import SettingsFooterInput from "../components/SettingsFooterInput";

import SettingsAccount from "../components/SettingsVariants/SettingsAccount";
import SettingsAutoFill from "../components/SettingsVariants/SettingsAutoFill";
import SettingsAutoSave from "../components/SettingsVariants/SettingsAutoSave";
import SettingsSecurity from "../components/SettingsVariants/SettingsSecurity";
import SettingsSubscription from "../components/SettingsVariants/SettingsSubscription";
import SettingsPasswordGenerator from "../components/SettingsVariants/SettingsPasswordGenerator";
import SettingsSubKeys from "../components/SettingsVariants/SettingsSubKeys";
import SettingsDeleteAccount from "../components/SettingsVariants/SettingsDeleteAccount";

import { SHA256 } from "crypto-js";

import "../styles/settings.css";
import MenuIcon from "@mui/icons-material/Menu";
import validateEmail from "email-validator";

import encryption from "../utils/encryption";
import GenKey from "../utils/GenKey";
import KeyUtils from "../utils/KeyUtils";

import SidebarContainer from "../components/SidebarContainer";
import SidebarSettingsContent from "../components/SidebarSettingsContent";
import SettingsContentContainer from "../components/SettingsContentContainer";
import SettingsImportExport from "../components/SettingsVariants/SettingsImportExport";

export default function Settings({
  email,
  encrypted,
  uid,
  syncEnc,
  syncSettings,
  subscriptionType,
  isTeamManager,
}) {
  const [selectedMenu, setSelectedMenu] = useState("account");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [passwordConf, setPasswordConf] = useState("");

  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  //   Account page state
  const [accountNewEmail, setAccountNewEmail] = useState("");
  const [accountNewPassword, setAccountNewPassword] = useState("");
  const [accountConfirmNewPassword, setAccountConfirmNewPassword] =
    useState("");

  // security page state
  const [security2fa, setSecurity2fa] = useState(false);
  const [securityRequirePassword, setSecurityRequirePassword] = useState(false);

  // auto fill page state
  const [autoFill, setAutoFill] = useState("ask");
  // state possibilities are "never", "always", and "ask"

  // auto save page state
  const [autoSave, setAutoSave] = useState("ask");
  // state possibilities are "never", "always" and "ask"

  // password generator page state
  const [pwGenNums, setPwGenNums] = useState(true); // 0-9
  const [pwGenaz, setPwGenaz] = useState(true); // a-z
  const [pwGenAZ, setPwGenAZ] = useState(true); // A-Z
  const [pwGenOther, setPwGenOther] = useState(true); // include other characters
  const [pwGenOtherChars, setPwGenOtherChars] = useState("@!$_."); // other charactes to include
  const [pwGenMinLength, setPwGenMinLength] = useState(15);
  const [pwGenMaxLength, setPwGenMaxLength] = useState(15);

  // Subscription page state
  const [subPageSubbed, setSubPageSubbed] = useState(false);
  const [subPageRenews, setSubPageRenews] = useState(false);
  const [subPageEnds, setSubPageEnds] = useState("");

  // settings page state
  const [isSynced, setIsSynced] = useState(false);
  const [isErr, setErr] = useState(false);

  const [ogData, setOgData] = useState({});
  const [isSub, setIsSub] = useState(false);

  const [psIsVisible, setPwIsVisible] = useState(false);

  const updateSettings = async () => {
    let data = await syncSettings();

    setSecurity2fa(data.twoFA);
    setSecurityRequirePassword(data.requirePassword);
    setAutoFill(data.autoFill);
    setAutoSave(data.autoSave);

    setPwGenNums(data.pwGen09);
    setPwGenaz(data.pwGenaz);
    setPwGenAZ(data.pwGenAZ);
    setPwGenOther(data.pwGenOther);
    setPwGenOtherChars(data.pwGenOtherChars);
    setPwGenMinLength(data.pwGenMinLen);
    setPwGenMaxLength(data.pwGenMaxLen);

    // handle sub page data
    let dataSubEnd = data.subEnd * 1000;
    let dataSubbed = dataSubEnd > Date.now();

    let renewDate = new Date(dataSubEnd);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let renew = [months[renewDate.getMonth()]];
    renew.push(renewDate.getDate());
    renew.push(renewDate.getFullYear());
    for (let i = 0; i < renew.length; i++) {
      renew[i] = String(renew[i]);
    }
    renew = renew.join(" ");

    //
    setSubPageSubbed(dataSubbed);
    setSubPageRenews(!data.cancelAtEnd);
    setSubPageEnds(renew);

    setOgData(data);

    if (!isSynced) {
      setIsSynced(true);
    }
  };

  useEffect(() => {
    if (!isSynced) {
      updateSettings();
    }
  }, []);

  const submit = {
    account: async () => {
      setIsSub(true);
      try {
        let newPw = "";

        if (accountNewPassword != "") {
          newPw = SHA256(accountNewPassword).toString();
        }

        let data = {
          newEmail: accountNewEmail,
          newPassword: newPw,
          password: SHA256(passwordConf).toString(),
        };

        let wrappedList = [];

        if (accountNewPassword != "") {
          let listOfKeys = JSON.parse(localStorage.getItem("keys"));

          let pubKey = KeyUtils.passwordToPriv(accountNewPassword).getPublic();
          let encodedPub = pubKey.encode("hex");

          listOfKeys.forEach((k) => {
            let wrappedKey = k.wrappedKey;
            let unwrappedKey = KeyUtils.unWrapKey(passwordConf, wrappedKey);

            let newWrapped = KeyUtils.wrapKey(encodedPub, unwrappedKey);

            let o = {
              id: k.id,
              keyType: k.type,
              wrappedKey: newWrapped,
            };
            wrappedList.push(o);
          });

          data["newWrapped"] = wrappedList;
          data["newPub"] = encodedPub;
        }

        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        data["_csrf"] = csrfToken;

        let res = await axios.post("/api/updateAccount", data);
        if (res.data.status == "success") {
          if (typeof res.data.data == "string") {
            if (res.data.data == "") {
              res.data.data = "Sucess";
            }
            setSuccMsg(res.data.data);
            setErrMsg("");
          } else {
            setSuccMsg("Success");
            setErrMsg("");
          }

          if (accountNewPassword != "") {
            localStorage.setItem(
              "hashedPw",
              SHA256(
                SHA256(SHA256(accountNewPassword).toString()).toString()
              ).toString()
            );
          }

          if (accountNewPassword != "") {
            // set keys
            // !ogData.requirePassword
            let newListArr = [];
            wrappedList.forEach((l) => {
              let o = {
                id: l.id,
                type: l.keyType,
                wrappedKey: l.wrappedKey,
              };

              if (!ogData.requirePassword) {
                let unWrapped = KeyUtils.unWrapKey(
                  accountNewPassword,
                  l.wrappedKey
                );

                o["unWrapped"] = unWrapped;
              }

              newListArr.push(o);
            });

            let prKey = KeyUtils.passwordToPriv(accountNewPassword, true);

            localStorage.setItem("privKey", prKey);
            localStorage.setItem("keys", JSON.stringify(newListArr));
          }
        } else {
          let emsg = res.data.message;
          if (emsg == undefined || emsg == "") {
            emsg = "An error has occured";
          }

          setErrMsg(emsg);
          setSuccMsg("");
        }
        updateSettings();
        syncEnc();
        setAccountNewEmail("");
        setAccountNewPassword("");
        setAccountConfirmNewPassword("");
        setPasswordConf("");
        setIsSub(false);
      } catch (e) {
        //? console.log(e);
        setIsSub(false);
        setErrMsg("An error has occured. Your password might be incorrect");
        setSuccMsg("");
      }
    },
    security: async () => {
      setIsSub(true);
      try {
        let data = {
          tfa: security2fa,
          requirePassword: securityRequirePassword,
          password: SHA256(passwordConf).toString(),
        };

        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        data["_csrf"] = csrfToken;

        let res = await axios.post("/api/updateSettings", data);
        let resData = res.data;
        if (resData.status == "error") {
          let emsg = resData.message;
          if (emsg == undefined || emsg == "") {
            emsg = "An error has occured";
          }

          setErrMsg(emsg);
          setSuccMsg("");
        } else {
          if (ogData.requirePassword && !securityRequirePassword) {
            // localStorage.setItem("key", GenKey(passwordConf, uid));
            let parsedKeys = JSON.parse(localStorage.getItem("keys"));
            let outKeys = [];
            parsedKeys.forEach((k) => {
              let wrapped = k.wrappedKey;
              let unwrapped = KeyUtils.unWrapKey(passwordConf, wrapped);

              k.unWrapped = unwrapped;
              outKeys.push(k);
            });
            let privKey = KeyUtils.passwordToPriv(passwordConf, true);

            localStorage.setItem("privKey", privKey);
            localStorage.setItem("keys", JSON.stringify(outKeys));
          } else if (!ogData.requirePassword && securityRequirePassword) {
            let parsedKeys = JSON.parse(localStorage.getItem("keys"));
            let outKeys = [];
            parsedKeys.forEach((k) => {
              k["unWrapped"] = undefined;
              outKeys.push(k);
            });

            localStorage.removeItem("privKey");
            localStorage.setItem("keys", JSON.stringify(outKeys));
          }

          await updateSettings();
          setPasswordConf("");

          setSuccMsg("Success");
          setErrMsg("");
        }
        setIsSub(false);
      } catch {
        setIsSub(false);
      }
    },
    autoFill: async () => {
      setIsSub(true);

      let data = {
        autoFill: autoFill,
        password: SHA256(passwordConf).toString(),
      };

      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      data["_csrf"] = csrfToken;

      let res = await axios.post("/api/updateSettings", data);
      let resData = res.data;
      if (resData.status == "error") {
      } else {
        await updateSettings();
        setPasswordConf("");
      }
      setIsSub(false);
    },
    autoSave: async () => {
      setIsSub(true);

      let data = {
        autoSave: autoSave,
        password: SHA256(passwordConf).toString(),
      };

      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      data["_csrf"] = csrfToken;

      let res = await axios.post("/api/updateSettings", data);
      let resData = res.data;
      if (resData.status == "error") {
      } else {
        await updateSettings();
        setPasswordConf("");
      }
      setIsSub(false);
    },
    passwordGen: async () => {
      setIsSub(true);
      try {
        let data = {
          nums: pwGenNums,
          az: pwGenaz,
          AZ: pwGenAZ,
          other: pwGenOther,
          otherChars: pwGenOtherChars,
          minLength: pwGenMinLength,
          maxLength: pwGenMaxLength,
          password: SHA256(passwordConf).toString(),
        };

        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        data["_csrf"] = csrfToken;

        let res = await axios.post("/api/updateSettings", data);
        let resData = res.data;
        if (resData.status == "error") {
          let emsg = resData.message;
          if (emsg == undefined || emsg == "") {
            emsg = "An error has occured";
          }

          setErrMsg(emsg);
          setSuccMsg("");
        } else {
          await updateSettings();
          setPasswordConf("");
          setSuccMsg("Success");
          setErrMsg("");
        }
        setIsSub(false);
      } catch {
        setIsSub(false);

        setErrMsg("An error has occured");
        setSuccMsg("");
      }
    },
    sub: () => {
      if (selectedMenu == "account") {
        submit.account();
      } else if (selectedMenu == "security") {
        submit.security();
      } else if (selectedMenu == "auto fill") {
        submit.autoFill();
      } else if (selectedMenu == "auto save") {
        submit.autoSave();
      } else if (selectedMenu == "password generator") {
        submit.passwordGen();
      }
    },
  };

  const hasChanged = {
    security: () => {
      let og = {
        twoFA: ogData.twoFA,
        requirePassword: ogData.requirePassword,
      };

      let current = {
        twoFA: security2fa,
        requirePassword: securityRequirePassword,
      };

      for (let key1 in og) {
        for (let key2 in current) {
          if (key1 == key2 && og[key1] != current[key2]) {
            return true;
          }
        }
      }

      return false;
    },
    autoFill: () => {
      let og = {
        autoFill: ogData.autoFill,
      };

      let current = {
        autoFill: autoFill,
      };

      for (let key1 in og) {
        for (let key2 in current) {
          if (key1 == key2 && og[key1] != current[key2]) {
            return true;
          }
        }
      }

      return false;
    },
    autoSave: () => {
      let og = {
        autoSave: ogData.autoSave,
      };

      let current = {
        autoSave: autoSave,
      };

      for (let key1 in og) {
        for (let key2 in current) {
          if (key1 == key2 && og[key1] != current[key2]) {
            return true;
          }
        }
      }

      return false;
    },
    passwordGen: () => {
      let og = {
        pwGen09: ogData.pwGen09,
        pwGenaz: ogData.pwGenaz,
        pwGenAZ: ogData.pwGenAZ,
        pwGenOther: ogData.pwGenOther,
        pwGenOtherChars: ogData.pwGenOtherChars,
        pwGenMinLen: ogData.pwGenMinLen,
        pwGenMaxLen: ogData.pwGenMaxLen,
      };

      let current = {
        pwGen09: pwGenNums,
        pwGenaz: pwGenaz,
        pwGenAZ: pwGenAZ,
        pwGenOther: pwGenOther,
        pwGenOtherChars: pwGenOtherChars,
        pwGenMinLen: pwGenMinLength,
        pwGenMaxLen: pwGenMaxLength,
      };

      for (let key1 in og) {
        for (let key2 in current) {
          if (key1 == key2 && og[key1] != current[key2]) {
            return true;
          }
        }
      }

      return false;
    },
  };

  const upperFirst = (word) => {
    let out = "";
    for (let i = 0; i < word.length; i++) {
      let letter = word[i];
      if (i == 0) {
        letter = letter.toUpperCase();
      }
      out += letter;
    }
    return out;
  };

  const changeMenu = (menu) => {
    setSelectedMenu(menu);
    setPasswordConf("");
    setIsSub(false);
    setErrMsg("");
    setSuccMsg("");
    setPwIsVisible(false);
  };

  return (
    <SidebarContainer
      settings
      email={email}
      content={
        <SidebarSettingsContent
          isTeamManager={isTeamManager}
          selected={selectedMenu}
          setSelected={changeMenu}
        />
      }
    >
      <SettingsContentContainer
        page={selectedMenu}
        loading={isSub}
        password={passwordConf}
        setPassword={setPasswordConf}
        visible={psIsVisible}
        setVisible={setPwIsVisible}
        submit={submit.sub}
        errMsg={errMsg}
        succMsg={succMsg}
        deactivated={(() => {
          if (passwordConf.length < 7) {
            return true;
          }

          if (selectedMenu != "account") {
            if (selectedMenu != "password generator") {
              // security, autofill, autosave
              if (selectedMenu == "security") {
                return !hasChanged.security();
              } else if (selectedMenu == "auto fill") {
                return !hasChanged.autoFill();
              } else if (selectedMenu == "auto save") {
                return !hasChanged.autoSave();
              }
            } else {
              let valid = true;
              if (Number(pwGenMinLength) > Number(pwGenMaxLength)) {
                valid = false;
              } else if (Number(pwGenMaxLength) < Number(pwGenMinLength)) {
                valid = false;
              }

              if (!hasChanged.passwordGen()) {
                valid = false;
              }
              return !valid;
            }
          } else {
            let valid = true;
            if (
              accountNewEmail != "" &&
              validateEmail.validate(accountNewEmail) == false
            ) {
              valid = false;
            }

            if (accountNewEmail != "" && accountNewEmail == email) {
              valid = false;
            }

            if (accountNewPassword != "" && accountNewPassword.length < 7) {
              valid = false;
            }

            if (
              accountNewPassword != "" &&
              accountNewPassword != accountConfirmNewPassword
            ) {
              valid = false;
            }

            if (accountNewEmail == "" && accountNewPassword == "") {
              valid = false;
            }

            return !valid;
          }
        })()}
      >
        {(() => {
          if (isSynced) {
            if (selectedMenu == "account") {
              return (
                <SettingsAccount
                  currentEmail={email}
                  newEmail={accountNewEmail}
                  setNewEmail={setAccountNewEmail}
                  newPassword={accountNewPassword}
                  setNewPassword={setAccountNewPassword}
                  confNewPassword={accountConfirmNewPassword}
                  setConfNewPassword={setAccountConfirmNewPassword}
                  succMsg={succMsg}
                  errMsg={errMsg}
                />
              );
            } else if (selectedMenu == "security") {
              return (
                <SettingsSecurity
                  tfa={security2fa}
                  set2fa={setSecurity2fa}
                  requirePassword={securityRequirePassword}
                  setRequirePassword={setSecurityRequirePassword}
                  succMsg={succMsg}
                  errMsg={errMsg}
                />
              );
            } else if (selectedMenu == "subscription") {
              return (
                <SettingsSubscription
                  subscriptionType={subscriptionType}
                  subbed={subPageSubbed}
                  renews={subPageRenews}
                  ends={subPageEnds}
                  sync={updateSettings}
                />
              );
            } else if (selectedMenu == "password generator") {
              return (
                <SettingsPasswordGenerator
                  nums={pwGenNums}
                  setNums={setPwGenNums}
                  az={pwGenaz}
                  setaz={setPwGenaz}
                  AZ={pwGenAZ}
                  setAZ={setPwGenAZ}
                  other={pwGenOther}
                  setOther={setPwGenOther}
                  otherChars={pwGenOtherChars}
                  setOtherChars={setPwGenOtherChars}
                  minLength={pwGenMinLength}
                  setMinLength={setPwGenMinLength}
                  maxLength={pwGenMaxLength}
                  setMaxLength={setPwGenMaxLength}
                  succMsg={succMsg}
                  errMsg={errMsg}
                />
              );
            } else if (selectedMenu == "deleteAccount") {
              return <SettingsDeleteAccount uid={uid} />;
            } else if (selectedMenu == "i/o") {
              return <SettingsImportExport uid={uid} sync={syncEnc} />;
            } else if (selectedMenu == "subKeys" && isTeamManager) {
              return <SettingsSubKeys uid={uid} />;
            }
          }
        })()}
      </SettingsContentContainer>
    </SidebarContainer>
  );
}
