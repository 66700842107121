import React from "react";

import AddBtn from "../AddBtn";
import CardButton from "../CardButton";
import CardListButton from "../CardListButton";

import ListAddBtn from "../ListAddBtn";

import AddIcon from "@mui/icons-material/Add";

export default function HomePageCards({
  setOverlay,
  cards,
  searchTerm,
  viewType,
}) {
  const search = (term, array, count) => {
    if (count == undefined) {
      count = 0;
    }
    if (array == undefined) {
      array = JSON.parse(JSON.stringify(cards));
    }
    term = term.toLowerCase();
    let realTerm = "";
    for (let i = 0; i < count; i++) {
      realTerm += term[i];
    }
    let output = array.sort((a, b) => {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();

      if (a.includes(realTerm)) {
        return -1;
      } else {
        return 0;
      }
    });
    if (count < term.length) {
      return search(term, output, count + 1);
    } else {
      let realOut = output.sort((a, b) => {
        a = a.name.toLowerCase();
        a = `~${a}`;
        let newTerm = `~${term}`;
        if (a.includes(newTerm)) {
          return -1;
        } else {
          return 0;
        }
      });

      return output;
    }
  };

  return (
    <div className="flex-1 flex flex-col mt-3">
      <div
        className={`${
          viewType == "grid"
            ? `grid xl:grid-cols-4 min-[1260px]:grid-cols-3 min-[870px]:grid-cols-2 gap-y-10`
            : `flex flex-1 flex-col`
        }`}
      >
        {(() => {
          if (viewType == "grid") {
            return (
              <>
                <div className="flex justify-center items-center">
                  <AddBtn onClick={() => setOverlay({ type: "add-card" })} />
                </div>
                {search(searchTerm).map((card) => {
                  return (
                    <div
                      className="flex justify-center items-center"
                      key={card.id}
                    >
                      <CardButton
                        card={card}
                        onClick={() =>
                          setOverlay({ type: "view-card", data: card })
                        }
                      />
                    </div>
                  );
                })}
              </>
            );
          } else {
            return (
              <>
                <div className="flex min-[550px]:hidden flex-col">
                  <ListAddBtn
                    name="Add card"
                    centered
                    onClick={() => setOverlay({ type: "add-card" })}
                  />
                </div>
                <div className="grid grid-cols-3 p-4 pt-0 min-[1260px]:grid-cols-4 border-b border-b-neutral-500">
                  <p className="flex items-center text-neutral-500">
                    Card number
                  </p>
                  <span></span>
                  <span className="hidden min-[1260px]:flex"></span>
                  <div className="hidden min-[550px]:flex flex-row items-center justify-end">
                    <ListAddBtn
                      name="Add card"
                      onClick={() => setOverlay({ type: "add-card" })}
                    />
                  </div>
                </div>
                {search(searchTerm).map((card) => {
                  return (
                    <CardListButton
                      key={card.id}
                      card={card}
                      onClick={() =>
                        setOverlay({ type: "view-card", data: card })
                      }
                    />
                  );
                })}
                {cards.length == 0 ? (
                  <div className="flex flex-1 justify-center items-center">
                    <p className="text-gray-600 text-lg">
                      You don't have any cards
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          }
        })()}
      </div>
    </div>
  );
}
