const aes = require("crypto-js/aes");
let utf8 = require("crypto-js/enc-utf8");

const encrypt = (data, key) => {
  if (data == "") {
    return "";
  }

  let ciphertext = aes.encrypt(data, key).toString();
  return ciphertext;
};

const decrypt = (data, key) => {
  if (data == "") {
    return "";
  }

  let bytes = aes.decrypt(data, key);
  let out = bytes.toString(utf8);
  if (out.length == 0) {
    // throw new Error("Unable to decrypt");
  }
  return out;
};

export default { decrypt, encrypt };
