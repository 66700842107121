import React, { useState } from "react";

import BtnTwo from "../BtnTwo";
import AddNoteTextArea from "../AddNoteTextArea";
import AddNoteNameInput from "../AddNoteNameInput";
import axios from "axios";
import encryption from "../../utils/encryption";
import Logout from "../../utils/Logout";

import OverlayContentWrapper from "../OverlayContentWrapper";
import AddAccountInput from "../AddAccountInput";

export default function AddNoteOverlay({ close, sync, encKey, setOverlay }) {
  const [noteVal, setNoteVal] = useState("");
  const [noteName, setNoteName] = useState("");
  const [errors, setErrors] = useState([]);
  const [errMsg, setErrMsg] = useState(
    "Some of the information you entered was invalid"
  );
  const [isSubbed, setIsSubbed] = useState(false);

  const submit = async () => {
    try {
      setIsSubbed(true);
      let errors = verifySub.all();
      if (errors.length == 0) {
        if (encKey == "" || encKey == undefined) {
          Logout();
          setIsSubbed(false);
          return;
        }
        let encedNote = encryption.encrypt(noteVal, encKey);
        let data = {
          note: encedNote,
          name: noteName,
        };

        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        data["_csrf"] = csrfToken;
        data["hashedPw"] = localStorage.getItem("hashedPw");

        let res = await axios.post("/api/createNote", data);
        let resData = res.data;
        if (resData.status == "error") {
          if (resData.message == "pwHashError") {
            localStorage.removeItem("hashedPw");
            setOverlay({ type: "add-note" });
            setIsSubbed(false);
            return;
          }

          if (
            resData.message == "Each note must be less than 750 characters" ||
            resData.message ==
              "You can only have a total of 100 notes, you can delete some old notes to make more space"
          ) {
            setErrMsg(resData.message);
            setErrors(["a"]);
          }
        } else {
          sync();
          close();
        }
      } else {
        setErrMsg("Some of the information you entered was invalid");
      }
      setIsSubbed(false);
    } catch {
      setIsSubbed(false);
    }
  };

  const verifySub = {
    name: () => {
      if (noteName == "" || noteName == undefined) {
        return false;
      } else {
        return true;
      }
    },
    note: () => {
      if (noteVal == "" || noteVal == undefined) {
        return false;
      } else {
        return true;
      }
    },
    all: () => {
      let errors = [];
      for (let key in verifySub) {
        if (key != "all") {
          let valid = verifySub[key]();
          if (!valid) {
            errors.push(key);
          }
        }
      }
      setErrors(errors);
      return errors;
    },
  };

  return (
    <OverlayContentWrapper close={close} loading={isSubbed} submit={submit}>
      <AddNoteTextArea
        value={noteVal}
        setValue={(v) => {
          if (v.length <= 750) {
            setNoteVal(v);
          }
        }}
        error={errors.includes("note")}
      />
      <div className="mt-2">
        <AddAccountInput
          name="Note name"
          val={noteName}
          setVal={setNoteName}
          error={errors.includes("name")}
        />
      </div>
    </OverlayContentWrapper>
  );
}
