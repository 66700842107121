import React from "react";

import { motion } from "framer-motion";

import { Helmet } from "react-helmet";

// import "../styles/landing.css";

import LandingFeatureBox from "../components/LandingFeatureBox";
import { Link } from "react-router-dom";

import SiteScreenshot from "../assets/siteScreenshot.png";

// Import icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import KeyboardAltOutlinedIcon from "@mui/icons-material/KeyboardAltOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import AccessibilityIcon from "@mui/icons-material/Accessibility";

import LgLandingSvg from "../assets/LgLandingSvg";
import Testimonials from "../components/Testimonials";
import SubscriptionBox from "../components/SubscriptionBox";

import Footer from "../components/Footer";

export default function Landing() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=IM+Fell+Great+Primer+SC&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <section className="min-h-[50vh] mt-4 flex flex-row mx-10 lg:mx-24">
        <div className="flex-1 flex flex-col justify-center lg:mr-6">
          <h1 className="text-5xl text-center lg:text-start font-bold text-transparent bg-clip-text bg-white bg-gradient-to-br from-[#DB287B] to-[#DB9E28] lg:w-[80%]">
            Secure your accounts, secure your peace of mind
          </h1>
          <h3 className="text-[#F187B8] text-xl mt-3 text-center lg:text-start">
            Simple and secure password management for all your accounts!
          </h3>
          <div className="flex flex-row lg:justify-start lg:w-[80%] justify-center">
            <Link to="/signup">
              <button className="bg-[#FFFCBA] px-4 py-3 text-[#85823D] rounded-lg mt-6 flex group ease-in-out">
                <p className="">Get started</p>
                <span className="group-hover:translate-x-2 duration-200">
                  <ArrowForwardIosIcon />
                </span>
              </button>
            </Link>
          </div>
        </div>
        <div className="flex-1 hidden ml-6 justify-center items-center lg:flex">
          <LgLandingSvg />
        </div>
      </section>
      <div className="mx-4 sm:mx-8 md:mx-16 lg:mx-24 mt-32">
        <section>
          <h1 className="text-5xl text-center font-bold text-[#6A113A] mb-16">
            Features
          </h1>
          <div className="hidden self-end bg-pink-100 bg-green-100 bg-blue-100 bg-yellow-100 bg-red-100 bg-orange-100"></div>
          <div className="flex flex-col overflow-hidden">
            {/* <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-8"> */}
            <LandingFeatureBox
              Icon={LockOutlinedIcon}
              title="Generate strong passwords"
              description="Pass Protects's robust built-in password generator ensures that you can effortlessly create strong and unique passwords for all your online accounts. By eliminating the need to come up with passwords yourself, you can significantly enhance the security of your digital presence."
              bg="bg-pink-100"
            />
            <LandingFeatureBox
              Icon={KeyboardAltOutlinedIcon}
              title="Fill forms"
              description="Say goodbye to the tedious process of manually filling out online forms. Pass Protect streamlines your online experience by automatically populating forms with your saved information, such as credentials, names, and payment details. With just a few clicks, you can breeze through registrations, checkouts, and other form-based activities."
              right
              bg="bg-green-100"
            />
            <LandingFeatureBox
              Icon={ShieldOutlinedIcon}
              title="Secure"
              description="Security is our utmost priority. Pass Protect employs state-of-the-art encryption algorithms and industry-standard security practices to protect your sensitive data. Rest assured that your passwords and personal information are safeguarded against unauthorized access, ensuring your peace of mind. With our encryption, even we can't see your passwords!"
              bg="bg-blue-100"
            />
            <LandingFeatureBox
              Icon={StorageOutlinedIcon}
              title="Safely store a variety of data"
              description="Beyond passwords, Pass Protect offers a secure vault to store various types of data. From credit card details and bank account information to secure notes, you can conveniently store and access your sensitive data whenever you need it."
              right
              bg="bg-yellow-100"
            />
            <LandingFeatureBox
              Icon={ImportExportOutlinedIcon}
              title="Import from other password managers"
              description="Switching password managers? No worries. Pass Protect allows you to seamlessly import your data from other password managers, making the transition hassle-free. Effortlessly migrate your passwords without any loss or inconvenience."
              bg="bg-red-100"
            />
            <LandingFeatureBox
              Icon={AccessibilityIcon}
              title="Easy to use"
              description="Designed with simplicity in mind, our Pass Protect offers a user-friendly interface that makes managing your passwords and personal data a breeze. From effortless navigation to intuitive features, Pass Protect ensures a seamless user experience, even for those who are not tech-savvy. Enjoy hassle-free password management with just a few clicks."
              right
              bg="bg-orange-100"
            />
          </div>
        </section>
        {/* <section className="mt-32 hidden md:block">
          <h1 className="text-5xl text-center font-bold text-[#3D7485] mb-16">
            Testimonials
          </h1>
          <div className="mt-5 grid grid-cols-3 gap-10 relative py-16">
            <Testimonials
              name="a"
              stars={5}
              message="a"
            />
            <Testimonials name="a" stars={5} message="asd" />
            <Testimonials name="a" stars={5} message="asd" />
            <div className="absolute w-[100%] h-[100%] flex justify-center flex-row z-0">
              <div className="h[100%] w-[70%] rounded-3xl bg-gradient-to-r from-[#00FF85] to-[#2F94BF]"></div>
            </div>
          </div>
        </section> */}
        <section className="my-24 flex">
          <div className="flex flex-col">
            <div className="flex relative items-center justify-center flex-row">
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                whileInView={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.4, delay: 0.1 }}
                className="flex items-center justify-center"
              >
                <img
                  src={SiteScreenshot}
                  className="hidden md:block w-full lg:w-11/12 xl:w-2/3 px-4 shadow-2xl shadow-neutral-800 rounded-lg p-4"
                />
              </motion.div>
            </div>
          </div>
        </section>
        <section className="mt-32">
          <h1 className="text-5xl text-center font-bold mb-12 text-[#85823D]">
            Compatibility
          </h1>
          <p className="text-center text-2xl md:mx-16 lg:mx-32 text-[#85823D]">
            Easily access Pass Protect from anywhere - on our website, mobile
            app, or through our convenient web extension!
          </p>
          <div className="flex flex-col justify-around mt-8 items-center md:flex-row">
            <a
              className="inline-block"
              href="https://play.google.com/store/apps/details?id=com.passprotect"
            >
              <img
                className="h-32"
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
            <a
              className="inline-block"
              href="https://apps.apple.com/us/app/pass-protect/id6450242806"
            >
              <img
                className="p-5 h-32"
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1507680000"
                alt="Download on the App Store"
              />
            </a>
          </div>
        </section>
        <section className="mt-32" id="pricing">
          <motion.div
            initial={{ opacity: 0, translateY: 50 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.4, delay: 0.1 }}
          >
            <h1 className="text-5xl text-center font-bold mb-12 text-[#6A113A]">
              Pricing
            </h1>
            <SubscriptionBox register={true} />
            <p className="text-center mt-4 text-neutral-800">
              If you're a business or organization, you can{" "}
              <a
                href="/contact"
                className="text-[#DB287B] cursor-pointer hover:brightness-75"
              >
                contact us
              </a>{" "}
              to discuss bulk discounts
            </p>
          </motion.div>
        </section>{" "}
      </div>
      <Footer />
    </>
  );
}
