import React, { useState, useEffect } from "react";

import { SHA256 } from "crypto-js";

import { Link } from "react-router-dom";

import axios from "axios";
import KeyUtils from "../utils/KeyUtils";

import LockIcon from "@mui/icons-material/Lock";

import AuthInput from "../components/AuthInput";
import PasswordStrengthBar from "react-password-strength-bar";
import BtnThree from "../components/BtnThree";

export default function ResetPassword() {
  const [isSubbed, setIsSubbed] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptedTerm, setAcceptedTerms] = useState(false);
  const [id, setId] = useState("");
  const [err, setErr] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [notAccepted, setNotAccepted] = useState(false);

  const [subbedOnce, setSubbedOnce] = useState(false);

  useEffect(() => {
    let search = document.location.search;
    search = search.split("?").join("");
    search = search.split("&");
    let map = {};
    search.forEach((val) => {
      let [key, v] = val.split("=");
      map[key] = v;
    });
    if (map.id != undefined && map.id != "") {
      setId(map.id);
    } else {
      document.location.pathname = "/";
    }
  }, []);

  const submit = async () => {
    if (!subbedOnce) {
      setSubbedOnce(true);
    }
    setNotAccepted(false);

    if (!isSubbed) {
      setIsSubbed(true);

      if (password.length < 7) {
        setErr("Invalid password");
        setIsSubbed(false);
        return;
      }

      if (password != confirmPassword) {
        setErr("Passwords do not match");
        setIsSubbed(false);
        return;
      }

      if (!acceptedTerm) {
        setNotAccepted(true);
        setIsSubbed(false);
        return;
      }

      let pwHash = SHA256(password).toString();
      let data = { newPassword: pwHash, id: id };

      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      data["_csrf"] = csrfToken;

      //

      let newAesKey = KeyUtils.genAesKey();
      let pubKey = KeyUtils.passwordToPriv(password).getPublic().encode("hex");
      let wrappedAesKey = KeyUtils.wrapKey(pubKey, newAesKey);

      data["pubKey"] = pubKey;
      data["wrappedKey"] = wrappedAesKey;

      //

      let res = await axios.post("/api/resetPw", data);

      let resData = res.data;
      if (resData.status == "success") {
        setIsSuccess(true);
        setTimeout(() => {
          document.location.pathname = "/login";
        }, 1500);
      } else {
        setErr(resData.message);
      }
      setIsSubbed(false);
    }
  };

  return (
    <div className="mt-16 flex flex-col items-center">
      <div className="bg-white w-[100%] md:w-[75%] lg:w-[900px] flex flex-col">
        <h1 className="text-center text-3xl sm:text-5xl font-bold">
          Reset password
        </h1>
        <div className="bg-white rounded-3xl p-8 mt-8 md:shadow-2xl">
          {!isSuccess ? (
            <>
              <div className="flex justify-center">
                <p className="text-gray-600 text-lg mt-4 text-center w-[95%] sm:w-[65%]">
                  Choose a new password for your account, Make sure to pick a
                  strong password
                </p>
              </div>
              <div className="flex flex-col">
                <AuthInput
                  error={(password != "" || subbedOnce) && password.length < 7}
                  name="New password"
                  placeholder="Password"
                  icon={LockIcon}
                  value={password}
                  setValue={setPassword}
                  password
                />
              </div>
              <PasswordStrengthBar password={password} className="mt-4" />
              <div className="mt-2 flex flex-col">
                <AuthInput
                  error={
                    password != confirmPassword &&
                    (confirmPassword != "" || subbedOnce)
                  }
                  name="Confirm new password"
                  placeholder="Confirm password"
                  icon={LockIcon}
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                  password
                />
              </div>
              <div className="flex flex-row items-center mt-2">
                <input
                  checked={acceptedTerm}
                  onChange={() => {
                    setAcceptedTerms(!acceptedTerm);
                  }}
                  type="checkbox"
                  className="accent-[#DB287B] w-[16px] h-[16px]"
                />
                <p className="ml-2">
                  {notAccepted ? (
                    <span className="text-red-500">*</span>
                  ) : (
                    <></>
                  )}
                  I acknowledge that by resetting my password, I will be
                  deleting all the data on my account
                </p>
              </div>
              <div className="mt-8 flex flex-col h-12">
                <BtnThree
                  onClick={submit}
                  loading={isSubbed}
                  flex1
                  name="Change password"
                />
              </div>
              <div className="h-[24px]">
                {(() => {
                  if (err != "") {
                    return <p className="text-red-500">{err}</p>;
                  }
                })()}
              </div>
            </>
          ) : (
            <div className="flex-1 flex justify-center items-center">
              <p className="text-green-700 text-lg">
                Successfully changed password, redirecting...
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}