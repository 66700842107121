import React from "react";

import { motion } from "framer-motion";

// export default function LandingFeatureBox({ title, description, Icon }) {
//   return (
//     <div style={{ scale: 1.5 }} className="bg-white p-8 flex flex-col mb-12">
//       <div className="flex justify-center items-center">
//         <Icon className="scale-200 text-[#6A113A]" />
//       </div>
//       <div>
//         <p className="font-bold text-center mt-8 text-lg text-[#6A113A]">
//           {title}
//         </p>
//         <p className="text-[#C7779C] mt-10 text-center mx-10">{description}</p>
//       </div>
//     </div>
//   );
// }

export default function LandingFeatureBox({
  title,
  description,
  Icon,
  right = false,
  bg,
}) {
  return (
    <motion.div
      initial={{ translateX: right ? 200 : -200, opacity: 0 }}
      whileInView={{ translateX: 0, opacity: 1 }}
      // viewport={{ once: true }}
      transition={{ duration: 0.4, delay: 0.1 }}
      className={`${bg} p-8 flex flex-col my-12 rounded-lg md:w-2/3 ${
        right ? "self-end" : ""
      }`}
    >
      <div className="flex flex-col items-center text-neutral-700">
        <Icon style={{ fontSize: 36 }} />
        <p className="font-bold my-2 text-lg">{title}</p>
      </div>
      <p className="mt-2 text-neutral-700 leading-10">{description}</p>
    </motion.div>
  );
}
