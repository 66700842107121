import React, { useState } from "react";

export default function PasswordGenSlider({ val, setVal }) {
  const [compVal, setCompVal] = useState(val * 10);

  const changeVal = (newVal) => {
    setCompVal(newVal);
    //? console.log(Math.floor(newVal / 10));
    setVal(String(Math.floor(newVal / 10)));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <div style={{ display: "flex", flex: 1, position: "relative" }}>
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#ccc",
              borderRadius: 20,
              zIndex: 2,
            }}
          ></div>
          <input
            onChange={(e) => changeVal(e.target.value)}
            value={compVal}
            className="password-gen-slider"
            type="range"
            style={{ flex: 1 }}
            min="1"
            max="300"
          />
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              borderRadius: 20,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: (() => {
                  let pc = (compVal / 300) * 100;
                  if (pc < 1) {
                    pc = 0;
                  }

                  if (pc > 50) {
                    pc -= 1;
                  }

                  if (pc < 50) {
                    pc += 1;
                  }

                  return `${pc}%`;
                })(),
                height: "100%",
                pointerEvents: "none",
                backgroundColor: "#db287b",
                zIndex: 2,
              }}
            ></div>
          </div>
        </div>
        <p style={{ width: 120, textAlign: "center" }}>
          {(() => {
            let out = `${val} character`;
            if (val > 1) {
              out += "s";
            }
            return out;
          })()}
        </p>
      </div>
    </>
  );
}
