import Paparse from "papaparse";

const csvToJson = (csv) => {
  try {
    let parsed = Paparse.parse(csv).data;

    let keyLine = parsed[0];

    let dataLines = [];
    for (let i = 0; i < parsed.length; i++) {
      if (i != 0 && parsed[i].length > 1) {
        dataLines.push(parsed[i]);
      }
    }

    if (dataLines.length == 0) {
      throw new Error("Invalid file");
    }

    let outJson = [];

    for (let i = 0; i < dataLines.length; i++) {
      let data = dataLines[i];
      let o = {};
      for (let j = 0; j < keyLine.length; j++) {
        let key = keyLine[j];
        key = key.toLowerCase();
        o[key] = data[j];
      }

      outJson.push(o);
    }

    if (outJson.length == 0) {
      throw new Error("Invalid file");
    }

    return outJson;
  } catch (e) {
    throw new Error("Invalid file");
  }
};

const jsonToParsed = (json) => {
  let keyMap = {};
  let keyList = [];
  for (let key in json[0]) {
    key = key.toLowerCase();
    keyList.push(key);
  }

  if (keyList.includes("name")) {
    keyMap["name"] = "name";
  }

  if (keyList.includes("title")) {
    keyMap["name"] = "title";
  }

  if (keyList.includes("domain")) {
    keyMap["domain"] = "domain";
  }

  if (keyList.includes("url")) {
    keyMap["domain"] = "url";
  }

  if (keyList.includes("email")) {
    keyMap["uid"] = "email";
  }

  if (keyList.includes("login")) {
    keyMap["uid"] = "login";
  }

  if (keyList.includes("username")) {
    keyMap["uid"] = "username";
  }

  if (keyList.includes("password")) {
    keyMap["password"] = "password";
  }

  if (keyList.includes("pwd")) {
    keyMap["password"] = "pwd";
  }

  if (keyMap["password"] == undefined) {
    throw new Error("Invalid file");
  }
  if (keyMap["name"] == undefined) {
    if (keyMap["uid"] == undefined) {
      throw new Error("Invalid file");
    } else {
      keyMap["name"] = keyMap["uid"];
    }
  }

  // Key map is done

  let output = [];

  keyMap["uid"] = keyMap["uid"] || "";
  keyMap["password"] = keyMap["password"] || "";
  keyMap["name"] = keyMap["name"] || "";
  keyMap["domain"] = keyMap["domain"] || "";

  json.forEach((j) => {
    let o = {};
    let uName = j[keyMap["uid"]] || "";
    let pw = j[keyMap["password"]] || "";
    let name = j[keyMap["name"]] || "";
    let domain = j[keyMap["domain"]] || "";
    o = {
      name: name,
      uid: uName,
      password: pw,
      domain: domain,
    };

    output.push(o);
  });

  return output;
};

const PasswordCsvDecoder = async (file) => {
  let reader = new FileReader();
  reader.readAsText(file, "utf-8");

  return new Promise((resolve, reject) => {
    try {
      reader.onloadend = (f) => {
        let json = csvToJson(reader.result);
        let parsed = jsonToParsed(json);

        resolve(parsed);
      };
    } catch (e) {
      let msg = e.message;
      if (msg == undefined || msg == "") {
        msg = "An error has occured";
      }
      reject(new Error(msg));
    }
  });
};

export default PasswordCsvDecoder;
