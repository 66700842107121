import React, { useState, useEffect } from "react";

import OverlayHeader from "./OverlayVariants/OverlayHeader";
import AddAccountOverlay from "./OverlayVariants/AddAccountOverlay";
import ViewAccountOverlay from "./OverlayVariants/ViewAccountOverlay";
import ConfirmDeleteAccount from "./OverlayVariants/ConfirmDeleteAccount";
import AddIdOverlay from "./OverlayVariants/AddIdOverlay";
import ViewIdOverlay from "./OverlayVariants/ViewIdOverlay";
import ConfirmDeleteIdentity from "./OverlayVariants/ConfirmDeleteIdentity";
import AddCardOverlay from "./OverlayVariants/AddCardOverlay";
import ViewCardOverlay from "./OverlayVariants/ViewCardOverlay";
import ConfirmDeleteCard from "./OverlayVariants/ConfirmDeleteCard";
import AddNoteOverlay from "./OverlayVariants/AddNoteOverlay";
import ViewNoteOverlay from "./OverlayVariants/ViewNoteOverlay";
import ConfirmDeleteNote from "./OverlayVariants/ConfirmDeleteNote";

import PasswordInput from "./OverlayVariants/PasswordInput";

import "../styles/overlay.css";
import Password from "@mui/icons-material/Password";
import { Link } from "react-router-dom";

export default function OverlayContainer({
  data,
  close,
  sync,
  promptDeleteAccount,
  promptDeleteId,
  promptDeleteCard,
  promptDeleteNote,
  requirePassword,
  uid,
  setOverlay,
  setContainerLoaded,
}) {
  const [encKey, setEncKey] = useState("");
  const [wrap, setWrap] = useState("");
  const [effectUsed, setEffectUsed] = useState(false);

  useEffect(() => {
    let key;
    let wrapped;

    if (data.data != undefined) {
      let owner = data.data.owner;
      let ownerId = owner.ownerId;

      let keyList = localStorage.getItem("keys");
      if (keyList != null) {
        try {
          keyList = JSON.parse(keyList);
          let ownerKeyArr = keyList.find((kl) => kl.id == ownerId);
          if (ownerKeyArr != undefined) {
            if (
              ownerKeyArr.unWrapped != undefined &&
              ownerKeyArr.unWrapped != ""
            ) {
              let unwrapped = ownerKeyArr.unWrapped;
              key = unwrapped;
            } else if (
              ownerKeyArr.wrappedKey != undefined &&
              ownerKeyArr.wrappedKey != ""
            ) {
              wrapped = ownerKeyArr.wrappedKey;
            }
          }
        } catch {}
      }
    } else {
      let keyList = localStorage.getItem("keys");
      if (keyList != null) {
        try {
          keyList = JSON.parse(keyList);
          let userKey = keyList.find((k) => k.id == uid);
          if (userKey != undefined) {
            if (userKey.unWrapped != undefined && userKey.unwrapped != "") {
              let unwrapped = userKey.unWrapped;
              key = unwrapped;
            } else if (
              userKey.wrappedKey != undefined &&
              userKey.wrappedKey != ""
            ) {
              wrapped = userKey.wrappedKey;
            }
          }
        } catch {}
      }
    }

    if (requirePassword == false && key != undefined && key != "") {
      setEncKey(key);
    } else if (wrapped != undefined) {
      setWrap(wrapped);
    }

    setContainerLoaded(true);
    setEffectUsed(true);
  }, []);

  return (
    <>
      {(() => {
        if (effectUsed) {
          return (
            <>
              {(() => {
                if (
                  data.type == "confirm-delete-account" &&
                  data.data != undefined
                ) {
                  return (
                    <ConfirmDeleteAccount
                      data={data.data}
                      close={close}
                      sync={sync}
                    />
                  );
                } else if (
                  data.type == "confirm-delete-id" &&
                  data.data != undefined
                ) {
                  return (
                    <ConfirmDeleteIdentity
                      data={data.data}
                      close={close}
                      sync={sync}
                    />
                  );
                } else if (
                  data.type == "confirm-delete-card" &&
                  data.data != undefined
                ) {
                  return (
                    <ConfirmDeleteCard
                      data={data.data}
                      close={close}
                      sync={sync}
                    />
                  );
                } else if (
                  data.type == "confirm-delete-note" &&
                  data.data != undefined
                ) {
                  return (
                    <ConfirmDeleteNote
                      data={data.data}
                      close={close}
                      sync={sync}
                    />
                  );
                } else if (data.type == "chromePopup") {
                  return (
                    <div className="w-[500px] p-4 flex flex-col">
                      <h3 className="text-neutral-800 text-center font-bold">
                        Have you installed our chrome extension?
                      </h3>
                      <p className="text-center text-neutral-500 leading-6 mt-4">
                        Our chrome extension can facilitate your life by helping
                        you automatically fill forms with your login
                        information!
                      </p>
                      <a
                        className="flex flex-col"
                        target="_blank"
                        href="https://chrome.google.com/webstore/detail/pass-protect/caakgcbijmbedbjjomjmnkdlpdemeegb?hl=en-GB&authuser=0"
                      >
                        <button className="bg-[#DB287B] text-white p-2 mt-2 rounded-md font-bold hover:brightness-90 duration-100">
                          Download now!
                        </button>
                      </a>
                    </div>
                  );
                } else {
                  return (
                    <>
                      {(() => {
                        if (data.type == "add-account") {
                          return (
                            <>
                              <PasswordInput
                                uid={uid}
                                requirePassword={requirePassword}
                                encKey={encKey}
                                wrappedKey={wrap}
                                setEncKey={setEncKey}
                                component={
                                  <>
                                    <OverlayHeader
                                      name="Add account"
                                      close={close}
                                    />
                                    <AddAccountOverlay
                                      close={close}
                                      sync={sync}
                                      encKey={encKey}
                                      setOverlay={setOverlay}
                                    />
                                  </>
                                }
                              />
                            </>
                          );
                        } else if (
                          data.type == "view-account" &&
                          data.data != undefined
                        ) {
                          return (
                            <>
                              <PasswordInput
                                uid={uid}
                                requirePassword={requirePassword}
                                encKey={encKey}
                                wrappedKey={wrap}
                                setEncKey={setEncKey}
                                component={
                                  <>
                                    <OverlayHeader
                                      name={data.data.name}
                                      close={close}
                                      deleteFunc={() =>
                                        promptDeleteAccount({
                                          id: data.data.id,
                                          name: data.data.name,
                                        })
                                      }
                                    />
                                    <ViewAccountOverlay
                                      account={data.data}
                                      close={close}
                                      sync={sync}
                                      encKey={encKey}
                                      setOverlay={setOverlay}
                                    />
                                  </>
                                }
                              />
                            </>
                          );
                        } else if (data.type == "add-id") {
                          return (
                            <>
                              <PasswordInput
                                uid={uid}
                                requirePassword={requirePassword}
                                encKey={encKey}
                                wrappedKey={wrap}
                                setEncKey={setEncKey}
                                component={
                                  <>
                                    <OverlayHeader
                                      name="Add identity"
                                      close={close}
                                    />
                                    <AddIdOverlay
                                      sync={sync}
                                      close={close}
                                      encKey={encKey}
                                      setOverlay={setOverlay}
                                    />
                                  </>
                                }
                              />
                            </>
                          );
                        } else if (data.type == "view-identity") {
                          return (
                            <>
                              <PasswordInput
                                uid={uid}
                                requirePassword={requirePassword}
                                encKey={encKey}
                                wrappedKey={wrap}
                                setEncKey={setEncKey}
                                component={
                                  <>
                                    <OverlayHeader
                                      name={data.data.name}
                                      close={close}
                                      deleteFunc={() =>
                                        promptDeleteId(data.data)
                                      }
                                    />
                                    <ViewIdOverlay
                                      identity={data.data}
                                      close={close}
                                      sync={sync}
                                      encKey={encKey}
                                      setOverlay={setOverlay}
                                    />
                                  </>
                                }
                              />
                            </>
                          );
                        } else if (data.type == "add-card") {
                          return (
                            <>
                              <PasswordInput
                                uid={uid}
                                requirePassword={requirePassword}
                                encKey={encKey}
                                wrappedKey={wrap}
                                setEncKey={setEncKey}
                                component={
                                  <>
                                    <OverlayHeader
                                      name="Add card"
                                      close={close}
                                    />
                                    <AddCardOverlay
                                      close={close}
                                      sync={sync}
                                      encKey={encKey}
                                      setOverlay={setOverlay}
                                    />
                                  </>
                                }
                              />
                            </>
                          );
                        } else if (
                          data.type == "view-card" &&
                          data.data != undefined
                        ) {
                          return (
                            <>
                              <PasswordInput
                                uid={uid}
                                requirePassword={requirePassword}
                                encKey={encKey}
                                wrappedKey={wrap}
                                setEncKey={setEncKey}
                                component={
                                  <>
                                    <OverlayHeader
                                      name={`∗∗∗∗ ∗∗∗∗ ∗∗∗∗ ${data.data.name}`}
                                      close={close}
                                      deleteFunc={() =>
                                        promptDeleteCard(data.data)
                                      }
                                    />
                                    <ViewCardOverlay
                                      card={data.data}
                                      close={close}
                                      sync={sync}
                                      encKey={encKey}
                                      setOverlay={setOverlay}
                                    />
                                  </>
                                }
                              />
                            </>
                          );
                        } else if (data.type == "add-note") {
                          return (
                            <>
                              <PasswordInput
                                uid={uid}
                                requirePassword={requirePassword}
                                encKey={encKey}
                                wrappedKey={wrap}
                                setEncKey={setEncKey}
                                component={
                                  <>
                                    <OverlayHeader
                                      name="Add note"
                                      close={close}
                                    />
                                    <AddNoteOverlay
                                      close={close}
                                      sync={sync}
                                      encKey={encKey}
                                      setOverlay={setOverlay}
                                    />
                                  </>
                                }
                              />
                            </>
                          );
                        } else if (
                          data.type == "view-note" &&
                          data.data != undefined
                        ) {
                          return (
                            <>
                              <PasswordInput
                                uid={uid}
                                requirePassword={requirePassword}
                                encKey={encKey}
                                wrappedKey={wrap}
                                setEncKey={setEncKey}
                                component={
                                  <>
                                    <OverlayHeader
                                      name={data.data.name}
                                      close={close}
                                      deleteFunc={() =>
                                        promptDeleteNote(data.data)
                                      }
                                    />
                                    <ViewNoteOverlay
                                      note={data.data}
                                      close={close}
                                      sync={sync}
                                      encKey={encKey}
                                      setOverlay={setOverlay}
                                    />
                                  </>
                                }
                              />
                            </>
                          );
                        } else {
                          return <></>;
                        }
                      })()}
                    </>
                  );
                }
              })()}
            </>
          );
        } else {
          return <></>;
        }
      })()}
    </>
  );
}
