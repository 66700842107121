import React, { useState } from "react";
import BtnThree from "./BtnThree";

import LockIcon from "@mui/icons-material/Lock";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const pageMap = {
  security: "Security",
  subscription: "Subscription",
  account: "Account",
  "password generator": "Password Generator",
  "i/o": "Import/Export",
  subKeys: "Bulk subscriptions",
  deleteAccount: "Delete account",
};

export default function SettingsContentContainer({
  children,
  page,
  loading,
  password,
  setPassword,
  visible,
  setVisible,
  submit,
  deactivated,
  errMsg,
  succMsg,
}) {
  return (
    <div className="flex-1 flex flex-col justify-center items-center">
      <div className="text-4xl text-neutral-800 mb-2 md:mb-4 font-bold">
        {pageMap[page]}
      </div>
      <div className="bg-white flex flex-col flex-1 w-[100vw] md:flex-initial md:w-[576px] lg:w-[640px] md:h-[75vh] md:rounded-xl md:shadow-2xl p-6 relative">
        <div className="flex-1 relative flex">
          <div className="absolute left-0 top-0 w-full h-full overflow-auto custom-scrollbar flex flex-col">
            {children}
          </div>
        </div>
        <div className="flex flex-col">
          {page != "i/o" && page != "subKeys" ? (
            <div className="h-10 flex flex-col justify-end">
              {(() => {
                if (errMsg != "" && errMsg != undefined) {
                  return <p className="text-red-500">{errMsg}</p>;
                } else if (succMsg != "" && succMsg != undefined) {
                  return <p className="text-green-700">{succMsg}</p>;
                }
              })()}
            </div>
          ) : (
            <></>
          )}
          {page != "subscription" &&
          page != "i/o" &&
          page != "subKeys" &&
          page != "deleteAccount" ? (
            <>
              <div className="flex flex-row mt-2">
                <div className="flex-1 mr-3">
                  <p className="text-[#aaa] ml-2">
                    Enter your password to save changes
                  </p>
                  <div className="flex flex-row border items-center border-neutral-300 focus-within:border-neutral-500 rounded-lg duration-100 px-1">
                    <LockIcon />
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      className="flex-1 outline-none p-1 w-full"
                      type={visible ? "text" : "password"}
                    />
                    <div
                      className="cursor-pointer"
                      onClick={() => setVisible(!visible)}
                    >
                      {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-end">
                  <div className="flex flex-row h-[34px] cursor-pointer">
                    <BtnThree
                      name="Save"
                      loading={loading}
                      onClick={submit}
                      deactivated={deactivated}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
