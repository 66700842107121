import React, { useState } from "react";

import PasswordIcon from "@mui/icons-material/Password";

import GetFavUrl from "../utils/GetFavUrl";

export default function AccountButton({ account, onClick }) {
  const [imgErr, setImgErr] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const getLogo = (domain) => {
    let url = GetFavUrl(domain, 256);
    return url;
    // return `https://logo.clearbit.com/${domain}?size=256`;
  };

  const upperFirstLetter = (word) => {
    let name = "";
    for (let i = 0; i < account.name.length; i++) {
      let letter = account.name[i];
      if (i == 0) {
        letter = letter.toUpperCase();
      }
      name += letter;
    }
    return name;
  };

  return (
    <div className="homepage-accbtn" onClick={onClick || (() => {})}>
      <div className="homepage-accbtn-overlay"></div>
      <div className="homepage-accbtn-logocntr">
        {(() => {
          if (
            account.domain != "" &&
            account.domain != undefined &&
            imgErr == false &&
            account.showIcon == true
          ) {
            return (
              <>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img
                    src={getLogo(account.domain)}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      display: (() => {
                        if (loaded) {
                          return "inherit";
                        } else {
                          return "none";
                        }
                      })(),
                    }}
                    onError={() => setImgErr(true)}
                    onLoad={(i) => {
                      if (i.target.width == 16 && i.target.height == 16) {
                        setImgErr(true);
                      }

                      setLoaded(true);
                    }}
                  />
                </div>
              </>
            );
          } else {
            return (
              <>
                <div className="homepage-accbtn-iconcontainer">
                  <PasswordIcon style={{ transform: "scale(3)" }} />
                </div>
              </>
            );
          }
        })()}
      </div>
      <div className="homepage-accbtn-namectnr">
        <p className="overflow-hidden text-ellipsis px-2">
          {upperFirstLetter(account.name)}
        </p>
      </div>
    </div>
  );
}
