import React from "react";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ErrorIcon from "@mui/icons-material/Error";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function AddAccountInput({
  name,
  helpTxt,
  val,
  setVal,
  error,
  placeholder,
  copy = false,
  className = "",
  noErrIcon = false,
  helpLeft = false,
}) {
  const copyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center mb-1">
        {(() => {
          if (name != "" && name != undefined) {
            return <p className="text-neutral-800 mr-3 text-lg">{name}</p>;
          } else {
            return <span className="h-[28px]"></span>;
          }
        })()}
        {(() => {
          if (helpTxt != "" && helpTxt != undefined) {
            return (
              <div className="relative group">
                <HelpOutlineIcon
                  className="cursor-pointer"
                  style={{ width: 16, height: 16 }}
                />
                <div
                  className={`absolute ${
                    helpLeft ? "right-full" : "left-full"
                  } top-0 pl-3 flex-row w-[256px] hidden group-hover:flex`}
                >
                  <div className="bg-white shadow-2xl z-50 p-2 rounded-md">
                    {helpTxt}
                  </div>
                </div>
              </div>
            );
          }
        })()}
        {error && !noErrIcon ? (
          <div className="flex-1 flex flex-row items-center justify-end text-red-500">
            <ErrorIcon style={{ width: 16, height: 16 }} />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        className={`flex border ${
          error
            ? "border-red-500"
            : "border-neutral-300 focus-within:border-neutral-500"
        } rounded-lg overflow-auto duration-100 bg-white ${className}`}
      >
        {copy ? (
          <div
            className="flex items-center justify-center px-2 cursor-pointer"
            onClick={() => copyText(val)}
          >
            <ContentCopyIcon className="text-neutral-800 hover:text-neutral-600 duration-100 active:translate-x-[1px] active:translate-y-[1px]" />
          </div>
        ) : (
          <></>
        )}
        <input
          value={val}
          onChange={(e) => setVal(e.target.value)}
          className={`flex-1 bg-transparent outline-none w-[100%] py-2 ${
            copy ? "" : "px-3"
          } text-base placeholder:text-neutral-500 text-neutral-900`}
          autoCorrect="false"
          autoComplete="false"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

// return (
//   <>
//     <div
//       className="addAcc-input-container"
//       style={(() => {
//         if (error) {
//           return { border: "1px dashed red" };
//         } else {
//           return {};
//         }
//       })()}
//     >
//       <div
//         style={{
//           alignItems: "center",
//           justifyContent: "center",
//           display: "flex",
//         }}
//         className="addAcc-helpOutline-container"
//       >
//         <HelpOutlineIcon className="addAcc-helpOuline" />
//       </div>
//       <div className="addAcc-help-container">
//         <p>{helpTxt}</p>
//       </div>

//       <p>{name}</p>
//       <input
//         className="addAcc-input"
//         value={val}
//         onChange={(e) => setVal(e.target.value)}
//       />
//     </div>
//   </>
// );
