import React from "react";

export default function Logo({ className = "" }) {
  return (
    <>
      <svg
        className={className}
        width="271"
        height="32"
        viewBox="0 0 271 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9887 0.0886211C14.5875 0.0886211 15.1723 0.149677 15.7373 0.265944C19.728 1.08726 22.7316 4.66443 22.7316 8.95289C22.7316 13.5425 19.2913 17.3175 14.8826 17.7714L14.863 17.7734C14.5754 17.8023 14.2838 17.8172 13.9887 17.8172H11.3658V11.6122H13.9887C15.4373 11.6122 16.6116 10.4216 16.6116 8.95289C16.6116 7.48421 15.4373 6.29361 13.9887 6.29361H6.99435V21.3629L5.24576 24.0222H6.99435L5.24576 26.6814H6.99435V27.5679L5.24576 30.2271H1.74859L0 27.5679V0.0886211H13.9887Z"
          fill="#DB287B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M169.613 32C176.856 32 182.727 26.047 182.727 18.7036C182.727 11.3602 176.856 5.40718 169.613 5.40718C162.37 5.40718 156.499 11.3602 156.499 18.7036C156.499 26.047 162.37 32 169.613 32ZM171.257 21.5834C172.857 20.951 173.984 19.4236 173.984 17.6399C173.984 15.29 172.027 13.385 169.613 13.385C167.199 13.385 165.242 15.29 165.242 17.6399C165.242 19.4236 166.369 20.951 167.969 21.5834L165.827 25.795H173.399L171.257 21.5834Z"
          fill="#DB287B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.6561 12.0111C23.7235 13.9317 23.2572 16.1625 23.2572 18.7036C23.2572 21.2447 23.7235 23.4903 24.6561 25.4405C25.6178 27.3611 26.9147 28.8532 28.5467 29.9169C30.1787 30.9511 31.9856 31.4682 33.9673 31.4682C35.6868 31.4682 37.1877 31.1136 38.47 30.4045C39.7814 29.6953 40.8014 28.7646 41.53 27.6122V31.1136H49.0052V6.38228H41.53V9.88366C40.8306 8.73131 39.8251 7.80056 38.5137 7.09142C37.2314 6.38227 35.7305 6.02771 34.0111 6.02771C32.0002 6.02771 30.1787 6.54479 28.5467 7.57895C26.9147 8.61312 25.6178 10.0905 24.6561 12.0111ZM39.9563 14.2715C41.0054 15.3647 41.53 16.8569 41.53 18.7479C41.53 20.639 41.0054 22.1311 39.9563 23.2244C38.9362 24.3177 37.6831 24.8643 36.1968 24.8643C34.7105 24.8643 33.4428 24.3177 32.3936 23.2244C31.3736 22.1016 30.8636 20.5947 30.8636 18.7036C30.8636 16.8126 31.3736 15.3352 32.3936 14.2715C33.4428 13.1782 34.7105 12.6316 36.1968 12.6316C37.6831 12.6316 38.9362 13.1782 39.9563 14.2715Z"
          fill="#DB287B"
        />
        <path
          d="M64.3364 31.4682C62.209 31.4682 60.3147 31.0988 58.6535 30.3601C56.9923 29.6214 55.6809 28.6168 54.7192 27.3463C53.7575 26.0462 53.2183 24.5984 53.1017 23.0028H60.4895C60.577 23.8597 60.9704 24.554 61.6698 25.0859C62.3693 25.6178 63.229 25.8837 64.249 25.8837C65.1816 25.8837 65.8956 25.7064 66.391 25.3518C66.9156 24.9677 67.1779 24.4802 67.1779 23.8892C67.1779 23.1801 66.8136 22.663 66.085 22.338C65.3564 21.9834 64.1761 21.5993 62.5441 21.1856C60.7955 20.7719 59.3384 20.3435 58.1726 19.9003C57.0069 19.4275 56.0015 18.7036 55.1563 17.7285C54.3112 16.7239 53.8886 15.3795 53.8886 13.6953C53.8886 12.277 54.2675 10.9917 55.0252 9.83934C55.812 8.65744 56.9486 7.72669 58.4349 7.0471C59.9504 6.3675 61.7427 6.02771 63.8118 6.02771C66.8719 6.02771 69.2762 6.79594 71.0248 8.33242C72.8025 9.86889 73.8225 11.9077 74.0848 14.4488H67.1779C67.0613 13.5919 66.6824 12.9123 66.0413 12.41C65.4293 11.9077 64.6133 11.6565 63.5933 11.6565C62.719 11.6565 62.0487 11.8338 61.5824 12.1884C61.1161 12.5134 60.883 12.9714 60.883 13.5623C60.883 14.2715 61.2472 14.8033 61.9758 15.1579C62.7336 15.5125 63.8993 15.867 65.473 16.2216C67.2799 16.6944 68.7516 17.1671 69.8882 17.6399C71.0248 18.0831 72.0156 18.8218 72.8608 19.856C73.7351 20.8606 74.1868 22.2198 74.2159 23.9335C74.2159 25.3814 73.8079 26.6815 72.9919 27.8338C72.2051 28.9566 71.0539 29.8431 69.5385 30.4931C68.0522 31.1431 66.3182 31.4682 64.3364 31.4682Z"
          fill="#DB287B"
        />
        <path
          d="M83.0296 30.3601C84.6907 31.0988 86.585 31.4682 88.7125 31.4682C90.6942 31.4682 92.4282 31.1431 93.9145 30.4931C95.43 29.8431 96.5811 28.9566 97.368 27.8338C98.184 26.6815 98.592 25.3814 98.592 23.9335C98.5629 22.2198 98.1111 20.8606 97.2368 19.856C96.3917 18.8218 95.4008 18.0831 94.2642 17.6399C93.1277 17.1671 91.6559 16.6944 89.8491 16.2216C88.2753 15.867 87.1096 15.5125 86.3519 15.1579C85.6233 14.8033 85.259 14.2715 85.259 13.5623C85.259 12.9714 85.4922 12.5134 85.9585 12.1884C86.4247 11.8338 87.095 11.6565 87.9693 11.6565C88.9893 11.6565 89.8054 11.9077 90.4174 12.41C91.0585 12.9123 91.4374 13.5919 91.5539 14.4488H98.4609C98.1986 11.9077 97.1786 9.86889 95.4008 8.33242C93.6522 6.79594 91.2479 6.02771 88.1879 6.02771C86.1187 6.02771 84.3264 6.3675 82.811 7.0471C81.3247 7.72669 80.1881 8.65744 79.4012 9.83934C78.6435 10.9917 78.2647 12.277 78.2647 13.6953C78.2647 15.3795 78.6872 16.7239 79.5324 17.7285C80.3775 18.7036 81.383 19.4275 82.5487 19.9003C83.7144 20.3435 85.1716 20.7719 86.9202 21.1856C88.5522 21.5993 89.7325 21.9834 90.4611 22.338C91.1896 22.663 91.5539 23.1801 91.5539 23.8892C91.5539 24.4802 91.2916 24.9677 90.7671 25.3518C90.2716 25.7064 89.5576 25.8837 88.6251 25.8837C87.605 25.8837 86.7453 25.6178 86.0459 25.0859C85.3464 24.554 84.953 23.8597 84.8656 23.0028H77.4778C77.5944 24.5984 78.1335 26.0462 79.0952 27.3463C80.057 28.6168 81.3684 29.6214 83.0296 30.3601Z"
          fill="#DB287B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.816 10.0166C135.816 11.819 135.408 13.4737 134.592 14.9806C133.776 16.458 132.523 17.6547 130.833 18.5707C129.143 19.4866 127.044 19.9446 124.538 19.9446H119.904V31.1136H112.429V0H124.538C126.986 0 129.055 0.42844 130.746 1.28532C132.436 2.1422 133.704 3.3241 134.549 4.83103C135.394 6.33795 135.816 8.06649 135.816 10.0166ZM123.97 13.9169C125.398 13.9169 126.461 13.5771 127.161 12.8975C127.86 12.2179 128.21 11.2576 128.21 10.0166C128.21 8.77563 127.86 7.81533 127.161 7.13574C126.461 6.45615 125.398 6.11635 123.97 6.11635H119.904V13.9169H123.97Z"
          fill="#DB287B"
        />
        <path
          d="M150.462 7.31303C149.15 8.08126 148.058 9.14497 147.183 10.5042V6.38228H139.708V31.1136H147.183V19.6344C147.183 17.5956 147.65 16.1773 148.582 15.3795C149.515 14.5522 150.914 14.1385 152.779 14.1385H154.833V6.11635C153.23 6.11635 151.773 6.51524 150.462 7.31303Z"
          fill="#DB287B"
        />
        <path
          d="M199.836 31.1136V24.687H197.169C196.353 24.687 195.77 24.5245 195.42 24.1995C195.071 23.8744 194.896 23.3426 194.896 22.6039V12.6759H199.792V6.38228H194.896V0.354572H187.421V6.38228H184.448V12.6759H187.421V22.5153C187.421 25.5587 188.178 27.76 189.694 29.1191C191.209 30.4488 193.322 31.1136 196.032 31.1136H199.836Z"
          fill="#DB287B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M226.993 18.349C226.993 19.0582 226.95 19.7969 226.862 20.5651H209.945C210.061 22.1016 210.542 23.2835 211.387 24.1108C212.261 24.9086 213.325 25.3075 214.578 25.3075C216.444 25.3075 217.74 24.5097 218.469 22.9141H226.425C226.017 24.5393 225.274 26.0019 224.196 27.302C223.146 28.6021 221.82 29.6214 220.218 30.3601C218.615 31.0988 216.822 31.4682 214.841 31.4682C212.451 31.4682 210.323 30.9511 208.458 29.9169C206.593 28.8828 205.136 27.4054 204.087 25.4848C203.038 23.5642 202.513 21.3186 202.513 18.7479C202.513 16.1773 203.023 13.9317 204.043 12.0111C205.092 10.0905 206.549 8.61312 208.415 7.57895C210.28 6.54479 212.422 6.02771 214.841 6.02771C217.201 6.02771 219.3 6.53001 221.136 7.53463C222.972 8.53925 224.4 9.97231 225.42 11.8338C226.469 13.6953 226.993 15.867 226.993 18.349ZM219.343 16.3546C219.343 15.0545 218.906 14.0203 218.032 13.2521C217.158 12.4839 216.065 12.0997 214.753 12.0997C213.5 12.0997 212.436 12.4691 211.562 13.2078C210.717 13.9465 210.192 14.9954 209.988 16.3546H219.343Z"
          fill="#DB287B"
        />
        <path
          d="M230.981 12.0111C229.961 13.9317 229.451 16.1773 229.451 18.7479C229.451 21.3186 229.961 23.5642 230.981 25.4848C232.03 27.4054 233.472 28.8828 235.308 29.9169C237.173 30.9511 239.301 31.4682 241.691 31.4682C244.722 31.4682 247.272 30.6408 249.341 28.9862C251.41 27.3315 252.765 25.0563 253.406 22.1607H245.45C244.78 24.0517 243.483 24.9972 241.56 24.9972C240.19 24.9972 239.097 24.4654 238.281 23.4017C237.465 22.3084 237.057 20.7572 237.057 18.7479C237.057 16.7387 237.465 15.2022 238.281 14.1385C239.097 13.0453 240.19 12.4986 241.56 12.4986C243.483 12.4986 244.78 13.4441 245.45 15.3352H253.406C252.765 12.3804 251.41 10.0905 249.341 8.46538C247.301 6.84026 244.751 6.02771 241.691 6.02771C239.301 6.02771 237.173 6.54479 235.308 7.57895C233.472 8.61312 232.03 10.0905 230.981 12.0111Z"
          fill="#DB287B"
        />
        <path
          d="M271 24.687V31.1136H267.197C264.487 31.1136 262.374 30.4488 260.858 29.1191C259.343 27.76 258.585 25.5587 258.585 22.5153V12.6759H255.612V6.38228H258.585V0.354572H266.06V6.38228H270.956V12.6759H266.06V22.6039C266.06 23.3426 266.235 23.8744 266.585 24.1995C266.935 24.5245 267.517 24.687 268.333 24.687H271Z"
          fill="#DB287B"
        />
      </svg>
    </>
  );
}
