import React from "react";

import axios from "axios";
import BtnTwo from "../BtnTwo";
import BtnThree from "../BtnThree";

export default function ConfirmDeleteAccount({ data, close, sync }) {
  const deleteAccount = async () => {
    let csrfToken = await axios.get("/api/csrf");
    csrfToken = csrfToken.data.token;

    await axios.post("/api/deleteEncrypted", { id: data.id, _csrf: csrfToken });
    close();
    sync();
  };

  return (
    <div className="p-3 flex flex-col">
      <p className="text-lg text-center text-neutral-800">
        Are you sure you want to delete account "{data.name}"? This action
        cannot be undone
      </p>
      <div className="flex flex-col mt-2">
        <div className="mb-2 flex flex-col">
          <BtnTwo name="Cancel" onClick={close} />
        </div>
        <BtnThree name="Delete" onClick={deleteAccount} />
      </div>
    </div>
  );
}

// return (
//   <div className="confirm-delete-account-overlay-container">
//     <p>
//       Are you sure you want to delete account "{data.name}"? This action
//       cannot be undone.
//     </p>
//     <div className="confirm-delete-account-container">
//       <button
//         className="confirm-delete-acc-btn confirm-delete-acc-btn-no"
//         onClick={close}
//       >
//         No
//       </button>
//       <button
//         className="confirm-delete-acc-btn confirm-delete-acc-btn-yes"
//         onClick={deleteAccount}
//       >
//         Yes
//       </button>
//     </div>
//   </div>
// );
