import React, { useState } from "react";
import { Link } from "react-router-dom";

import Logo from "../assets/Logo";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function OtherHeader({ email }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="bg-white flex flex-col px-8 py-4">
      <div className="flex-1 flex flex-row justify-center">
        <div className="w-8 h-8"></div>
        <div className="flex-1 hidden lg:flex">
          <ul class="flex flex-1">
            <li class="px-4 font-bold text-xl text-[#682A47] duration-150 hover:scale-105 flex justify-center items-center">
              <Link to="/">Home</Link>
            </li>
            <li class="px-4 font-bold text-xl text-[#682A47] duration-150 hover:scale-105 flex justify-center items-center">
              <Link to="/contact">Contact</Link>
            </li>
            <li class="px-4 font-bold text-xl text-[#682A47] duration-150 hover:scale-105 flex justify-center items-center">
              <Link to="/faq">FAQ</Link>
            </li>
          </ul>
        </div>
        <div className="px-4 flex flex-1 justify-center">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="flex-1 lg:flex justify-end hidden items-center">
          <p className="w-2/3 overflow-hidden text-ellipsis text-end text-[#682A47] select-none">
            <AccountCircleIcon className="mr-1" />
            {email}
          </p>
        </div>
        <div
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="cursor-pointer block w-8 h-8 lg:hidden"
        >
          {dropdownOpen ? <CloseIcon /> : <MenuIcon />}
        </div>
      </div>
      {dropdownOpen ? (
        <div className="flex lg:hidden flex-col border-b bg-white border-b-[#DB287B] py-4">
          <ul className="flex flex-1 flex-col">
            <li className="py-2 font-bold text-xl text-[#682A47] duration-150 hover:scale-105">
              <Link to="/">Home</Link>
            </li>
            <li className="py-2 font-bold text-xl text-[#682A47] duration-150 hover:scale-105">
              <Link to="/contact">Contact</Link>
            </li>
            <li className="py-2 font-bold text-xl text-[#682A47] duration-150 hover:scale-105">
              <Link to="/faq">FAQ</Link>
            </li>
          </ul>
          {/* <div className="flex flex-row mt-4">
            <div className="mr-4">
              <Link to="/login">
                <BtnTwo
                  hoverEffect={false}
                  onClick={() => console.log("HELLO")}
                  name="Login"
                />
              </Link>
            </div>
            <div>
              <Link to="/signup">
                <BtnOne hoverEffect={false} name="Sign up" />
              </Link>
            </div>
          </div> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
