import React from "react";

import PasswordIcon from "@mui/icons-material/Password";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PersonIcon from "@mui/icons-material/Person";

import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";

export default function SidebarHomeContent({ selected, setSelected }) {
  return (
    <div className="flex-1 flex flex-col overflow-y-auto relative">
      <div className="absolute w-[100%] h-[100%] left-0 top-0 flex flex-col overflow-y-auto custom-scrollbar">
        <p className="text-[#666]">Menu</p>
        <div className="mt-4 mb-6 flex flex-col">
          <MenuItem
            icon1={PasswordIcon}
            name="Passwords"
            selected={selected == "password"}
            onClick={() => setSelected("password")}
          />
        </div>
        <div className="mb-6 flex flex-col">
          <MenuItem
            icon1={CreditCardIcon}
            name="Cards"
            selected={selected == "card"}
            onClick={() => setSelected("card")}
          />
        </div>
        <div className="mb-6 flex flex-col">
          <MenuItem
            icon1={PersonOutlineOutlinedIcon}
            icon2={PersonIcon}
            name="Identities"
            selected={selected == "identity"}
            onClick={() => setSelected("identity")}
          />
        </div>
        <div className="flex flex-col">
          <MenuItem
            icon1={StickyNote2OutlinedIcon}
            icon2={StickyNote2Icon}
            name="Notes"
            selected={selected == "note"}
            onClick={() => setSelected("note")}
          />
        </div>
      </div>
    </div>
  );
}

const MenuItem = ({ icon1, icon2, name, onClick, selected }) => {
  return (
    <button
      className={`flex flex-row p-2 duration-100 outline-none ${
        selected
          ? "text-black font-bold bg bg-[#ddd] rounded-md hover:brightness-90"
          : "text-[#999] hover:text-[#000]"
      }`}
      onClick={onClick}
    >
      {(() => {
        let A;
        if (selected) {
          A = icon2 || icon1;
        } else {
          A = icon1;
        }

        return <A style={{}} />;
      })()}
      <p className="ml-2 select-none">{name}</p>
    </button>
  );
};
