import React from "react";

import SettingsCheckbox from "./SettingsCheckbox";
import SettingsRadio from "./SettingsRadio";

export default function SettingsSecurity({
  tfa,
  set2fa,
  requirePassword,
  setRequirePassword,
  succMsg,
  errMsg,
}) {
  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-row items-center">
        <SettingsCheckbox value={tfa} setValue={set2fa} />
        <p className="ml-2 text-base text-neutral-800">
          <span className="font-bold">Two-factor authentication</span>
          <span className="ml-1 text-neutral-500">(Uses email)</span>
        </p>
      </div>
      <div className="mt-6 flex flex-col">
        <p className="text-neutral-800 font-bold">
          To view and edit passwords, notes, cards, etc:
        </p>
        <div className="mt-2 flex flex-row items-center">
          <SettingsRadio
            value={requirePassword == true}
            setValue={() => setRequirePassword(true)}
          />
          <p className="text-base text-neutral-500 ml-1">
            Ask for master password
          </p>
        </div>
        <div className="mt-2 flex flex-row items-center">
          <SettingsRadio
            value={requirePassword == false}
            setValue={() => setRequirePassword(false)}
          />
          <p className="text-base text-neutral-500 ml-1">
            Don't ask for master password
          </p>
        </div>
      </div>
    </div>
  );
}

// return (
//   <>
//     <div
//       style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
//     >
//       <SettingsCheckbox value={tfa} setValue={set2fa} />
//       <p style={{ fontWeight: "bold" }}>Two-factor authentication</p>
//       <p style={{ color: "#949494", marginLeft: 8 }}>(using email)</p>
//     </div>
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         marginTop: 16,
//         marginLeft: 8,
//       }}
//     >
//       <div>
//         <p style={{ fontWeight: "bold" }}>
//           Require password to view encrypted data
//         </p>
//         <p style={{ color: "#949494" }}>
//           Ex: Accounts, cards, notes, identities
//         </p>
//       </div>
//     </div>
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         marginLeft: 8,
//         marginTop: 12,
//       }}
//     >
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//         }}
//       >
//         <SettingsRadio
//           value={requirePassword == true}
//           setValue={() => setRequirePassword(true)}
//         />
//         <p style={{ marginLeft: 8 }}>Require password</p>
//       </div>
//     </div>
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         marginLeft: 8,
//         marginTop: 12,
//       }}
//     >
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//         }}
//       >
//         <SettingsRadio
//           value={requirePassword == false}
//           setValue={() => setRequirePassword(false)}
//         />
//         <p style={{ marginLeft: 8 }}>Don't require password</p>
//       </div>
//     </div>
//     <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
//       <div style={{ flex: 1 }}></div>
//       {(() => {
//         if (succMsg != "") {
//           return <p style={{ color: "green", marginLeft: 16 }}>{succMsg}</p>;
//         } else if (errMsg != "") {
//           return <p style={{ color: "red", marginLeft: 16 }}>{errMsg}</p>;
//         }
//       })()}
//     </div>
//   </>
// );
