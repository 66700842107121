import React from "react";

import AddBtn from "../AddBtn";
import NoteButton from "../NoteButton";

import AddIcon from "@mui/icons-material/Add";

import NoteListButton from "../NoteListButton";

import ListAddBtn from "../ListAddBtn";

export default function HomePageNotes({
  searchTerm,
  setOverlay,
  notes,
  viewType,
}) {
  const search = (term, array, count) => {
    if (count == undefined) {
      count = 0;
    }
    if (array == undefined) {
      array = JSON.parse(JSON.stringify(notes));
    }
    term = term.toLowerCase();
    let realTerm = "";
    for (let i = 0; i < count; i++) {
      realTerm += term[i];
    }
    let output = array.sort((a, b) => {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();

      if (a.includes(realTerm)) {
        return -1;
      } else {
        return 0;
      }
    });
    if (count < term.length) {
      return search(term, output, count + 1);
    } else {
      let realOut = output.sort((a, b) => {
        a = a.name.toLowerCase();
        a = `~${a}`;
        let newTerm = `~${term}`;
        if (a.includes(newTerm)) {
          return -1;
        } else {
          return 0;
        }
      });

      return output;
    }
  };

  // <div className="homePageGlobalNoteCountContainer">
  //               <p style={{ fontWeight: "bold" }}>{notes.length}/100 notes</p>
  //             </div>
  return (
    <>
      {(() => {
        if (notes.length > 0) {
          return (
            <>
              <div
                style={{ zIndex: 2 }}
                className="absolute right-6 bottom-3 shadow-xl bg-white px-3 py-1 rounded-lg"
              >
                {notes.length}/100 notes
              </div>
            </>
          );
        }
      })()}
      <div className="flex-1 flex flex-col mt-3">
        <div
          className={`${
            viewType == "grid"
              ? `grid xl:grid-cols-4 min-[1260px]:grid-cols-3 min-[870px]:grid-cols-2 gap-y-10`
              : `flex flex-1 flex-col`
          }`}
        >
          {(() => {
            if (viewType == "grid") {
              return (
                <>
                  <div className="flex justify-center items-center">
                    <AddBtn onClick={() => setOverlay({ type: "add-note" })} />
                  </div>
                  {search(searchTerm).map((note) => {
                    return (
                      <div
                        className="flex justify-center items-center"
                        key={note.id}
                      >
                        <NoteButton
                          note={note}
                          onClick={() =>
                            setOverlay({ type: "view-note", data: note })
                          }
                        />
                      </div>
                    );
                  })}
                </>
              );
            } else {
              return (
                <>
                  <div className="flex min-[550px]:hidden flex-col">
                    <ListAddBtn
                      name="Add note"
                      centered
                      onClick={() => setOverlay({ type: "add-note" })}
                    />
                  </div>
                  <div className="grid grid-cols-3 p-4 pt-0 min-[1260px]:grid-cols-4 border-b border-b-neutral-500">
                    <p className="flex items-center text-neutral-500">Name</p>
                    <span></span>
                    <span className="hidden min-[1260px]:flex"></span>
                    <div className="hidden min-[550px]:flex flex-row items-center justify-end">
                      <ListAddBtn
                        name="Add note"
                        onClick={() => setOverlay({ type: "add-note" })}
                      />
                    </div>
                  </div>
                  {search(searchTerm).map((note) => {
                    return (
                      <NoteListButton
                        key={note.id}
                        note={note}
                        onClick={() =>
                          setOverlay({ type: "view-note", data: note })
                        }
                      />
                    );
                  })}
                  {notes.length == 0 ? (
                    <div className="flex flex-1 justify-center items-center">
                      <p className="text-gray-600 text-lg">
                        You don't have any notes
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            }
          })()}
        </div>
      </div>
    </>
  );
}

// return (
//   <>
// {(() => {
//   if (notes.length > 0) {
//     return (
//       <>
//         <div className="homePageGlobalNoteCountContainer">
//           <p style={{ fontWeight: "bold" }}>{notes.length}/100 notes</p>
//         </div>
//       </>
//     );
//   }
// })()}
//     {(() => {
//       if (viewType == "grid") {
//         return (
//           <>
//             <AddBtn onClick={() => setOverlay({ type: "add-note" })} />
//             {search(searchTerm).map((note) => {
//               return (
//                 <NoteButton
//                   key={note.id}
//                   note={note}
//                   onClick={() =>
//                     setOverlay({ type: "view-note", data: note })
//                   }
//                 />
//               );
//             })}
//           </>
//         );
//       } else {
//         return (
//           <>
//             <div
//               style={{
//                 marginTop: 12,
//                 display: "flex",
//                 flexDirection: "column",
//               }}
//             >
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   backgroundColor: "#fff",
//                   borderRadius: 8,
//                   position: "relative",
//                   border: "1px solid #ddd",
//                 }}
//               >
//                 <div
//                   style={{
//                     flex: 1,
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     padding: 4,
//                   }}
//                 >
//                   <p
//                     style={{
//                       fontSize: 18,
//                       fontWeight: "bold",
//                       color: "#db287b",
//                     }}
//                   >
//                     Name
//                   </p>
//                 </div>
//                 <div
//                   style={{
//                     flex: 1,
//                     display: "flex",
//                     flexDirection: "row",
//                     alignItems: "center",
//                     padding: 4,
//                   }}
//                 >
//                   <div style={{ flex: 1 }}></div>
//                   <div
//                     className="add-list-btn"
//                     onClick={() => setOverlay({ type: "add-note" })}
//                   >
//                     <AddIcon />
//                     <p>Add note</p>
//                   </div>
//                 </div>
//               </div>
//               {search(searchTerm).map((note) => {
//                 return (
//                   <NoteListButton
//                     key={note.id}
//                     note={note}
//                     onClick={() =>
//                       setOverlay({ type: "view-note", data: note })
//                     }
//                   />
//                 );
//               })}
//             </div>
//           </>
//         );
//       }
//     })()}
//   </>
// );
