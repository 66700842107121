import axios from "axios";
import React, { useState } from "react";
import PinInput from "../components/PinInput";
import BtnFour from "../components/BtnFour";
import UnverifiedHeader from "../components/UnverifiedHeader";

import MailOutlinedIcon from "@mui/icons-material/MailOutlined";

import "../styles/TfaPrompt.css";

export default function TfaPromptPage({ email, uid }) {
  const [pin, setPin] = useState([-1, -1, -1, -1, -1, -1]);
  const [errMsg, setErrMsg] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [resendClick, setResendClick] = useState(false);
  const [isSubbed, setIsSubbed] = useState(false);

  const resendEmail = async () => {
    if (resendClick == false) {
      setResendClick(true);

      let csrfToken = await axios.get("/api/csrf");

      csrfToken = csrfToken.data.token;

      let res = await axios.post("/api/resend2fa", { _csrf: csrfToken });

      let resData = res.data;

      if (resData.status == "success") {
        let succMsg = resData.data;
        setErrMsg("");
        setSuccessMessage(succMsg);
      } else {
        let eMsg = resData.message;
        setSuccessMessage("");
        setErrMsg(eMsg);
      }
      setResendClick(false);
    }
  };

  const isValidPin = () => {
    let valid = true;
    pin.forEach((p) => {
      if (p < 0) {
        valid = false;
      }
    });

    return valid;
  };

  const submit = async () => {
    try {
      setIsSubbed(true);
      let strPin = pin.join("");

      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      let res = await axios.post("/api/2fapin", {
        pin: strPin,
        _csrf: csrfToken,
      });
      let resData = res.data;
      if (resData.status == "success") {
        document.location.pathname = "/";
      } else {
        setErrMsg(resData.message);
        setSuccessMessage("");
      }
      setIsSubbed(false);
    } catch {
      setIsSubbed(false);
    }
  };

  return (
    <>
      <UnverifiedHeader email={email} />
      <div className="flex-1 flex justify-center items-center">
        <div className="p-6 w-[100%] mx-4 sm:w-[80%] md:w-[724px] flex flex-col shadow-2xl rounded-xl">
          <div className="flex justify-center items-center">
            <div className="bg-[#FFFCBA] p-3 rounded-[50%] text-[#85823D]">
              <MailOutlinedIcon />
            </div>
          </div>
          <p className="mt-6 text-center text-gray-900 font-bold text-xl">
            Please check your email
          </p>
          <p className="text-gray-500 text-center mt-6 text-lg">
            We've sent a code to <span className="font-bold">{email}</span>
          </p>
          <PinInput value={pin} setValue={setPin} />
          <p className="text-gray-500 text-center mt-2">
            Didn't get a code?{" "}
            <span onClick={resendEmail} className="underline cursor-pointer">
              Click to resend
            </span>
            .
          </p>
          <div className="flex flex-col mt-6">
            <BtnFour
              deactivated={!isValidPin()}
              name="Verify"
              onClick={submit}
            />
          </div>
          <div className="h-12 flex flex-col justify-end">
          {(() => {
        if (errMsg != "") {
          return <p className="text-red-500">{errMsg}</p>;
        } else if (successMessage != "") {
          return <p className="text-green-700">{successMessage}</p>;
        } else {
          return <></>;
        }
      })()}
          </div>
        </div>
      </div>
    </>
  );
}

// return (
//   <div
//     style={{
//       flex: 1,
//       display: "flex",
//       flexDirection: "column",
//       justifyContent: "center",
//       alignItems: "center",
//     }}
//   >
//     <p
//       style={{
//         fontSize: 20,
//         marginBottom: 15,
//         textAlign: "center",
//         fontWeight: "bold",
//       }}
//     >
//       Two factor authentication is needed
//     </p>
//     <p style={{ textAlign: "center" }}>
//       Enter the pin we emailed to {email} to finish logging in
//     </p>

//     <div className="tfa-center-container">
//       <div className="tfa-pin-container">
//         <PinInput
//           length={6}
//           initialValue=""
//           onChange={(value, index) => {
//             changePin(value);
//           }}
//           type="numeric"
//           inputMode="number"
//           style={{ padding: "10px" }}
//           inputStyle={{
//             borderRadius: 10,
//             borderWidth: 1,
//             borderColor: "#888",
//           }}
//           inputFocusStyle={{ borderWidth: 2 }}
//           onComplete={(value, index) => {}}
//           autoSelect={true}
//           regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
//         />
//       </div>
//       <BtnTwo
//         loading={isSubbed}
//         name="Submit"
//         style={{ marginLeft: 10, flex: 1 }}
//         deactivated={!isValidPin()}
//         onClick={submit}
//       />
//     </div>
//     <p style={{ marginTop: 15, textAlign: "center" }}>
//       If you can't find the email, try checking your spam folder
//       <br />
//       or{" "}
//       <a
//         style={{
//           textDecoration: "underline",
//           cursor: "pointer",
//           opacity: 0.5,
//         }}
//         onClick={() => resendEmail()}
//       >
//         send another email
//       </a>
//     </p>
//     <br />
//     <div style={{ height: 40 }}>
//       {(() => {
//         if (errMsg != "") {
//           return <p style={{ color: "red" }}>{errMsg}</p>;
//         } else if (successMessage != "") {
//           return <p style={{ color: "green" }}>{successMessage}</p>;
//         } else {
//           return <></>;
//         }
//       })()}
//     </div>
//   </div>
// );
