import React from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function SettingsKeyIncrementer({ value, setValue, increment }) {
  return (
    <>
      <div className="ml-2 flex flex-row items-center border border-neutral-500 rounded-md overflow-hidden">
        <button
          onClick={() => {
            if (value - increment >= 0) {
              setValue(value - increment);
            } else {
              setValue(0);
            }
          }}
          className="px-2 flex items-center justify-center h-full bg-white"
        >
          <RemoveIcon
            className="text-neutral-800"
            style={{ width: 16, height: 16 }}
          />
        </button>
        <div className="bg-white outline-none min-w-[64px] text-center border-x border-x-neutral-500 px-2 py-1 text-sm">
          {value}
        </div>
        <button
          className="px-2 flex items-center justify-center h-full bg-white"
          onClick={() => setValue(value + increment)}
        >
          <AddIcon
            className="text-neutral-800"
            style={{ width: 16, height: 16 }}
          />
        </button>
      </div>
    </>
  );
}
