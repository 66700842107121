import React, { useState } from "react";

import OtherHeader from "../components/OtherHeader";

import ErrorIcon from "@mui/icons-material/Error";

import BtnFour from "../components/BtnFour";

import emailValidator from "email-validator";
import axios from "axios";

export default function Contact({ loggedIn, email }) {
  const [emailVal, setEmail] = useState("");
  const [messageValue, setMessageValue] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [succMsg, setSuccMsg] = useState("");

  const [errList, setErrList] = useState("");

  const gerErrList = () => {
    if (errList.length == 0) {
      return [];
    } else {
      let newEl = [];

      if (errList.includes("email")) {
        if (emailVal == "") {
          newEl.push("email");
        }

        if (!emailValidator.validate(emailVal)) {
          if (!newEl.includes("email")) {
            newEl.push("email");
          }
        }
      }

      if (errList.includes("message") && (messageValue == "" || messageValue.length > 2500)) {
        newEl.push("message");
      }

      return newEl;
    }
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      setErrMsg("");
      setErrList([]);

      let newEl = [];

      let emsg = "";

      if (emailVal == "") {
        emsg = "Email cannot be empty";
        newEl.push("email");
      }

      if (!emailValidator.validate(emailVal)) {
        emsg = "Invalid email";
        if (!newEl.includes("email")) {
          newEl.push("email");
        }
      }

      if (messageValue == "") {
        emsg = "Message cannot be empty";
        newEl.push("message");
      }

      if (messageValue.length > 2500) {
        emsg = "Message cannot be more than 2500 characters long";
        if (!newEl.includes("message")) {
          newEl.push("message");
        }
      }

      if (emsg != "") {
        setErrList(newEl);
        throw new Error(emsg);
      }

      // Ok, post

      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      let response = await axios.post("/api/contact", {
        _csrf: csrfToken,
        email: emailVal,
        message: messageValue,
      });

      let res = response.data;
      if (res.status == "success") {
        setSuccMsg("Success! We'll get back to you as soon as we can");
        setErrMsg("");
        setMessageValue("");
        setEmail("");
      } else {
        setErrMsg(res.message);
      }

      setIsLoading(false);
    } catch (e) {
      let emsg = e.message;
      if (emsg == "" || emsg == undefined) {
        emsg = "An error has occured";
      }

      setIsLoading(false);
      setErrMsg(emsg);
    }
  };

  return (
    <div className="flex-1 flex flex-col">
      {loggedIn ? (
        <>
          <OtherHeader email={email} />
        </>
      ) : (
        <></>
      )}
      <div className="flex flex-1 flex-col">
        <div className="flex-1 flex-col flex items-center justify-center mx-4">
          <div className="w-full md:w-4/5 lg:w-[800px]">
            <h1 className="text-neutral-800 text-3xl text-center font-bold">
              Get in touch
            </h1>
            <h4 className="text-lg text-center text-neutral-500 mt-3">
              Fill this form to contact us, we'll get back to you as soon as we
              can
            </h4>
            <div className="mt-6 flex flex-col">
              <EmailInput
                value={emailVal}
                setValue={setEmail}
                error={gerErrList().includes("email")}
              />
            </div>
            <div className="mt-6 flex flex-col">
              <MessageInput
                value={messageValue}
                setValue={setMessageValue}
                error={gerErrList().includes("message")}
              />
            </div>
            <div className="mt-6 flex flex-col">
              <BtnFour
                name="Send message"
                loading={isLoading}
                onClick={submit}
              />
            </div>
            <div className="mt-3 h-8">
              {(() => {
                if (errMsg != "") {
                  return <p className="text-base text-red-500">{errMsg}</p>;
                } else if (succMsg != "") {
                  return <p className="text-base text-green-700">{succMsg}</p>;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const EmailInput = ({ value, setValue, error = false }) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row mb-1 items-center mx-2">
        <p className="text-neutral-800">Email</p>
        <div className="flex-1"></div>
        {error ? (
          <ErrorIcon
            className="text-red-500"
            style={{ width: 16, height: 16 }}
          />
        ) : (
          <></>
        )}
      </div>
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={`outline-none p-2 border text-neutral-800 rounded-lg ${
          error
            ? "border-red-500"
            : "border-neutral-500 focus:border-neutral-800"
        }`}
        placeholder="The email we will reply to"
      />
    </div>
  );
};

const MessageInput = ({ value, setValue, error = false }) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row mb-1 items-center mx-2">
        <p className="text-neutral-800">Message</p>
        <div className="flex-1"></div>
        {error ? (
          <ErrorIcon
            className="text-red-500"
            style={{ width: 16, height: 16 }}
          />
        ) : (
          <></>
        )}
      </div>
      <div
        className={`border rounded-lg flex flex-col ${
          error
            ? "border-red-500"
            : "border-neutral-500 focus:border-neutral-800"
        }`}
      >
        <textarea
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={`outline-none p-2 border rounded-lg text-neutral-800 h-64 resize-none border-none custom-scrollbar`}
        />
        <div
          className={`p-1 flex flex-row items-center justify-end ${
            value.length <= 2500 ? "text-neutral-800" : "text-red-500"
          }`}
        >
          <div>{value.length}/2500 characters</div>
        </div>
      </div>
    </div>
  );
};
