import React, { useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../assets/Logo";
import { Link } from "react-router-dom";

import Logout from "../utils/Logout";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SettingsIcon from "@mui/icons-material/Settings";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HomeIcon from "@mui/icons-material/Home";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";

import LaptopIcon from "@mui/icons-material/Laptop";

export default function SidebarContainer({
  children,
  content,
  email,
  settings = false,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex-1 flex flex-row">
      <div
        className={`bg-white lg:w-[256px] w-[192px] fixed md:left-0 md:relative h-[100vh] z-30 duration-200 md:duration-0 ${
          !open ? "left-[-192px]" : "left-0"
        } border-r border-r-black px-3 flex flex-col`}
      >
        <Link to="/">
          <Logo className="w-[100%] my-3 lg:my-4" />
        </Link>
        <div className="flex-1 flex flex-col">{content}</div>
        <div className="flex flex-col">
          <div className="flex flex-col">
            <a className="mb-2" href="https://chrome.google.com/webstore/detail/pass-protect/caakgcbijmbedbjjomjmnkdlpdemeegb?hl=en-GB&authuser=0" target="_blank">
              <button className="flex flex-row items-center justify-start text-neutral-500">
                <LaptopIcon className="mr-1" />
                <p>Download extension</p>
              </button>
            </a>
            <button className="flex mb-4 flex-row group">
              {settings ? (
                <>
                  <Link to="/" className="flex flex-1 flex-row py-2">
                    <span className="flex group-hover:hidden">
                      <HomeOutlinedIcon />
                    </span>
                    <span className="hidden group-hover:flex">
                      <HomeIcon />
                    </span>
                    <p className="ml-1 select-none">Home</p>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/settings" className="flex flex-1 flex-row py-2">
                    <span className="flex group-hover:hidden">
                      <SettingsOutlinedIcon />
                    </span>
                    <span className="hidden group-hover:flex">
                      <SettingsIcon />
                    </span>
                    <p className="ml-1 select-none">Settings</p>
                  </Link>
                </>
              )}
            </button>
            <p className="text-[#666]">Account</p>
            <div className="flex flex-row items-center">
              <AccountCircleIcon
                className="text-[#DB287B]"
                style={{ width: 32, height: 32 }}
              />
              <p className="ml-2 text-[#aaa] text-ellipsis overflow-hidden">
                {email}
              </p>
            </div>
          </div>
          <div className="flex flex-row cursor-pointer group">
            <div className="text-neutral-500 flex flex-row items-center my-2 cursor-pointer relative">
              Help{" "}
              <HelpOutlineIcon
                style={{ width: 16, height: 16 }}
                className="ml-2"
              />
              <div className="absolute hidden group-hover:flex left-[100%] pl-1 pb-4 bottom-0">
                <div className="bg-white w-52 p-1 flex flex-col rounded-r-xl rounded-tl-xl border border-neutral-500">
                  <Link to="/contact" className="flex flex-col">
                    <button className="flex flex-row bg-white group/btn">
                      <QuestionAnswerOutlinedIcon className="text-neutral-500 group-hover/btn:text-neutral-800 duration-100" />
                      <p className="ml-2 text-neutral-500 group-hover/btn:text-neutral-800 duration-100">
                        Contact
                      </p>
                    </button>
                  </Link>
                  <Link to="/faq" className="flex flex-col mt-1">
                    <button className="flex flex-row bg-white group/btn">
                      <HelpCenterOutlinedIcon className="text-neutral-500 group-hover/btn:text-neutral-800 duration-100" />
                      <p className="ml-2 text-neutral-500 group-hover/btn:text-neutral-800 duration-100">
                        FAQ
                      </p>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <button
            className="flex flex-row bg-white mb-4 px-4 py-2 items-center justify-center group"
            onClick={Logout}
          >
            <p className="ml-1 text-gray-700 group-hover:text-black duration-100 select-none">
              Log out
            </p>
            <div className="ml-1 group-hover:translate-x-1 duration-100 text-gray-700 group-hover:text-black">
              <LogoutOutlinedIcon />
            </div>
          </button>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        {open && (
          <div
            className="bg-black opacity-25 fixed w-[100vw] h-[100vh] z-10 flex md:hidden"
            onClick={() => {
              setOpen(false);
            }}
          ></div>
        )}
        <div className="bg-white flex flex-row justify-end mx-2 my-2 md:hidden">
          <button onClick={() => setOpen(!open)} className="z-20">
            {open ? (
              <CloseIcon style={{ width: 32, height: 32 }} />
            ) : (
              <MenuIcon style={{ width: 32, height: 32 }} />
            )}
          </button>
        </div>
        <div className="flex-1 flex flex-col">{children}</div>
      </div>
    </div>
  );
}
