import React, { useEffect, useState } from "react";

import axios from "axios";
import validatePhoneNumber from "validate-phone-number-node-js";
import emailValidator from "email-validator";

import BtnTwo from "../BtnTwo";
import AddIdSectionTitle from "../AddIdSectionTitle";
import AddIdInput from "../AddIdInput";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIdCountryInput from "../AddIdCountryInput";
import encryption from "../../utils/encryption";
import countries from "../../utils/countries";
import Logout from "../../utils/Logout";
import OverlayContentWrapper from "../OverlayContentWrapper";
import AddAccountInput from "../AddAccountInput";
import ReactDatePicker from "react-datepicker";

export default function ViewIdOverlay({
  identity,
  close,
  sync,
  promptDeleteId,
  encKey,
  setOverlay,
}) {
  const [startDate, setStartDate] = useState(new Date());

  const [country, setCountry] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [bYear, setBYear] = useState("");
  const [bMonth, setBMonth] = useState("");
  const [bDay, setBDay] = useState("");
  const [addr1, setAddr1] = useState("");
  const [addr2, setAddr2] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [cellNum, setCellNum] = useState("");
  const [homeNum, setHomeNum] = useState("");
  const [workNum, setWorkNum] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");

  const [invalidFields, setInvalidFields] = useState([]);
  const [originalState, setOriginalState] = useState("");

  const [isSubbed, setIsSubbed] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  const isNumber = (val) => {
    if (isNaN(val) == false) {
      if (Number(val) < 0) {
        return false;
      } else {
        if (val.includes("-")) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    try {
      if (encKey == undefined || encKey == "") {
        Logout();
        return;
      }
      let encryptedData = JSON.parse(atob(identity.data));
      //? console.log(encryptedData);
      let data = {};
      for (let key in encryptedData) {
        let value = encryptedData[key];
        if (value != "") {
          let decrypted = encryption.decrypt(value, encKey);
          data[key] = decrypted;
        } else {
          data[key] = "";
        }
      }
      let ogState = "";

      let [startDay, startMonth, startYear] = [null, null, null];

      for (let key in data) {
        let value = data[key];
        if (value != "") {
          if (key == "first") {
            setFirst(value);
          } else if (key == "last") {
            setLast(value);
          } else if (key == "birthYear") {
            setBYear(value);
            startYear = value;
          } else if (key == "birthMonth") {
            setBMonth(value);
            startMonth = value;
          } else if (key == "birthDay") {
            setBDay(value);
            startDay = value;
          } else if (key == "address1") {
            setAddr1(value);
          } else if (key == "address2") {
            setAddr2(value);
          } else if (key == "country") {
            setCountry(value);
          } else if (key == "city") {
            setCity(value);
          } else if (key == "zip") {
            setZip(value);
          } else if (key == "cellNum") {
            setCellNum(value);
          } else if (key == "homeNum") {
            setHomeNum(value);
          } else if (key == "workNum") {
            setWorkNum(value);
          } else if (key == "fax") {
            setFax(value);
          } else if (key == "email") {
            setEmail(value);
          }
          ogState += value;
        }
      }

      if (startYear != null && startMonth != null && startDay != null) {
        let sD = new Date();
        sD.setFullYear(Number(startYear));
        sD.setMonth(Number(startMonth) - 1);
        sD.setDate(Number(startDay));

        setStartDate(sD);
      }

      setOriginalState(ogState);
      setIsLoaded(true);
    } catch (e) {
      close();
    }
  }, []);

  const isChanged = () => {
    let currentState =
      first +
      last +
      bYear +
      bMonth +
      bDay +
      addr1 +
      addr2 +
      country +
      city +
      zip +
      cellNum +
      homeNum +
      workNum +
      fax +
      email;
    if (currentState == originalState) {
      return false;
    } else {
      return true;
    }
  };

  const submit = async () => {
    try {
      setIsSubbed(true);
      let errors = verifySubmit.all();
      if (errors.length != 0) {
        // If there are invalid fields
        setInvalidFields(errors);
      } else {
        if (encKey == undefined || encKey == "") {
          Logout();
          setIsSubbed(false);
          return;
        }
        setInvalidFields([]);
        // If all the fields are valid
        let data = {
          first,
          last,
          birthYear: bYear,
          birthMonth: bMonth,
          birthDay: bDay,
          address1: addr1,
          address2: addr2,
          country,
          city,
          zip,
          cellNum,
          homeNum,
          workNum,
          fax,
          email,
        };
        let output = {};
        for (let key in data) {
          let value = data[key];
          if (value != "" && key != "id") {
            let encrypted = encryption.encrypt(value, encKey);
            output[key] = encrypted;
          } else {
            output[key] = "";
          }
        }

        //

        let name = `${first}`;
        if (last != "") {
          name += ` ${last}`;
        }
        output["name"] = name;
        output["id"] = identity.id;

        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        output["_csrf"] = csrfToken;
        output["hashedPw"] = localStorage.getItem("hashedPw");

        let res = await axios.post("/api/updateIdentity", output);

        if (res.data.status == "success") {
          sync();
          close();
        } else {
          if (res.data.message == "pwHashError") {
            localStorage.removeItem("hashedPw");
            setOverlay({ type: "view-identity", data: identity });
            setIsSubbed(false);
            return;
          } else {
            close();
          }
        }
      }
      setIsSubbed(false);
    } catch {
      setIsSubbed(false);
      close();
    }
  };

  const isInvalid = (name) => {
    if (invalidFields.includes(name)) {
      return true;
    } else {
      return false;
    }
  };

  const verifySubmit = {
    country: () => {
      if (country == "") {
        return true;
      }

      let valid = true;
      let countryList = countries.get();
      let includes = false;
      countryList.forEach((c) => {
        if (c.toLowerCase() == country.toLowerCase()) {
          includes = true;
        }
      });
      if (includes == false) {
        valid = false;
      }
      return valid;
    },
    first: () => {
      if (first == "") {
        return false;
      }

      return true;
    },
    last: () => {
      if (last == "") {
        return true;
      }

      return true;
    },
    year: () => {
      if (bYear == "") {
        return true;
      }

      if (bYear.length != 4) {
        return false;
      } else {
        if (Number(bYear) <= 0) {
          return false;
        } else {
          return true;
        }
      }
    },
    month: () => {
      if (bMonth == "") {
        return true;
      }

      let valid = true;
      if (bMonth.length > 2) {
        valid = false;
      }
      if (Number(bMonth) > 12) {
        valid = false;
      }
      if (Number(bMonth) == 0) {
        valid = false;
      }
      return valid;
    },
    day: () => {
      if (bDay == "") {
        return true;
      }

      let valid = true;
      if (bDay.length > 2) {
        valid = false;
      }
      if (Number(bDay) > 31) {
        valid = false;
      }
      if (Number(bDay) <= 0) {
        valid = false;
      }
      return valid;
    },
    address1: () => {
      return true;
    },
    address2: () => {
      return true;
    },
    city: () => {
      return true;
    },
    zip: () => {
      if (zip == "") {
        return true;
      }

      if (zip.length > 12) {
        return false;
      } else if (zip != "" && zip.length <= 4) {
        return false;
      } else {
        return true;
      }
    },
    cellNum: () => {
      if (cellNum == "") {
        return true;
      }

      if (validatePhoneNumber.validate(cellNum)) {
        return true;
      } else {
        return false;
      }
    },
    homeNum: () => {
      if (homeNum == "") {
        return true;
      }

      if (validatePhoneNumber.validate(homeNum)) {
        return true;
      } else {
        return false;
      }
    },
    workNum: () => {
      if (workNum == "") {
        return true;
      }

      if (validatePhoneNumber.validate(workNum)) {
        return true;
      } else {
        return false;
      }
    },
    fax: () => {
      if (fax == "") {
        return true;
      }

      if (validatePhoneNumber.validate(fax)) {
        return true;
      } else {
        return false;
      }
    },
    email: () => {
      if (email == "") {
        return true;
      }

      if (emailValidator.validate(email)) {
        return true;
      } else {
        return false;
      }
    },
    all: () => {
      let errors = [];
      for (let key in verifySubmit) {
        if (key != "all") {
          let isValid = verifySubmit[key]();
          if (!isValid) {
            errors.push(key);
          }
        }
      }
      return errors;
    },
  };

  const setVal = {
    country: (val) => {
      let countryList = countries.get();
      let value = val;
      countryList.forEach((c) => {
        if (val.toLowerCase() == c.toLowerCase()) {
          value = c;
        }
      });
      setCountry(value);
    },
    first: (val) => {
      if (verifyVal.first(val)) {
        setFirst(val);
      }
    },
    last: (val) => {
      if (verifyVal.last(val)) {
        setLast(val);
      }
    },
    year: (val) => {
      if (verifyVal.year(val)) {
        setBYear(val);
      }
    },
    month: (val) => {
      if (verifyVal.month(val)) {
        setBMonth(val);
      }
    },
    day: (val) => {
      if (verifyVal.day(val)) {
        setBDay(val);
      }
    },
    address1: (val) => {
      if (verifyVal.address1(val)) {
        setAddr1(val);
      }
    },
    address2: (val) => {
      if (verifyVal.address2(val)) {
        setAddr2(val);
      }
    },
    city: (val) => {
      if (verifyVal.city(val)) {
        setCity(val);
      }
    },
    zip: (val) => {
      if (verifyVal.zip(val)) {
        setZip(val);
      }
    },
    cellNum: (val) => {
      if (verifyVal.phoneNumber(val)) {
        setCellNum(val);
      }
    },
    homeNum: (val) => {
      if (verifyVal.phoneNumber(val)) {
        setHomeNum(val);
      }
    },
    workNum: (val) => {
      if (verifyVal.phoneNumber(val)) {
        setWorkNum(val);
      }
    },
    fax: (val) => {
      if (verifyVal.phoneNumber(val)) {
        setFax(val);
      }
    },
    email: (val) => {
      if (verifyVal.email(val)) {
        setEmail(val);
      }
    },
  };

  const verifyVal = {
    first: (val) => {
      return true;
    },
    last: (val) => {
      return true;
    },
    year: (val) => {
      let valid = true;
      if (!isNumber(val)) {
        valid = false;
      }
      if (val.length > 4) {
        valid = false;
      }
      return valid;
    },
    month: (val) => {
      let valid = true;
      if (!isNumber(val)) {
        valid = false;
      } else {
        if (Number(val) > 12) {
          valid = false;
        }
      }
      if (val.length > 2) {
        valid = false;
      }
      return valid;
    },
    day: (val) => {
      let valid = true;
      if (!isNumber(val)) {
        valid = false;
      } else {
        if (Number(val) > 31) {
          valid = false;
        }
      }
      if (val.length > 2) {
        valid = false;
      }
      return valid;
    },
    address1: (val) => {
      return true;
    },
    address2: (val) => {
      return true;
    },
    city: (val) => {
      return true;
    },
    zip: (val) => {
      if (val.length > 7) {
        return false;
      } else {
        return true;
      }
    },
    phoneNumber: (val) => {
      if (isNumber(val)) {
        if (val.length > 20) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    email: (val) => {
      return true;
    },
  };

  return (
    <OverlayContentWrapper
      close={close}
      errMsg={
        invalidFields.length == 0
          ? ""
          : "Some of the information you entered was invalid"
      }
      loading={isSubbed}
      submit={submit}
      deactivated={!isChanged()}
    >
      {isLoaded ? (
        <>
          <p className="text-2xl text-neutral-800 font-bold">Name</p>
          <div className="flex flex-row mt-2">
            <div className="flex flex-row mr-1">
              <AddAccountInput
                name="First"
                placeholder="John"
                val={first}
                setVal={setVal.first}
                error={isInvalid("first")}
              />
            </div>
            <div className="flex flex-row ml-1">
              <AddAccountInput
                name="Last"
                placeholder="doe"
                val={last}
                setVal={setVal.last}
                error={isInvalid("last")}
              />
            </div>
          </div>
          <p className="text-2xl text-neutral-800 font-bold mt-4">Birth date</p>
          <div className="flex flex-col mt-2">
            <ReactDatePicker
              selected={startDate}
              onChange={(d) => {
                setStartDate(d);
                let y = d.getFullYear();
                let m = d.getMonth() + 1;
                let day = d.getDate();
                setBDay(String(day));
                setBYear(String(y));
                setBMonth(String(m));
              }}
              minDate={-2208970798738}
              maxDate={Date.now()}
              placeholderText="MM/DD/YYYY"
              showYearDropdown
              yearDropdownItemNumber={200}
              scrollableYearDropdown
              dayClassName={(date) =>
                date == startDate ? "bg-red-500" : undefined
              }
              className="bg-white w-[100%] border p-2 outline-none border-neutral-300 focus-within:border-neutral-500 placeholder:text-neutral-500 rounded-lg text-neutral-900"
            />
            <p className="text-2xl text-neutral-800 font-bold mt-4">Address</p>
          </div>
          <div className="mt-2">
            <AddAccountInput
              name="Address line 1"
              placeholder=""
              val={addr1}
              setVal={setVal.address1}
              error={isInvalid("address1")}
            />
          </div>
          <div className="mt-2">
            <AddAccountInput
              name="Address line 2 "
              placeholder=""
              val={addr2}
              setVal={setVal.address2}
              error={isInvalid("address2")}
            />
          </div>
          <div className="flex flex-row mt-2">
            <div className="mr-1 flex-1">
              <AddIdCountryInput
                name="Country"
                val={country}
                setVal={setVal.country}
                error={isInvalid("country")}
              />
            </div>
            <div className="ml-1 flex-1">
              <AddAccountInput
                name="City"
                placeholder=""
                val={city}
                setVal={setVal.city}
                error={isInvalid("city")}
              />
            </div>
          </div>
          <div className="mt-2">
            <AddAccountInput
              name="Zip/Postal code"
              placeholder=""
              val={zip}
              setVal={setVal.zip}
              error={isInvalid("zip")}
            />
          </div>
          <p className="text-2xl text-neutral-800 font-bold mt-4">Contact</p>
          <div className="mt-2">
            <AddAccountInput
              name="Cell phone"
              placeholder=""
              val={cellNum}
              setVal={setVal.cellNum}
              error={isInvalid("cellNum")}
            />
          </div>
          <div className="mt-2">
            <AddAccountInput
              name="Home phone"
              placeholder=""
              val={homeNum}
              setVal={setVal.homeNum}
              error={isInvalid("homeNum")}
            />
          </div>
          <div className="mt-2">
            <AddAccountInput
              name="Work phone"
              placeholder=""
              val={workNum}
              setVal={setVal.workNum}
              error={isInvalid("workNum")}
            />
          </div>
          <div className="mt-2">
            <AddAccountInput
              name="Fax phone"
              placeholder=""
              val={fax}
              setVal={setVal.fax}
              error={isInvalid("fax")}
            />
          </div>
          <div className="mt-2">
            <AddAccountInput
              name="Email"
              placeholder=""
              val={email}
              setVal={setVal.email}
              error={isInvalid("email")}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </OverlayContentWrapper>
  );
}

// return (
//   <>
//     <div className="addIdOverlay-box">
//       <div className="addIdOverlay-container custom-scrollbar">
//         <AddIdSectionTitle title="Name" />
//         <div className="addIdFlex">
//           <AddIdInput
//             name="First"
//             val={first}
//             set={setVal.first}
//             invalid={isInvalid("first")}
//           />
//           <div className="addIdOverlay-seperator"></div>
//           <AddIdInput
//             name="Last"
//             val={last}
//             set={setVal.last}
//             invalid={isInvalid("last")}
//           />
//         </div>
//         <AddIdSectionTitle title="Birth date" />
//         <div className="addIdFlex">
//           <div style={{ flex: 2 }}>
//             <AddIdInput
//               name="Year"
//               val={bYear}
//               set={setVal.year}
//               invalid={isInvalid("year")}
//               placeholder="Ex: 1999"
//             />
//           </div>
//           <div className="addIdOverlay-seperator"></div>
//           <AddIdInput
//             name="Month"
//             val={bMonth}
//             set={setVal.month}
//             invalid={isInvalid("month")}
//             placeholder="Ex: 12"
//           />
//           <div className="addIdOverlay-seperator"></div>
//           <AddIdInput
//             name="Day"
//             val={bDay}
//             set={setVal.day}
//             invalid={isInvalid("day")}
//             placeholder="Ex: 31"
//           />
//         </div>
//         <AddIdSectionTitle title="Address" />
//         <div style={{ marginBottom: 12 }}>
//           <AddIdInput
//             name="Address line 1"
//             val={addr1}
//             set={setVal.address1}
//             invalid={isInvalid("address1")}
//           />
//         </div>
//         <div style={{ marginBottom: 12 }}>
//           <AddIdInput
//             name="Address line 2"
//             val={addr2}
//             set={setVal.address2}
//             invalid={isInvalid("address2")}
//           />
//         </div>
//         <div className="addIdFlex" style={{ marginBottom: 12 }}>
//           <AddIdCountryInput
//             val={country}
//             set={setVal.country}
//             invalid={isInvalid("country")}
//           />
//           <div className="addIdOverlay-seperator"></div>
//           <AddIdInput
//             name="City"
//             val={city}
//             set={setVal.city}
//             invalid={isInvalid("city")}
//           />
//         </div>
//         <div style={{ marginBottom: 12 }}>
//           <AddIdInput
//             name="Zip/Postal code"
//             val={zip}
//             set={setVal.zip}
//             invalid={isInvalid("zip")}
//           />
//         </div>
//         <AddIdSectionTitle title="Contact" />
//         <div style={{ marginBottom: 12 }}>
//           <AddIdInput
//             name="Cell phone"
//             val={cellNum}
//             set={setVal.cellNum}
//             invalid={isInvalid("cellNum")}
//           />
//         </div>
//         <div style={{ marginBottom: 12 }}>
//           <AddIdInput
//             name="Home phone"
//             val={homeNum}
//             set={setVal.homeNum}
//             invalid={isInvalid("homeNum")}
//           />
//         </div>
//         <div style={{ marginBottom: 12 }}>
//           <AddIdInput
//             name="Work phone"
//             val={workNum}
//             set={setVal.workNum}
//             invalid={isInvalid("workNum")}
//           />
//         </div>
//         <div style={{ marginBottom: 12 }}>
//           <AddIdInput
//             name="Fax"
//             val={fax}
//             set={setVal.fax}
//             invalid={isInvalid("fax")}
//           />
//         </div>
//         <div style={{ marginBottom: 48 }}>
//           <AddIdInput
//             name="Email"
//             val={email}
//             set={setVal.email}
//             invalid={isInvalid("email")}
//           />
//         </div>
//       </div>
//     </div>
//     <div className="addIdOverlay-footerContainer">
//       <div style={{ display: "flex", flexDirection: "column" }}>
//         <div style={{ flex: 1 }}></div>
//         <div
//           className="viewAccount-deletebtn"
//           style={{ alignItems: "center" }}
//           onClick={() => promptDeleteId(identity)}
//         >
//           <DeleteIcon />
//           <p>Delete</p>
//         </div>
//       </div>
//       <div
//         style={{
//           flex: 1,
//           display: "flex",
//           alignItems: "center",
//           marginLeft: 16,
//         }}
//       >
//         {(() => {
//           if (invalidFields.length == 0) {
//             return <></>;
//           } else {
//             return (
//               <p style={{ fontSize: 16, color: "red" }}>
//                 Some of the information you entered was invalid
//               </p>
//             );
//           }
//         })()}
//       </div>
//       <BtnTwo
//         name="Save"
//         style={{ width: 200 }}
//         onClick={submit}
//         deactivated={!isChanged()}
//         loading={isSubbed}
//       />
//     </div>
//   </>
// );
