import React from "react";

export default function BtnFour({
  name,
  onClick,
  deactivated,
  loading,
  hoverEffect = true,
}) {
  return (
    <button
      onClick={onClick && !deactivated && !loading ? onClick : () => {}}
      className={
        !deactivated && !loading
          ? `bg-[#FFFCBA] py-2 px-6 rounded-md text-[#85823D] border border-[#FFFCBA] text-base font-semibold duration-150 relative ${
              hoverEffect ? "hover:brightness-90" : ""
            }`
          : `bg-white py-2 px-6 rounded-md test-base font-semibold text-[#aaa] border border-[#aaa] cursor-default relative`
      }
    >
      <p className={loading ? "opacity-0 select-none" : ""}>{name}</p>
      <div
        className={`absolute w-[100%] h-[100%] left-0 top-0 flex justify-center items-center ${
          !loading ? "hidden" : ""
        }`}
      >
        <div className="w-6 h-6 rounded-[50%] border border-[#aaa] border-r-[#fff] animate-spin"></div>
      </div>
    </button>
  );
}