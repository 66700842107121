import React from "react";

import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="mt-24 py-6 px-4 flex flex-col border-t border-t-neutral-500">
      <div className="flex flex-row my-2">
        <div className="flex flex-col md:flex-row items-center justify-center w-full text-gray-800">
          <div className="underline">
            <Link to="/contact">Contact us!</Link>
          </div>
          <div className="underline my-2 mx-4">
            <Link to="/faq">Frequently asked questions!</Link>
          </div>
          <div className="underline">
            <Link to="/signup">Sign up!</Link>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-row justify-end items-end text-gray-800 font-bold">
        <a href="mailto:support@pass-protect.com">support@pass-protect.com</a>
      </div>
      <div className="flex flex-col text-gray-800 mt-4 border-t border-t-gray-800 pt-4">
        <div className="flex flex-row items-center justify-between">
          <p>Pass Protect 2023 ©</p>
          <Link to="/tos" className="underline">
            Terms of service
          </Link>
        </div>
      </div>
    </div>
  );
}
