import React, { useState } from "react";
import BtnTwo from "../BtnTwo";

import axios from "axios";
import SubscriptionBox from "../SubscriptionBox";

import KeyIcon from "@mui/icons-material/Key";
import BtnThree from "../BtnThree";

export default function SettingsSubscription({
  subbed,
  renews,
  ends,
  sync,
  subscriptionType,
}) {
  const [makingReq, setMakingReq] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [subKey, setSubKey] = useState("");
  const [keyErrMsg, setKeyErrMsg] = useState("");

  const checkout = async () => {
    if (makingReq == false) {
      setMakingReq(true);
      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      let response = await axios.post("/api/checkout", { _csrf: csrfToken });
      let res = response.data;
      if (res.status == "success") {
        let url = res.data.url;
        document.location = url;
      } else {
        setErrMsg(res.message);
      }
      setMakingReq(false);
    }
  };

  const cancelSub = async () => {
    try {
      setMakingReq(true);
      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      let res = await axios.post("/api/subscription/cancel", {
        _csrf: csrfToken,
      });

      if (res.status != "success") {
        setErrMsg(res.data.message);
      }

      let resData = res.data.data;
      await sync();
      setMakingReq(false);
    } catch {
      setMakingReq(false);
    }
  };

  const reactivateSub = async () => {
    try {
      setMakingReq(true);
      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      let res = await axios.post("/api/subscription/reactivate", {
        _csrf: csrfToken,
      });

      if (res.status != "success") {
        setErrMsg(res.data.message);
      }

      let resData = res.data.data;
      await sync();
      setMakingReq(false);
    } catch {
      setMakingReq(false);
    }
  };

  const redeemKey = async () => {
    try {
      setMakingReq(true);
      setKeyErrMsg("");

      if (subKey.length != 23) {
        throw new Error("Invalid key");
      }

      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      let res = await axios.post("/api/redeemKey", {
        _csrf: csrfToken,
        key: subKey,
      });

      let resData = res.data;

      if (resData.status == "success") {
        document.location = document.location;
      } else {
        throw new Error(resData.message);
      }

      setMakingReq(false);
    } catch (e) {
      let eMsg = e.message;
      if (eMsg == "" || eMsg == undefined) {
        eMsg = "An error has occured";
      }
      setKeyErrMsg(eMsg);
      setMakingReq(false);
    }
  };

  const removeKey = async () => {
    try {
      setMakingReq(true);
      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      let res = await axios.post("/api/removeKey", {
        _csrf: csrfToken,
      });

      if (res.data.status != "success") {
        setErrMsg(res.data.message);
      } else {
        await sync();
        //? console.log("HERE");
        document.location = document.location;
      }
      setMakingReq(false);
    } catch {
      setMakingReq(false);
    }
  };

  return (
    <div className="flex flex-1 flex-col">
      {subbed ? (
        <>
          <div className="flex flex-row">
            <p className="text-base text-neutral-800 font-bold">
              Current subscription:
            </p>
            <p className="ml-2 text-base text-neutral-500">
              {subscriptionType}
            </p>
          </div>
          <div className="flex flex-row mt-4">
            <p className="text-base text-neutral-800 font-bold">
              {renews
                ? `${
                    subscriptionType.toLowerCase() == "free trial"
                      ? "Subscription starts: "
                      : "Subscription renews: "
                  }`
                : `${
                    subscriptionType.toLowerCase() == "free trial"
                      ? "Trial ends: "
                      : "Subscription ends: "
                  }`}
            </p>
            <p className="ml-2 text-base text-neutral-500">{ends}</p>
          </div>
          <div className="mt-2 flex flex-col">
            {(() => {
              if (subscriptionType.toLowerCase() == "subscription key") {
                return (
                  <BtnTwo
                    name="Remove key"
                    loading={makingReq}
                    onClick={() => removeKey()}
                  />
                );
              } else {
                return (
                  <>
                    {renews ? (
                      <>
                        <BtnTwo
                          name="Cancel subscription"
                          loading={makingReq}
                          onClick={() => cancelSub()}
                        />
                      </>
                    ) : (
                      <>
                        <BtnTwo
                          name="Reactivate subscription"
                          loading={makingReq}
                          onClick={() => reactivateSub()}
                        />
                      </>
                    )}
                  </>
                );
              }
            })()}
          </div>
          <p className="text-sm mt-2 text-red-500 h-8">{errMsg || ""}</p>
        </>
      ) : (
        <>
          <p className="text-neutral-800">
            Go to the Home Page to subscribe, or use a subscription key instead
            if you have one.
          </p>
          <p className="mt-3 text-neutral-600 mb-1">Subscription key</p>
          <div className="border border-neutral-600 px-1 flex flex-row items-center rounded">
            <KeyIcon className="text-neutral-600" />
            <input
              placeholder="Paste key here"
              className="flex-1 py-2 outline-none ml-2"
              value={subKey}
              onChange={(e) => setSubKey(e.target.value)}
            />
          </div>
          <div className="flex flex-col mt-2">
            <BtnThree name="Redeem key" onClick={redeemKey} />
          </div>
          <p className="text-red-500 mt-2">{keyErrMsg}</p>
        </>
      )}
    </div>
  );
}
