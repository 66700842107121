import React from "react";

import OtherHeader from "../components/OtherHeader";

export default function Tos({ loggedIn, email }) {
  return (
    <div className="flex-1 flex flex-col">
      {loggedIn ? (
        <>
          <OtherHeader email={email} />
        </>
      ) : (
        <></>
      )}
      <div className="flex flex-1 mt-8 xl:mx-48 lg:mx-36 md:mx-24 my-8 relative flex-col border border-neutral-700 rounded-lg">
        <div className="absolute w-full h-full left-0 top-0 p-4 flex flex-col overflow-y-auto">
          <div className="flex flex-1 flex-col px-8">
            <h1 className="text-center text-3xl text-neutral-800">
              Terms of service
            </h1>
            <p className="mt-4 text-neutral-800">
              These terms of Service ("Terms") govern your use of the Pass
              Protect website,{" "}
              <a
                href="https://www.pass-protect.com"
                className="text-[#DB287B] underline hover:brightness-75"
              >
                www.pass-protect.com
              </a>{" "}
              ("Website") and the Pass Protect mobile application ("App"). By
              accessing or using the Website and/or App, you agree to be bound
              by these Terms. If you do not agree with any part of these Terms,
              you may not access or use the Website and/or App.
            </p>
            <Section number={1} name="Contact information">
              <p>
                For any inquiries or concerns regarding the Website and/or App,
                you can reach us through the following contact methods:
              </p>
              <ul className="list-disc ms-8">
                <li>Email: support@pass-protect.com</li>
                <li>
                  Contact page:{" "}
                  <a
                    href="https://www.pass-protect.com/contact"
                    className="text-[#DB287b] underline hover:brightness-75 cursor-pointer"
                  >
                    www.pass-protect.com/contact
                  </a>
                </li>
              </ul>
            </Section>
            <Section number={2} name="Online payments">
              <p>
                The Website and/or App accept online payments for the services
                offered. By providing your payment information, you authorize us
                to charge the specified amount for the selected services.
              </p>
            </Section>
            <Section number={3} name="Refunds and Billing">
              <p>
                We do not provide refunds for the services offered through the
                Website and/or App. Additionally, if you sign up for a free
                trial, please note that you will be automatically billed for the
                selected services unless you cancel before the end of the trial
                period.
              </p>
            </Section>
            <Section number={4} name="Modifications to Terms">
              <p>
                We reserve the right to modify these Terms at any time without
                prior notice. It is your responsibility to review the Terms
                periodically for any updates or changes. By continuing to use
                the Website and/or App after any modifications to the Terms, you
                agree to be bound by the revised Terms.
              </p>
            </Section>
            <Section number={5} name="Disclaimer of Warranties">
              <p>
                The Website and/or App are provided on an "as-is" and "as
                available" basis. We make no warranties or representations of
                any kind, express or implied, regarding the operation,
                availability, or accuracy of the Website and/or App.
              </p>
            </Section>
            <Section number={6} name="Limitation of Liability">
              <p>
                To the extent permitted by law, we shall not be liable for any
                direct, indirect, incidental, consequential, or punitive damages
                arising out of or in connection with your use of the Website
                and/or App.
              </p>
            </Section>
            <Section number={7} name="Governing Law and Jurisdiction">
              <p>
                These Terms shall be governed by and construed in accordance
                with the laws of Ontario, Canada. Any legal actions or
                proceedings arising out of or relating to these Terms shall be
                exclusively brought in the courts located in Ontario, Canada.
              </p>
            </Section>
            <h1 className="text-center text-3xl text-neutral-800 my-4">
              Privacy Policy
            </h1>
            <p className="text-neutral-800">Apart from the data you directly give us (such as your email address), we do not collect any of your data or sell it to third parties.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const Section = ({ children, name, number }) => {
  return (
    <div className="mt-4">
      <div className="flex flex-row items-center text-lg text-neutral-900">
        <span className="font-bold">{number}.</span>
        <span className="font-bold ml-2">{name}</span>
      </div>
      <div className="mt-2 text-neutral-800 flex flex-col">{children}</div>
    </div>
  );
};
