import React, { useState, useEffect } from "react";

import HomePageAccounts from "./HomePageAccounts";
import HomePageNotes from "./HomePageNotes";
import HomePageIdentities from "./HomePageIdentities";
import HomePageCards from "./HomePageCards";

import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";

export default function HomePageContentContainer({
  setOverlay,
  encrypted,
  menuSelection,
  columns,
  searchTerm,
}) {
  const [viewType, setViewType] = useState(
    localStorage.getItem("viewType") || "list"
  );

  useEffect(() => {
    let vt = localStorage.getItem("viewType");
    if (vt == null || vt == "null") {
      localStorage.setItem("viewType", "list");
    }
  }, []);

  const changeViewType = (val) => {
    localStorage.setItem("viewType", val);
    setViewType(val);
  };

  return (
    <div className="flex flex-1 bg-white mb-6 flex-col relative mx-3 md:mx-6">
      <div className="absolute w-[100%] h-[100%] left-0 top-0 flex flex-1 flex-col custom-scrollbar overflow-y-auto px-2">
        <div className="flex flex-row mt-2 items-center justify-end">
          <button
            onClick={() => changeViewType("grid")}
            className={`mr-4 p-2 rounded-md ${
              viewType == "grid" ? "bg-[#ccc]" : ""
            }`}
          >
            <GridViewIcon />
          </button>
          <button
            onClick={() => changeViewType("list")}
            className={`p-2 rounded-md ${
              viewType == "list" ? "bg-[#ccc]" : ""
            }`}
          >
            <ListIcon />
          </button>
        </div>
        <div className="flex-1 flex flex-col">
          {(() => {
            if (menuSelection == "password") {
              return (
                <HomePageAccounts
                  viewType={viewType}
                  searchTerm={searchTerm}
                  setOverlay={setOverlay}
                  accounts={encrypted.password || []}
                />
              );
            } else if (menuSelection == "card") {
              return (
                <HomePageCards
                  viewType={viewType}
                  setOverlay={setOverlay}
                  cards={encrypted.card || []}
                  searchTerm={searchTerm}
                />
              );
            } else if (menuSelection == "identity") {
              return (
                <HomePageIdentities
                  viewType={viewType}
                  searchTerm={searchTerm}
                  setOverlay={setOverlay}
                  identities={encrypted.identity || []}
                />
              );
            } else if (menuSelection == "note") {
              return (
                <HomePageNotes
                  viewType={viewType}
                  searchTerm={searchTerm}
                  setOverlay={setOverlay}
                  notes={encrypted.note || []}
                />
              );
            }
          })()}
        </div>
      </div>
    </div>
  );
}

// return (
//   <>
//     <div className="hp-content-scroll" style={{ position: "relative" }}>
//       <div
//         style={{
//           position: "absolute",
//           left: 0,
//           top: 0,
//           width: "100%",
//           height: "100%",
//           overflowY: "auto",
//           overflowX: "inherit",
//           display: "flex",
//           flexDirection: "column",
//         }}
//         className="custom-scrollbar"
//       >
//         <div style={{ display: "flex", flexDirection: "row" }}>
//           <div style={{ flex: 1 }}></div>
//           <div
//             style={{
//               marginRight: 8,
//               marginTop: 8,
//               display: "flex",
//               flexDirection: "row",
//             }}
//           >
//             <div
//               className="changeViewBtn"
//               style={(() => {
//                 if (viewType == "grid") {
//                   return { backgroundColor: "#ddd" };
//                 } else {
//                   return {};
//                 }
//               })()}
//               onClick={() => changeViewType("grid")}
//             >
//               <GridViewIcon />
//             </div>
//             <div style={{ width: 16 }}></div>
//             <div
//               onClick={() => changeViewType("list")}
//               className="changeViewBtn"
//               style={(() => {
//                 if (viewType == "list") {
//                   return { backgroundColor: "#ddd" };
//                 } else {
//                   return {};
//                 }
//               })()}
//             >
//               <ListIcon />
//             </div>
//           </div>
//         </div>
//         {(() => {
//           if (viewType == "grid") {
//             return (
//               <>
//                 <div
//                   className="homepage-content-container"
//                   style={{ gridTemplateRows: `repeat(${columns}, 185px)`, position: "relative" }}
//                 >
//                   {(() => {
//                     if (menuSelection == "accounts") {
//                       return (
//                         <HomePageAccounts
//                           viewType={viewType}
//                           searchTerm={searchTerm}
//                           setOverlay={setOverlay}
//                           accounts={encrypted.password || []}
//                         />
//                       );
//                     } else if (menuSelection == "notes") {
//                       return (
//                         <HomePageNotes
//                           viewType={viewType}
//                           searchTerm={searchTerm}
//                           setOverlay={setOverlay}
//                           notes={encrypted.note || []}
//                         />
//                       );
//                     } else if (menuSelection == "identities") {
//                       return (
//                         <HomePageIdentities
//                           viewType={viewType}
//                           searchTerm={searchTerm}
//                           setOverlay={setOverlay}
//                           identities={encrypted.identity || []}
//                         />
//                       );
//                     } else if (menuSelection == "cards") {
//                       return (
//                         <HomePageCards
//                           viewType={viewType}
//                           setOverlay={setOverlay}
//                           cards={encrypted.card || []}
//                           searchTerm={searchTerm}
//                         />
//                       );
//                     }
//                   })()}
//                 </div>
//               </>
//             );
//           } else {
//             return (
//               <>
//                 <div
//                   style={{
//                     flex: 1,
//                     flexDirection: "column",
//                     display: "flex",
//                   }}
//                 >
//                   {(() => {
//                     if (menuSelection == "accounts") {
//                       return (
//                         <HomePageAccounts
//                           viewType={viewType}
//                           searchTerm={searchTerm}
//                           setOverlay={setOverlay}
//                           accounts={encrypted.password || []}
//                         />
//                       );
//                     } else if (menuSelection == "notes") {
//                       return (
//                         <HomePageNotes
//                           viewType={viewType}
//                           searchTerm={searchTerm}
//                           setOverlay={setOverlay}
//                           notes={encrypted.note || []}
//                         />
//                       );
//                     } else if (menuSelection == "identities") {
//                       return (
//                         <HomePageIdentities
//                           viewType={viewType}
//                           searchTerm={searchTerm}
//                           setOverlay={setOverlay}
//                           identities={encrypted.identity || []}
//                         />
//                       );
//                     } else if (menuSelection == "cards") {
//                       return (
//                         <HomePageCards
//                           viewType={viewType}
//                           setOverlay={setOverlay}
//                           cards={encrypted.card || []}
//                           searchTerm={searchTerm}
//                         />
//                       );
//                     }
//                   })()}
//                 </div>
//               </>
//             );
//           }
//         })()}
//       </div>
//     </div>
//   </>
// );
