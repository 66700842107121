import React from "react";

import axios from "axios";
import BtnTwo from "../BtnTwo";
import BtnThree from "../BtnThree";

export default function ConfirmDeleteIdentity({ data, close, sync }) {
  const deleteIdentity = async () => {
    let id = data.id;

    let csrfToken = await axios.get("/api/csrf");
    csrfToken = csrfToken.data.token;

    await axios.post("/api/deleteEncrypted", { id, _csrf: csrfToken });

    close();
    sync();
  };

  return (
    <div className="p-3 flex flex-col">
      <p className="text-lg text-center text-neutral-800">
        Are you sure you want to delete identity "{data.name}"? This action
        cannot be undone.
      </p>
      <div className="flex flex-col mt-2">
        <div className="mb-2 flex flex-col">
          <BtnTwo name="Cancel" onClick={close} />
        </div>
        <BtnThree name="Delete" onClick={deleteIdentity} />
      </div>
    </div>
  );
}
