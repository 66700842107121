import React from "react";

import AddIcon from "@mui/icons-material/Add";

export default function ListAddBtn({ onClick, centered = false, name}) {
  return (
    <button
      onClick={onClick}
      className={`flex flex-row bg-[#BAE6FF] mb-2 py-2 px-2 min-[790px]:px-4 rounded-lg text-[#2282B9] hover:brightness-90 duration-100 text-sm items-center${
        centered ? " justify-center" : ""
      }`}
    >
      <AddIcon />
      <p className="ml-2 mr-2 select-none">{name}</p>
    </button>
  );
}
