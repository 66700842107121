import { useState, useEffect } from "react";
import axios from "axios";

import AuthStatus from "./components/AuthStatus";

import Header from "./components/Header";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import CheckoutRequest from "./pages/CheckoutRequest";
import HomePage from "./pages/HomePage";
import OverlayContainer from "./components/OverlayContainer";
import Settings from "./pages/Settings";
import TfaPromptPage from "./pages/TfaPromptPage";
import ForgotPassword from "./pages/ForgotPassword";
import Footer from "./components/Footer";
import ResetPassword from "./pages/ResetPassword";

import KeyUtils from "./utils/KeyUtils";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import "./styles/main.css";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import Tos from "./pages/Tos";
import LoadingScreen from "./components/LoadingScreen";
import Err404 from "./pages/Err404";

function App() {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [overlay, setOverlay] = useState({});
  const [encrypted, setEncrypted] = useState({});
  const [uid, setUid] = useState("");
  const [settings, setSettings] = useState();
  const [reqPassword, setReqPassword] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [containerLoaded, setContainerLoaded] = useState(false); //overlay container
  const [freeTrialDone, setFreeTrialDone] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [isTeamManager, setIsTeamManager] = useState(null);

  const isEmpty = (val) => {
    let count = 0;
    for (let keys in val) {
      count += 1;
    }
    if (count == 0) {
      return true;
    } else {
      return false;
    }
  };

  const closeOverlayContainer = () => {
    setOverlay({});
    setContainerLoaded(false);
  };

  const syncSettings = async (auth) => {
    if (auth || isAuth) {
      let res = await axios.get("/api/getSettings");
      let data = res.data;
      if (data.status == "success") {
        setSettings(data.data);
        setReqPassword(data.data.requirePassword);

        localStorage.setItem("settings", JSON.stringify(data.data));
        return data.data;
      }
    }
  };

  const sync = async (auth) => {
    if (auth || isAuth) {
      let enced = await axios.get("/api/getEncrypted");
      let data = enced.data;
      if (data.status == "success") {
        let output = data.data;
        let newUh = data.updateHash;
        localStorage.setItem("encryptedData", JSON.stringify(output));
        localStorage.setItem("updateHash", newUh);
        setEncrypted(output);
      }
    }
  };

  const promptDeleteAccount = (accData) => {
    setOverlay({ type: "confirm-delete-account", data: accData });
  };

  const promptDeleteId = (idData) => {
    setOverlay({ type: "confirm-delete-id", data: idData });
  };

  const promptDeleteCard = (cardData) => {
    setOverlay({ type: "confirm-delete-card", data: cardData });
  };

  const promptDeleteNote = (noteData) => {
    setOverlay({ type: "confirm-delete-note", data: noteData });
  };

  useEffect(() => {
    if (email == "") {
      (async () => {
        let response = await axios.get("/api/getEmail");
        if (response.data.status != "success") {
          if (response.data.message == "unauthenticated") {
            localStorage.clear();
          }
          setEmail("none");
        } else if (response.data.data.email != undefined) {
          let outKeys = [];
          let wrpdKeys = response.data.data.wrappedKeys;
          wrpdKeys.forEach((k) => {
            let o = {
              id: k.id,
              type: k.keyType,
              wrappedKey: k.wrappedKey,
            };

            if (
              response.data.data.requirePassword ||
              response.data.data.hashedPw != localStorage.getItem("hashedPw") ||
              localStorage.getItem("privKey") == null
            ) {
              outKeys.push(o);
              localStorage.removeItem("privKey");
            } else {
              let privKey = localStorage.getItem("privKey");
              let unwrapped = KeyUtils.unWrapKey(privKey, k.wrappedKey, true);
              o["unWrapped"] = unwrapped;
              outKeys.push(o);
            }
          });

          localStorage.setItem("keys", JSON.stringify(outKeys));

          let uh = response.data.data.updateHash;
          let localUh = localStorage.getItem("updateHash");

          if (uh != localUh) {
            await sync(response.data.data.isAuth);
            await syncSettings(response.data.data.isAuth);

            localStorage.setItem("updateHash", uh);
          } else {
            let encedLocalStorage = localStorage.getItem("encryptedData");

            if (encedLocalStorage != undefined) {
              try {
                let parsed = JSON.parse(encedLocalStorage);
                if (
                  parsed["password"] != undefined &&
                  parsed["identity"] != undefined &&
                  parsed["card"] != undefined &&
                  parsed["note"] != undefined
                ) {
                  setEncrypted(parsed);
                } else {
                  await sync(response.data.data.isAuth);
                }
              } catch {
                await sync(response.data.data.isAuth);
              }
            } else {
              await sync(response.data.data.isAuth);
            }

            let localSettings = localStorage.getItem("settings");

            if (localSettings != undefined) {
              try {
                let parsed = JSON.parse(localSettings);

                setSettings(parsed);
                setReqPassword(parsed.requirePassword);
              } catch {
                await syncSettings(response.data.data.isAuth);
              }
            } else {
              await syncSettings(response.data.data.isAuth);
            }
          }

          setIsAuth(response.data.data.isAuth);
          setEmailVerified(response.data.data.verified);
          setSubscribed(response.data.data.subscribed);
          setUid(response.data.data.uid);
          setEmail(response.data.data.email);
          setFreeTrialDone(response.data.data.didFreeTrial);
          setSubscriptionType(response.data.data.subscriptionType);
          setIsTeamManager(response.data.data.isTeamManager || false);
        }
      })();
    }
  }, []);

  return (
    <Router>
      <div id="appContainer">
        {(() => {
          if (email == "") {
            // Change after
            return (
              <>
                <LoadingScreen />
              </>
            );
          } else {
            return (
              <>
                {(() => {
                  if (email == "none") {
                    return <Header email={email} isAuth={isAuth} />;
                  }
                })()}
                <Routes>
                  {(() => {
                    if (isAuth == false && email != "none") {
                      return (
                        <>
                          <Route
                            path="*"
                            element={<TfaPromptPage email={email} uid={uid} />}
                          />
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Route
                            path="/"
                            element={
                              <AuthStatus
                                emailVerified={emailVerified}
                                email={email}
                                subbed={subscribed}
                                loggedOut={<Landing />}
                                unsubscribed={
                                  <CheckoutRequest
                                    freeTrialDone={freeTrialDone}
                                    email={email}
                                  />
                                }
                                subscribed={
                                  <HomePage
                                    email={email}
                                    setOverlay={setOverlay}
                                    encrypted={encrypted}
                                    overlay={
                                      <>
                                        <div
                                          className={`fixed w-[100vw] h-[100vh] z-40 justify-center items-center ${
                                            isEmpty(overlay) || !containerLoaded
                                              ? "hidden"
                                              : "flex"
                                          }`}
                                        >
                                          <div
                                            className="absolute left-0 top-0 w-[100%] h-[100%] bg-black opacity-25 cursor-pointer"
                                            onClick={() => setOverlay({})}
                                          ></div>
                                          <div
                                            className="flex bg-white shadow-2xl rounded-xl"
                                            style={{ zIndex: 41 }}
                                          >
                                            {(() => {
                                              if (isEmpty(overlay) == false) {
                                                return (
                                                  <OverlayContainer
                                                    setContainerLoaded={
                                                      setContainerLoaded
                                                    }
                                                    setOverlay={setOverlay}
                                                    uid={uid}
                                                    requirePassword={
                                                      reqPassword
                                                    }
                                                    data={overlay}
                                                    sync={sync}
                                                    promptDeleteAccount={
                                                      promptDeleteAccount
                                                    }
                                                    promptDeleteId={
                                                      promptDeleteId
                                                    }
                                                    promptDeleteCard={
                                                      promptDeleteCard
                                                    }
                                                    promptDeleteNote={
                                                      promptDeleteNote
                                                    }
                                                    close={() =>
                                                      closeOverlayContainer()
                                                    }
                                                  />
                                                );
                                              }
                                            })()}
                                          </div>
                                        </div>
                                      </>
                                    }
                                  />
                                }
                              />
                            }
                          />
                          <Route
                            path="/signup"
                            element={
                              <AuthStatus
                                email={email}
                                subbed={subscribed}
                                loggedOut={<Signup />}
                                loggedIn={<Navigate to="/" replace />}
                              />
                            }
                          />
                          <Route
                            path="/login"
                            element={
                              <AuthStatus
                                email={email}
                                subbed={subscribed}
                                loggedOut={<Login />}
                                loggedIn={<Navigate to="/" replace />}
                              />
                            }
                          />
                          <Route
                            path="settings"
                            element={
                              <AuthStatus
                                email={email}
                                subbed={subscribed}
                                loggedOut={<Navigate to="/" replace />}
                                loggedIn={
                                  <Settings
                                    isTeamManager={isTeamManager}
                                    subscriptionType={subscriptionType}
                                    settings={settings}
                                    syncSettings={syncSettings}
                                    email={email}
                                    encrypted={encrypted}
                                    uid={uid}
                                    syncEnc={sync}
                                  />
                                }
                              />
                            }
                          />
                          <Route
                            path="forgot-password"
                            element={
                              <AuthStatus
                                email={email}
                                subbed={subscribed}
                                loggedIn={<Navigate to="/" replace />}
                                loggedOut={<ForgotPassword />}
                              />
                            }
                          />
                          <Route
                            path="reset-password"
                            element={
                              <AuthStatus
                                email={email}
                                subbed={subscribed}
                                loggedIn={<Navigate to="/" replace />}
                                loggedOut={<ResetPassword />}
                              />
                            }
                          />
                          <Route
                            path="faq"
                            element={
                              <AuthStatus
                                email={email}
                                subbed={subscribed}
                                loggedOut={<Faq loggedIn={false} />}
                                loggedIn={<Faq loggedIn={true} email={email} />}
                              />
                            }
                          />
                          <Route
                            path="contact"
                            element={
                              <AuthStatus
                                email={email}
                                subbed={subscribed}
                                loggedOut={<Contact loggedIn={false} />}
                                loggedIn={
                                  <Contact loggedIn={true} email={email} />
                                }
                              />
                            }
                          />
                          <Route
                            path="tos"
                            element={
                              <AuthStatus
                                email={email}
                                subbed={subscribed}
                                loggedOut={<Tos loggedIn={false} />}
                                loggedIn={<Tos loggedIn={true} email={email} />}
                              />
                            }
                          />
                          {/* 404 path cuz its the last one */}
                          <Route path="*" element={<Err404 email={email} />} />
                        </>
                      );
                    }
                  })()}
                </Routes>
              </>
            );
          }
        })()}
      </div>
    </Router>
  );
}

export default App;
