import React from "react";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockIcon from "@mui/icons-material/Lock";

import CreditCardIcon from "@mui/icons-material/CreditCard";

import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PersonIcon from "@mui/icons-material/Person";

import PasswordIcon from "@mui/icons-material/Password";

import ImportExportIcon from "@mui/icons-material/ImportExport";

import KeyIcon from "@mui/icons-material/Key";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function SidebarSettingsContent({
  selected,
  setSelected,
  isTeamManager,
}) {
  return (
    <div className="flex-1 flex flex-col overflow-y-auto relative">
      <div className="absolute w-[100%] h-[100%] left-0 top-0 flex flex-col overflow-y-auto custom-scrollbar">
        <p className="text-[#666]">Menu</p>
        <div className="mt-4 mb-6 flex flex-col">
          <MenuItem
            icon1={PersonOutlinedIcon}
            icon2={PersonIcon}
            name="Account"
            selected={selected == "account"}
            onClick={() => setSelected("account")}
          />
        </div>
        <div className="mb-6 flex flex-col">
          <MenuItem
            icon1={LockOutlinedIcon}
            icon2={LockIcon}
            name="Security"
            selected={selected == "security"}
            onClick={() => setSelected("security")}
          />
        </div>
        <div className="mb-6 flex flex-col">
          <MenuItem
            icon1={CreditCardIcon}
            name="Subscription"
            selected={selected == "subscription"}
            onClick={() => setSelected("subscription")}
          />
        </div>
        <div className="mb-6 flex flex-col">
          <MenuItem
            icon1={PasswordIcon}
            name="Password generator"
            selected={selected == "password generator"}
            onClick={() => setSelected("password generator")}
          />
        </div>
        <div className="mb-6 flex flex-col">
          <MenuItem
            icon1={ImportExportIcon}
            name="Import/Export"
            selected={selected == "i/o"}
            onClick={() => setSelected("i/o")}
          />
        </div>
        {isTeamManager ? (
          <div className="mb-6 flex flex-col">
            <MenuItem
              icon1={KeyIcon}
              name="Subscription keys"
              selected={selected == "subKeys"}
              onClick={() => setSelected("subKeys")}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="mb-6 flex flex-col">
          <MenuItem
            icon1={DeleteForeverIcon}
            name="Delete account"
            onClick={() => setSelected("deleteAccount")}
            selected={selected == "deleteAccount"}
          />
        </div>
      </div>
    </div>
  );
}

const MenuItem = ({ icon1, icon2, name, onClick, selected }) => {
  return (
    <button
      className={`flex flex-row p-2 duration-100 outline-none items-center ${
        selected
          ? "text-black font-bold bg bg-[#ddd] rounded-md hover:brightness-90"
          : "text-[#999] hover:text-[#000]"
      }`}
      onClick={onClick}
    >
      {(() => {
        let A;
        if (selected) {
          A = icon2 || icon1;
        } else {
          A = icon1;
        }

        return <A style={{}} />;
      })()}
      <p className="ml-2 select-none text-start">{name}</p>
    </button>
  );
};
