import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import OtherHeader from "../components/OtherHeader";

export default function Err404({ email }) {
  return (
    <div className="flex flex-1 flex-col">
      {email == "none" ? (
        <></>
      ) : (
        <>
          <OtherHeader email={email}/>
        </>
      )}
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="flex flex-col items-center">
          <p className="text-neutral-800 text-center text-xl border-b border-b-neutral-400 pb-2 w-full">
            <span className="text-3xl font-bold me-4">404</span> Page not found
          </p>
          <p className="mt-4 text-neutral-800">
            That page doesn't exist or is unavailable!
          </p>
          <div className="flex flex-col w-full">
            <Link to="/" className="mt-4 w-full">
              <button className="bg-gray-800 text-white w-full py-2 rounded-lg hover:brightness-125">
                Go home
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
