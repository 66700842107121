import React, { useState, useEffect } from "react";

import encryption from "../../utils/encryption";
import Logout from "../../utils/Logout";

import BtnTwo from "../BtnTwo";
import AddNoteNameInput from "../AddNoteNameInput";
import AddNoteTextArea from "../AddNoteTextArea";

import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import OverlayContentWrapper from "../OverlayContentWrapper";
import AddAccountInput from "../AddAccountInput";

export default function ViewNoteOverlay({
  note,
  close,
  sync,
  promptDeleteNote,
  encKey,
  setOverlay,
}) {
  const [noteVal, setNoteVal] = useState("");
  const [noteName, setNoteName] = useState(note.name);
  const [ogState, setOgState] = useState("");
  const [errors, setErrors] = useState([]);
  const [errMsg, setErrMsg] = useState(
    "Some of the information you entered was invalid"
  );

  const [isSubbed, setIsSubbed] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    try {
      if (encKey == "" || encKey == undefined) {
        Logout();
        return;
      }

      let parsedData = JSON.parse(atob(note.data));
      let encedNote = parsedData.note;
      if (encedNote == undefined || encedNote == "") {
        close();
        return;
      }

      let decryptedNote = encryption.decrypt(encedNote, encKey);

      setNoteVal(decryptedNote);
      setOgState(note.name + "~" + decryptedNote);
      setIsLoaded(true);
    } catch {
      close();
    }
  }, []);

  const isChanged = () => {
    let currentState = noteName + "~" + noteVal;
    if (currentState == ogState) {
      return false;
    } else {
      return true;
    }
  };

  const submit = async () => {
    try {
      setIsSubbed(true);
      let errors = verifySub.all();
      if (errors.length == 0) {
        if (encKey == "" || encKey == undefined) {
          Logout();
          setIsSubbed(false);
          return;
        }
        let encedNote = encryption.encrypt(noteVal, encKey);
        let data = {
          note: encedNote,
          name: noteName,
          id: note.id,
        };

        let csrfToken = await axios.get("/api/csrf");
        csrfToken = csrfToken.data.token;

        data["_csrf"] = csrfToken;
        data["hashedPw"] = localStorage.getItem("hashedPw");

        let res = await axios.post("/api/updateNote", data);

        let resData = res.data;

        if (resData.status == "success") {
          sync();
          close();
        } else if (resData.status == "error") {
          if (resData.message == "pwHashError") {
            localStorage.removeItem("hashedPw");
            setOverlay({ type: "view-note", data: note });
            setIsSubbed(false);
            return;
          }

          if (resData.message == "Each note must be less than 750 characters") {
            setErrMsg(resData.message);
            setErrors(["a"]);
          }
        }
      } else {
        setErrMsg("Some of the information you entered was invalid");
      }
      setIsSubbed(false);
    } catch {
      setIsSubbed(false);
      close();
    }
  };

  const verifySub = {
    name: () => {
      if (noteName == "" || noteName == undefined) {
        return false;
      } else {
        return true;
      }
    },
    note: () => {
      if (noteVal == "" || noteVal == undefined) {
        return false;
      } else {
        return true;
      }
    },
    all: () => {
      let errors = [];
      for (let key in verifySub) {
        if (key != "all") {
          let valid = verifySub[key]();
          if (!valid) {
            errors.push(key);
          }
        }
      }
      setErrors(errors);
      return errors;
    },
  };

  return (
    <OverlayContentWrapper
      close={close}
      loading={isSubbed}
      submit={submit}
      deactivated={!isChanged()}
    >
      {isLoaded ? (
        <>
          <AddNoteTextArea
            value={noteVal}
            setValue={(v) => {
              if (v.length <= 750) {
                setNoteVal(v);
              }
            }}
            error={errors.includes("note")}
          />
          <div className="mt-2">
            <AddAccountInput
              name="Note name"
              val={noteName}
              setVal={setNoteName}
              error={errors.includes("name")}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </OverlayContentWrapper>
  );
}
