import React from "react";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default function OverlayHeader({ name, deleteFunc }) {
  const upperFirstLetter = (word) => {
    let name = "";
    for (let i = 0; i < word.length; i++) {
      let letter = word[i];
      if (i == 0) {
        letter = letter.toUpperCase();
      }
      name += letter;
    }
    return name;
  };

  return (
    <div className="flex items-center justify-center border-b border-b-[#2282B9]">
      <span className="w-8 h-8"></span>
      <p className="text-[#2282B9] text-2xl py-4 flex-1 text-center overflow-hidden text-ellipsis px-2">
        {name}
      </p>
      <span className="w-8 h-8 flex justify-center items-center">
        {(() => {
          if (deleteFunc != undefined) {
            return (
              <svg
                onClick={deleteFunc}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer group"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 21C6.8999 21 6 20.1001 6 19V7H18V19C18 20.1001 17.1001 21 16 21H8ZM16 9H8V19H16V9Z"
                  fill="black"
                />
                <path
                  className="group-hover:-rotate-12 group-hover:translate-x-[-2px] group-hover:translate-y-[1px] duration-100"
                  d="M5 4V6H19V4H15.5L14.5 3H9.5L8.5 4H5Z"
                  fill="black"
                />
              </svg>
            );
          }
        })()}
      </span>
    </div>
  );
}
