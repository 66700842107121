import React, { useState } from "react";

import validateEmail from "email-validator";

import SettingsAccountInput from "../SettingsAccountInput";

import EmailIcon from "@mui/icons-material/Email";

export default function SettingsAccount({
  currentEmail,
  newEmail,
  setNewEmail,
  newPassword,
  setNewPassword,
  confNewPassword,
  setConfNewPassword,
  succMsg,
  errMsg,
}) {
  const [emailVal, setEmailVal] = useState(currentEmail);

  return (
    <div className="flex flex-1 flex-col">
      <p className="text-2xl text-neutral-800 font-bold">Email</p>
      <div className="-translate-y-6">
        <SettingsAccountInput
          icon={EmailIcon}
          value={emailVal}
          error={!validateEmail.validate(emailVal)}
          setValue={(v) => {
            setEmailVal(v);
            setNewEmail(v);
          }}
        />
      </div>
      <p className="text-2xl text-neutral-800 font-bold">Change password</p>
      <SettingsAccountInput
        isPassword
        name="Password"
        value={newPassword}
        error={newPassword != "" && newPassword.length < 7}
        setValue={setNewPassword}
      />
      <div className="mt-3">
        <SettingsAccountInput
          isPassword
          name="Confirm password"
          value={confNewPassword}
          error={confNewPassword != "" && confNewPassword != newPassword}
          setValue={setConfNewPassword}
        />
      </div>
    </div>
  );
}