import React, { useState } from "react";
import emailValidator from "email-validator";
import axios from "axios";

import AuthInput from "../components/AuthInput";

import MailIcon from "@mui/icons-material/Mail";

import "../styles/ForgotPassword.css";
import BtnThree from "../components/BtnThree";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [isSubbed, setIsSubbed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [invEmail, setInvEmail] = useState(false);

  const submit = async () => {
    if (emailValidator.validate(email) == false) {
      setInvEmail(true);
      return;
    } else {
      setInvEmail(false);
    }

    setIsSubbed(true);

    let csrfToken = await axios.get("/api/csrf");
    csrfToken = csrfToken.data.token;

    let res = await axios.post("/api/resetLink", {
      email: email,
      _csrf: csrfToken,
    });
    let resData = res.data;
    setIsSuccess(true);
    setIsSubbed(false);
  };

  return (
    <div className="mt-16 flex flex-col items-center">
      <div className="bg-white w-[100%] md:w-[75%] lg:w-[900px] flex flex-col">
        <h1 className="text-center text-3xl sm:text-5xl font-bold">
          Forgot password
        </h1>
        <div className="bg-white rounded-3xl p-8 mt-8 md:shadow-2xl flex justify-center flex-col border min-h-[500px]">
          {!isSuccess ? (
            <>
              <div className="flex-1"></div>
              <div>
                <p className="text-[#666666] text-center">
                  Enter the email for your account
                </p>
                <p className="text-[#666666] text-center">
                  You will be emailed a link to reset your password
                </p>
                <div>
                  <AuthInput
                    error={invEmail}
                    name="Email"
                    placeholder="Email address"
                    icon={MailIcon}
                    value={email}
                    setValue={setEmail}
                  />
                </div>
                <div className="mt-8 flex flex-col h-12">
                  <BtnThree
                    onClick={submit}
                    loading={isSubbed}
                    flex1
                    name="Send reset link"
                  />
                </div>
                <div className="h-[32px]">
                  {(() => {
                    if (invEmail) {
                      return (
                        <p className="text-red-500">
                          The email you entered is invalid
                        </p>
                      );
                    }
                  })()}
                </div>
              </div>
              <div className="flex-1 flex flex-col justify-end">
                <p className="text-red-500">
                  Resetting your password will cause you to lose all the data in
                  your account! Only use this as a last resort
                </p>
              </div>
            </>
          ) : (
            <div className="flex-1 flex justify-center items-center">
              <p className="text-lg text-green-700">
                An email was sent to {email}, check your mailbox to reset your
                password.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}