import React, { useState } from "react";
import { validate } from "email-validator";
import { Link } from "react-router-dom";
import axios from "axios";
import { SHA256 } from "crypto-js";

import KeyUtils from "../utils/KeyUtils";

import AuthInput from "../components/AuthInput";
import BtnThree from "../components/BtnThree";

import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isSub, setIsSub] = useState(false);

  const [subbedOnce, setSubbedOnce] = useState(false);

  const verifyData = () => {
    let errors = [];
    let validEmail = validate(email);
    if (!validEmail) {
      errors.push("email");
    }
    if (password.length < 7) {
      errors.push("password");
    }
    return errors;
  };

  const validateData = () => {
    let errors = [];
    let validEmail = validate(email);
    if (!validEmail && (email != "" || subbedOnce)) {
      errors.push("email");
    }
    if (password.length < 7 && (password != "" || subbedOnce)) {
      errors.push("password");
    }
    return errors;
  };

  const login = async () => {
    if (!subbedOnce) {
      setSubbedOnce(true);
    }

    setErrMsg("");
    setIsSub(true);
    if (verifyData().length == 0) {
      let pw = String(password);

      let csrfToken = await axios.get("/api/csrf");
      csrfToken = csrfToken.data.token;

      let res = await axios.post(
        "/api/login",
        {
          email,
          password: SHA256(password).toString(),
          _csrf: csrfToken,
        },
        { withCredentials: true }
      );

      if (res.data.status == "error") {
        setErrMsg(res.data.message);
      } else if (res.data.status == "success") {
        let uid = res.data.data.uid;
        if (uid != undefined && pw != undefined) {
          localStorage.setItem(
            "hashedPw",
            SHA256(SHA256(SHA256(password).toString()).toString()).toString()
          );

          let keyList = [];
          let wrappedKeys = res.data.data.wrappedKeys;
          wrappedKeys.forEach((key) => {
            let o = {
              id: key.id,
              type: key.keyType,
              wrappedKey: key.wrappedKey,
            };

            let wk = o.wrappedKey;
            let unwrappedKey = KeyUtils.unWrapKey(password, wk);
            o["unWrapped"] = unwrappedKey;
            keyList.push(o);
          });

          let privKey = KeyUtils.passwordToPriv(password, true);

          localStorage.setItem("keys", JSON.stringify(keyList));
          localStorage.setItem("privKey", privKey);
          document.location.pathname = "/";
        }
      }
    } else {
      setErrMsg("Some of the data you entered is invalid");
    }
    setIsSub(false);
  };

  return (
    <>
      <div className="mt-16 flex flex-col items-center mb-8">
        <div className="bg-white w-[100%] md:w-[75%] lg:w-[900px] flex flex-col">
          <h1 className="text-center text-3xl sm:text-5xl font-bold">
            Sign in
          </h1>
          <div className="flex justify-center mt-4">
            <p className="text-gray-600 text-center w-[95%] sm:w-[65%]">
              Pass Protect provides the security and encryption necessary to
              safeguard your passwords and personal information
            </p>
          </div>
          <div className="bg-white rounded-3xl p-8 mt-8 md:shadow-2xl">
            <div>
              <AuthInput
                error={validateData().includes("email")}
                name="Email"
                placeholder="Email address"
                icon={MailIcon}
                value={email}
                setValue={setEmail}
              />
            </div>
            <div className="mt-8 flex flex-col">
              <AuthInput
                error={validateData().includes("password")}
                name="Password"
                placeholder="Password"
                icon={LockIcon}
                value={password}
                setValue={setPassword}
                password
              />
              <div className="flex justify-end">
                <Link to="/forgot-password">
                  <p className="text-[#DB287B]">Forgot password?</p>
                </Link>
              </div>
            </div>
            <div className="mt-8 flex flex-col h-12">
              <BtnThree onClick={login} loading={isSub} flex1 name="Log in" />
            </div>
            <div className="h-[24px]">
              {(() => {
                if (errMsg != "") {
                  return <p className="text-red-500">{errMsg}</p>;
                }
              })()}
            </div>
            <div className="h-16 flex flex-row items-end justify-center">
              <p className="text-gray-500">
                Don't have an account?{" "}
                <span className="font-bold text-[#DB287B]">
                  <Link to="/signup">Sign up</Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
