import React from "react";

import SearchIcon from "@mui/icons-material/Search";

const map = {
  password: "passwords",
  card: "cards",
  identity: "identities",
  note: "notes",
};

export default function HomePageSearch({
  menuSelection,
  searchTerm,
  setSearchTerm,
}) {
  return (
    <div className="flex flex-row bg-[#FFFCBA] text-[#85823D] items-center px-2 rounded-lg">
      <SearchIcon />
      <input
        className="peer outline-none bg-transparent placeholder:text-[#85823D] py-2 px-2 flex-1"
        type="text"
        autoComplete="off"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={`Search ${map[menuSelection]}`}
      />
    </div>
  );
}